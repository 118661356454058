import { MouseEvent, useEffect, useRef } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface StepperInputProps {
  label: string;
  onIncrease: () => void;
  onDecrease: () => void;
  onInputChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  setFocused?: () => void;
  value?: string | number;
  id?: string;
  name?: string;
  className?: string;
  commonClassName?: string;
  isFocused?: boolean;
  isInvalid?: boolean;
  isDecreaseBtnDisabled?: boolean;
  isIncreaseBtnDisabled?: boolean;
  isInputReadOnly?: boolean;
  autoFocus?: boolean;
}

const StepperInput = ({
  label,
  onIncrease,
  onDecrease,
  onInputChange,
  onBlur,
  onFocus,
  setFocused,
  value,
  id,
  name,
  className,
  commonClassName,
  isDecreaseBtnDisabled,
  isIncreaseBtnDisabled,
  isInputReadOnly,
  isFocused,
  isInvalid,
  autoFocus
}: StepperInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isFocused && document.activeElement !== inputRef.current) {
      inputRef.current?.focus();
    }
  }, [value, isFocused]);

  const setFocus = () => {
    if (document.activeElement !== inputRef.current) {
      inputRef.current?.focus();
    }

    if (!isFocused) {
      setFocused?.();
    }
  };

  const increase = () => {
    setFocus();
    onIncrease();
  };

  const decrease = () => {
    setFocus();
    onDecrease();
  };

  const ignoreBlurEvent = (ev: MouseEvent) => {
    ev.preventDefault();
  };

  return (
    <div className={classNames(styles.stepper, className)}>
      <button
        type="button"
        className={classNames(styles.stepper__button, styles.stepper__button__decrease, commonClassName, {
          [styles.stepper__button__focused]: isFocused,
          [styles.stepper__button__invalid]: isInvalid,
          [styles.stepper__button__disabled]: isDecreaseBtnDisabled,
          biab_disabled: isDecreaseBtnDisabled
        })}
        onClick={decrease}
        onMouseDown={ignoreBlurEvent}
        disabled={isDecreaseBtnDisabled}
        data-type="minus"
      >
        <i className="fa2 fa2-minus" aria-hidden="true" />
      </button>
      <div
        className={classNames(styles.stepper__inputWrapper, commonClassName, {
          [styles.stepper__inputWrapper__focused]: isFocused,
          [styles.stepper__inputWrapper__invalid]: isInvalid
        })}
      >
        {value !== '' && value !== undefined && (
          <label htmlFor={id} className={styles.stepper__label}>
            {label}
          </label>
        )}
        <input
          ref={inputRef}
          type="text"
          value={value ?? ''}
          name={name}
          id={id}
          placeholder={label}
          className={classNames(styles.stepper__input, { [styles.stepper__input__withValue]: value })}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={ev => onInputChange?.(ev.target.value)}
          readOnly={isInputReadOnly}
          autoFocus={autoFocus}
          lang="en-US"
          inputMode="decimal"
          autoComplete="off"
          size={1}
        />
      </div>
      <button
        type="button"
        className={classNames(styles.stepper__button, styles.stepper__button__increase, commonClassName, {
          [styles.stepper__button__focused]: isFocused,
          [styles.stepper__button__invalid]: isInvalid,
          [styles.stepper__button__disabled]: isIncreaseBtnDisabled,
          biab_disabled: isIncreaseBtnDisabled
        })}
        onClick={increase}
        onMouseDown={ignoreBlurEvent}
        disabled={isIncreaseBtnDisabled}
        data-type="plus"
      >
        <i className="fa2 fa2-plus" aria-hidden="true" />
      </button>
    </div>
  );
};

export default StepperInput;
