import { Fragment, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { getConsolidatedBets, getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { getCurrentBetsBySideType } from 'redux/modules/currentBets/selectors';
import { ECurrentBetActions } from 'redux/modules/currentBets/type';
import { CookieNames } from 'types';
import { BetTypes, MatchTypes } from 'types/bets';

import OpenedBet from '../OpenedBet';
import OpenedBetsHeader from '../OpenedBetsHeader';

const OpenedBetsMatchedBySide = ({ side }: { side: BetTypes }) => {
  const [cookies] = useCookies([CookieNames.BETSLIP_FILTER_MARKET, CookieNames.BETSLIP_FILTER_EVENT]);

  const isGameBetslip = useSelector(getIsGameBetSlip);
  const consolidateBets = useSelector(getConsolidatedBets);
  const currentBets = useSelector(
    getCurrentBetsBySideType(
      side,
      MatchTypes.MATCHED,
      cookies.FILTER_BETS_BY_MARKET_ID,
      cookies.FILTER_BETS_BY_EVENT_ID,
      consolidateBets,
      isGameBetslip
    )
  );

  const hideOpenedBetsHeader = useMemo(() => {
    return currentBets.every(
      bet => bet.action === ECurrentBetActions.CANCELLING && bet.offerState === BetsStatusesTypes.CANCELLED
    );
  }, [currentBets]);

  if (!currentBets.length) {
    return null;
  }

  return (
    <>
      {currentBets.map((bet, index) => (
        <Fragment key={`${bet.offerId}${bet.isCombined ? '_combined' : ''}`}>
          {!hideOpenedBetsHeader && (
            <OpenedBetsHeader
              bet={bet}
              prevBet={currentBets[index - 1] ?? null}
              side={side}
              matchType={MatchTypes.MATCHED}
            />
          )}
          <OpenedBet
            bet={bet}
            matchType={MatchTypes.MATCHED}
            betIndex={0}
            isFirst={index === 0}
            isLast={currentBets.length === index + 1}
            side={side}
          />
        </Fragment>
      ))}
    </>
  );
};

export default OpenedBetsMatchedBySide;
