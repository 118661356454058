import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import InlineBetProgress from 'components/InlinePlacement/components/InlineBetProgress';
import InlinePlacedBet from 'components/InlinePlacement/components/InlinePlacedBet';
import InlineSelectedBet from 'components/InlinePlacement/components/InlineSelectedBet';
import InlineUnavailableBet from 'components/InlinePlacement/components/InlineUnavailableBet';
import {
  PlacementErrorsIds,
  VALIDATION_ERROR_BET_OUTDATED_LINE,
  VALIDATION_ERROR_BET_OUTDATED_ODDS
} from 'constants/placement';
import { usePlacementData } from 'hooks/usePlacement';
import useTakeOdds from 'hooks/useTakeOdds';
import {
  getBalanceWsEnabled,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled,
  getIsSportLayOddsDisabled,
  getMarketOddsBackOnly,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { setRGErrorMessage } from 'redux/modules/betslip';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { getCurrentBetByOfferId } from 'redux/modules/currentBets/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import {
  failureInlinePlacedBet,
  removeInlineSelectedBet,
  successInlinePlacedBet,
  updateInlineSelectedBet
} from 'redux/modules/inlinePlacement';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { getStatusByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { removePlacedSelectedBetError } from 'redux/modules/placement';
import { getMarketDataById, getPlacedSelectedBetErrorMessage } from 'redux/modules/placement/selectors';
import { TPlacementError } from 'redux/modules/placement/type';
import { fetchBalance } from 'redux/modules/user';
import { hideInlinePlacementWhatIf } from 'redux/modules/whatIf';
import { MarketStatus, PageBlocks, PlacementPage } from 'types';
import { BetTypes } from 'types/bets';
import { Actions } from 'types/inlinePlacement';
import { BettingType } from 'types/markets';
import { formatBestPrice, isResponsibleGamblingError } from 'utils/betslip';
import { getBetIdentityData } from 'utils/inlinePlacement';

type TInlinePlacementProps = {
  bet: TInlineSelectedBet;
  pageBlock?: PageBlocks;
  page?: PlacementPage;
  isPopularMarketsOdds?: boolean;
};

const InlinePlacementItem = ({
  bet,
  page,
  isPopularMarketsOdds,
  pageBlock = bet.pageBlock ?? PageBlocks.HOME
}: TInlinePlacementProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);
  const market = useSelector(getMarketDataById(pageBlock, bet.marketId, bet.sportId));
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const marketStatus = useSelector(getStatusByMarketPricesId(bet.marketId));
  const placementErrorMessage = useSelector(getPlacedSelectedBetErrorMessage(bet.betUuid ?? ''));
  const isDisabledLayOddsForMarket = useSelector(getIsSportLayOddsDisabled(bet.sportId));
  const currentBet = useSelector(getCurrentBetByOfferId(bet?.placedBet?.offerId || 0));
  const marketOddsBackOnly = useSelector(getMarketOddsBackOnly);

  const isClosed = marketStatus === MarketStatus.CLOSED;
  const isLineMarket = bet.bettingType === BettingType.LINE || market?.description?.bettingType === BettingType.LINE;
  const isPlacedAction = bet.action === Actions.PLACED;
  const isEditAction = bet.action === Actions.EDIT;
  const isConfirmAction = bet.action === Actions.CONFIRM;
  const isProgressAction = bet.action === Actions.PROGRESS || bet.action === Actions.LONG_PROGRESS;
  const isCancelledAction = bet.action === Actions.CANCELLED;

  const isUnavailableBetView = isClosed && !bet.placedBet;
  const isSelectedBetView = !isProgressAction && (!bet.placedBet || isEditAction || isConfirmAction);
  const isPlacedBetView = bet.placedBet && (isPlacedAction || isCancelledAction);

  const { takeOdds } = useTakeOdds({ ...bet, betType: bet.type, size: bet.size || '' });

  const betIdData = getBetIdentityData(bet);

  const onSuccessPlacement = (placedBets: TCurrentBet[]) => {
    if (placedBets.length) {
      dispatch(successInlinePlacedBet({ ...bet, action: Actions.PLACED, placedBet: placedBets[0] }));

      if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
        dispatch(fetchBalance());
      }
    }
  };

  const onErrorPlacement = (error: TPlacementError | string = '') => {
    dispatch(failureInlinePlacedBet({ ...bet, action: bet.placedBet ? Actions.EDIT : Actions.SELECT, error }));

    if (isResponsibleGamblingError(error)) {
      dispatch(setRGErrorMessage(error));
    }
  };

  const onCancelledPlacement = () => {
    if (isPNCEnabled) {
      dispatch(
        updateInlineSelectedBet({
          ...betIdData,
          isTakeOffer: true,
          action: Actions.SELECT,
          validationMessage: {
            text: isLineMarket ? t(VALIDATION_ERROR_BET_OUTDATED_LINE) : t(VALIDATION_ERROR_BET_OUTDATED_ODDS),
            field: 'price',
            code: isLineMarket ? PlacementErrorsIds.BET_OUTDATED_LINE : PlacementErrorsIds.BET_OUTDATED_ODDS
          }
        })
      );
    }
  };

  const { editBetsHandler, cancelBetsHandler, placeBetsHandler } = usePlacementData({
    successPlacement: onSuccessPlacement,
    errorPlacement: onErrorPlacement,
    onCancelledPlacement
  });

  useEffect(() => {
    if (bet.action && [Actions.PLACE, Actions.UPDATE, Actions.TAKE, Actions.CANCEL].includes(bet.action)) {
      dispatch(
        updateInlineSelectedBet({
          ...betIdData,
          action: Actions.PROGRESS,
          progressType: bet.action,
          ...(bet.action === Actions.TAKE ? { price: takeOdds } : {})
        })
      );
    }

    if (bet.action === Actions.PLACE) {
      placeBetsHandler({
        bets: [{ ...bet, side: bet.type.toUpperCase(), page }],
        options: {
          isTakeOffer: !!bet.isTakeOffer
        }
      });
    } else if (bet.placedBet) {
      if (bet.action === Actions.UPDATE) {
        editBetsHandler({
          marketId: bet.marketId,
          bets: [{ ...bet.placedBet, betUuid: bet.betUuid, price: bet.price, size: bet.size }]
        });
      } else if (bet.action === Actions.TAKE) {
        editBetsHandler({
          marketId: bet.marketId,
          bets: [{ ...bet.placedBet, betUuid: bet.betUuid, price: takeOdds, size: bet.size }]
        });
      } else if (bet.action === Actions.CANCEL) {
        cancelBetsHandler({ marketId: bet.marketId, bets: [{ ...bet.placedBet, betUuid: bet.betUuid }] });
      }
    }

    if (bet.action === Actions.PROGRESS) {
      dispatch(removePlacedSelectedBetError({ betUuid: bet.betUuid ?? '' }));
    }

    dispatch(hideInlinePlacementWhatIf(bet.action === Actions.PLACED ? false : null));
  }, [bet.action]);

  useEffect(() => {
    if (isClosed) {
      dispatch(updateInlineSelectedBet({ ...betIdData, action: Actions.REMOVED }));
    }
  }, [isClosed]);

  useEffect(() => {
    if (bet.placedBet) {
      dispatch(
        updateInlineSelectedBet({
          ...betIdData,
          action: Actions.PLACED,
          price: isLineMarket
            ? formatBestPrice(+(bet.placedBet.handicap ?? 0), bet.marketType, bet.bettingType ?? '')
            : bet.placedBet.price,
          size: bet.placedBet.sizeRemaining,
          profit: bet.placedBet.profit
        })
      );
    }
  }, [bet.placedBet?.offerId]);

  useEffect(() => {
    if (bet.placedBet?.offerState === BetsStatusesTypes.CANCELLED) {
      dispatch(updateInlineSelectedBet({ ...betIdData, action: Actions.CANCELLED }));
    }
  }, [bet.placedBet?.offerState]);

  useEffect(() => {
    if (placementErrorMessage) {
      dispatch(
        updateInlineSelectedBet({
          ...betIdData,
          action: bet.placedBet ? Actions.EDIT : Actions.SELECT,
          placementError: placementErrorMessage
        })
      );
    }
  }, [placementErrorMessage]);

  useEffect(() => {
    if ((isDisabledLayOddsForMarket || (isPopularMarketsOdds && marketOddsBackOnly)) && bet.type === BetTypes.LAY) {
      dispatch(removeInlineSelectedBet(bet));
    }
  }, [isDisabledLayOddsForMarket, isPopularMarketsOdds, marketOddsBackOnly]);

  useEffect(() => {
    if (
      bet.placedBet?.offerId &&
      (!currentBet?.offerId || currentBet?.offerState === BetsStatusesTypes.CANCELLED) &&
      bet.action !== Actions.PROGRESS &&
      bet.action !== Actions.LONG_PROGRESS &&
      bet.action !== Actions.CANCELLED
    ) {
      dispatch(removeInlineSelectedBet(bet));
    }
  }, [currentBet?.offerId, currentBet?.offerState, bet.placedBet?.offerId]);

  return (
    <>
      {isUnavailableBetView ? (
        <InlineUnavailableBet bet={bet} />
      ) : (
        <>
          {isPlacedBetView && <InlinePlacedBet bet={bet} />}
          {isSelectedBetView && <InlineSelectedBet bet={bet} />}
          {isProgressAction && <InlineBetProgress bet={bet} />}
        </>
      )}
    </>
  );
};

export default InlinePlacementItem;
