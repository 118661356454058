import { ReactNode } from 'react';
import classNames from 'classnames';

import { ResponsibleBettingAlertTypeKeys, ResponsibleBettingAlertTypes } from 'types/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingAlertProps {
  type?: ResponsibleBettingAlertTypeKeys;
  description?: string;
  injection?: string;
  children?: ReactNode;
}

const ResponsibleBettingAlert = ({
  type = ResponsibleBettingAlertTypes.Error,
  description,
  children
}: ResponsibleBettingAlertProps) => {
  return description || children ? (
    <div
      className={classNames(styles.container, {
        [styles.container__error]: type === ResponsibleBettingAlertTypes.Error,
        [styles.container__info]: type === ResponsibleBettingAlertTypes.Info,
        [styles.container__outline]: type === ResponsibleBettingAlertTypes.Outline
      })}
    >
      {type === ResponsibleBettingAlertTypes.Error ? (
        <i className={classNames(styles.icon, 'fa2 fa2-warning_icon biab_fav-manage-icon')} />
      ) : (
        <i className={classNames(styles.icon, 'fa2 fa2-info-icon biab_fav-manage-icon')} />
      )}
      {description ? <span className={styles.description}>{description}</span> : children}
    </div>
  ) : null;
};

export default ResponsibleBettingAlert;
