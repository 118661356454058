import { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import BetGroupEmptyCells from 'components/BetGroupEmptyCells';
import InlinePlacement from 'components/InlinePlacement';
import BetContentGroup from 'components/MarketsTable/components/MarketsTableRow/components/BetContentGroup';
import MobileOpenBets from 'components/MobileOpenBets';
import MobilePlacement from 'components/MobilePlacement';
import NonRunner from 'components/NonRunner';
import { BetStatuses } from 'constants/myBets';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getIsCurrentBetsBySelection } from 'redux/modules/currentBets/selectors';
import {
  getInlineSelectedBetBySelection,
  getInlineSelectedBetsBySelection,
  getIsInlineSelectedBetsBySelection
} from 'redux/modules/inlinePlacement/selectors';
import { getMarketPricesRunnerStatusBySelectionId } from 'redux/modules/marketsPrices/selectors';
import { PageBlocks, PlacementPage } from 'types';
import { MatchTypes } from 'types/bets';
import { BetContentGroupType, IMarket, IMarketStatusSettings, TMarketRunner } from 'types/markets';

import styles from './styles.module.scss';

interface MarketRunnerProps {
  /**
   * Market runner
   */
  runner: TMarketRunner;
  /**
   * Current Market
   */
  market: IMarket;
  /**
   * Tooltip will show only for first market and first runner
   */
  tooltip?: ReactNode;
  /**
   * Passed children
   */
  children: ReactNode;
  /**
   * Place where component was added (Home, Market odds, Competition, Event)
   */
  pageBlock?: PageBlocks;
  /**
   * Redirect to single market page if inline placement is disabled
   */
  redirectToSingleMarketPage?: boolean;
  /**
   * Show tooltip or not
   */
  showTooltip?: boolean;
  /**
   * URL search params for redirect to single market page
   */
  redirectToSingleMarketSearchParams?: string;
  /**
   * Callback fired when redirected to a single market page
   */
  onRedirectToSingleMarketPage?: (market: IMarket) => void;
  /**
   * Show empty columns on desktop if market depth is disabled
   */
  showEmptyColumns?: boolean;
  isLayColumnEnabled: boolean;
  desktopCellsContainerWidth: string;
  mobileCellsContainerWidth: string;
  isLineMarket: boolean;
  betGroups: BetContentGroupType[];
  marketPricesCurrency: string;
  marketStatusSettings: IMarketStatusSettings;
  isOtherSection?: boolean;
  page?: PlacementPage;
  containerClassName?: string;
}

const MarketRunner = ({
  runner,
  children,
  market,
  tooltip,
  pageBlock,
  redirectToSingleMarketPage,
  showTooltip = true,
  redirectToSingleMarketSearchParams,
  onRedirectToSingleMarketPage,
  showEmptyColumns = false,
  isLayColumnEnabled,
  desktopCellsContainerWidth,
  mobileCellsContainerWidth,
  betGroups,
  marketPricesCurrency,
  marketStatusSettings,
  isOtherSection = false,
  page,
  containerClassName = ''
}: MarketRunnerProps) => {
  const [isBetslipClosing, setIsBetslipClosing] = useState(false);

  const device = useSelector(getAppDevice);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const inlineSelectedBet = useSelector(
    getInlineSelectedBetBySelection(pageBlock, market.marketId, runner?.selectionId, runner?.handicap)
  );

  const inlineSelectedBets = useSelector(
    getInlineSelectedBetsBySelection(pageBlock, market.marketId, runner?.selectionId, runner?.handicap)
  );

  const isInlineSelectedBetsBySelection = useSelector(
    getIsInlineSelectedBetsBySelection(pageBlock, market.marketId, runner?.selectionId, runner?.handicap)
  );

  const currentRunnerStatus = useSelector(
    getMarketPricesRunnerStatusBySelectionId(market.marketId, runner.selectionId, runner.handicap)
  );
  const isMatchedBetsBySelectionId = useSelector(
    getIsCurrentBetsBySelection({
      type: MatchTypes.MATCHED,
      marketId: market.marketId,
      selectionId: runner.selectionId,
      handicap: runner?.handicap
    })
  );
  const isUnmatchedBetsBySelectionId = useSelector(
    getIsCurrentBetsBySelection({
      type: MatchTypes.UNMATCHED,
      marketId: market.marketId,
      selectionId: runner.selectionId,
      handicap: runner?.handicap
    })
  );

  const isDesktop = device === Devices.DESKTOP;
  const isMobileOpenBetsVisible =
    !isDesktop &&
    isLoggedIn &&
    (pageBlock === PageBlocks.TOP_5_VIEW || pageBlock === PageBlocks.MULTI_MARKET_VIEW) &&
    (isMatchedBetsBySelectionId || isUnmatchedBetsBySelectionId) &&
    (isBetslipClosing || !inlineSelectedBet);

  return (
    <>
      <div className={classNames(styles.marketRunner, 'marketRunner', containerClassName)}>
        {children}

        {showTooltip && tooltip && (
          <div className={classNames('biab_promoted-tooltip-inner', styles.marketRunner__tooltip)}>{tooltip}</div>
        )}

        {currentRunnerStatus === BetStatuses.Removed ? (
          <NonRunner marketId={market.marketId} selectionId={runner.selectionId} handicap={runner.handicap} />
        ) : (
          <div
            style={{ width: isDesktop ? desktopCellsContainerWidth : mobileCellsContainerWidth }}
            className={classNames(styles.marketRunner__betContent, 'marketRunner__betContent', {
              [styles.marketRunner__betContent__withoutLay]: !isLayColumnEnabled
            })}
          >
            {showEmptyColumns && <BetGroupEmptyCells />}
            {betGroups.map(type => (
              <BetContentGroup
                key={type}
                runner={runner}
                betGroupsCount={showEmptyColumns ? 3 : betGroups.length}
                market={market}
                pageBlock={pageBlock}
                marketStatusSettings={marketStatusSettings}
                type={type}
                cellClasses={
                  isDesktop ? { content: styles.cellContent, cell: styles.cell, odds: styles.cellOdds } : undefined
                }
                groupClasses={{ betContent: classNames({ [styles.marketRunner__betGroup]: !isDesktop }) }}
                redirectToSingleMarketPage={redirectToSingleMarketPage}
                onRedirectToSingleMarketPage={onRedirectToSingleMarketPage}
                redirectToSingleMarketSearchParams={redirectToSingleMarketSearchParams}
                allGroupsTypes={betGroups}
                isOtherSection={isOtherSection}
                page={page}
              />
            ))}
            {showEmptyColumns && isLayColumnEnabled && <BetGroupEmptyCells />}
          </div>
        )}
      </div>
      {isInlineSelectedBetsBySelection && isDesktop && (
        <InlinePlacement bets={inlineSelectedBets} pageBlock={pageBlock} page={page} />
      )}
      {inlineSelectedBet && !isDesktop && (
        <MobilePlacement
          bet={inlineSelectedBet}
          onClosingBetslip={setIsBetslipClosing}
          pageBlock={pageBlock}
          page={page}
        />
      )}
      {isMobileOpenBetsVisible && (
        <MobileOpenBets
          marketId={market.marketId}
          selectionId={runner?.selectionId}
          handicap={runner?.handicap}
          marketPricesCurrency={marketPricesCurrency}
          pageBlock={pageBlock}
          page={page}
          sportId={market.eventType.id}
        />
      )}
    </>
  );
};

export default MarketRunner;
