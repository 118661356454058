import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { keys } from 'lodash';

import { BETSLIP_NOTIFICATIONS_STORAGE_NAME, BETSLIP_SELECTED_BETS_STORAGE_NAME } from 'constants/betslip';
import { betslipBranding as branding } from 'constants/branding';
import { GAMES_BASE_URL } from 'constants/locations';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { usePlacementNotifications } from 'hooks/usePlacementNotifications';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getLoggedInLoading, getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  checkSportsMarkets,
  removeAllPlacementNotifications,
  removeEmptySelectedMarkets,
  setActiveTab,
  setBetslipType,
  setCheckMarketsLoading,
  setPlacedBets
} from 'redux/modules/betslip';
import {
  getBetslipActiveTab,
  getBetslipCollapse,
  getHasPlacementNotifications,
  getIsCheckMarketsLoading,
  getIsGameBetSlip,
  getNewSelectedBetsAmount,
  getSelectedBetsAmountToPlace,
  getSelectedBetsMarketIds,
  getSelectedMarketsAmount
} from 'redux/modules/betslip/selectors';
import { EBetslipTabs } from 'redux/modules/betslip/type';
import { getCashOutPlacedIds } from 'redux/modules/cashOut/selectors';
import { removeAllCurrentBetsCanBeRemoved, removeAllUnmatchedOffersIdsToShowLapsed } from 'redux/modules/currentBets';
import {
  getAreCurrentBetsLoaded,
  getCurrentBetsAmount,
  getCurrentBetsLengthByType,
  getIsAtLeastOneCanBeRemovedCurrentBet
} from 'redux/modules/currentBets/selectors';
import { getGamesPricesCurrencies } from 'redux/modules/games/selectors';
import { getMarketPricesCurrencies } from 'redux/modules/marketsPrices/selectors';
import { MatchTypes } from 'types/bets';
import { EBetslipTypes } from 'types/betslip';

import BetSlipBetsStatusesInjection from './components/BetSlipBetsStatusesInjection';
import BetslipHeader from './components/BetslipHeader';
import BetslipMarkets from './components/BetslipMarkets';
import BetslipSettings from './components/BetslipSettings';
import OpenedBetsTab from './components/OpenedBetsTab';
import PlaceBetsActions from './components/PlaceBetsActions';
import PlaceBetsTab from './components/PlaceBetsTab';

import styles from './styles.module.scss';

const contentComponents = {
  [EBetslipTabs.PLACE_BETS]: <PlaceBetsTab />,
  [EBetslipTabs.OPEN_BETS]: <OpenedBetsTab />
};

const Betslip = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const hasPlacementNotifications = useSelector(getHasPlacementNotifications);
  const isGameBetslip = useSelector(getIsGameBetSlip);
  const activeTab = useSelector(getBetslipActiveTab);
  const collapse = useSelector(getBetslipCollapse);
  const currentBetsAmount = useSelector(getCurrentBetsAmount({ isGameType: isGameBetslip }));
  const selectedBetsAmountToPlace = useSelector(getSelectedBetsAmountToPlace);
  const newSelectedBetsAmount = useSelector(getNewSelectedBetsAmount);
  const selectedMarketsAmount = useSelector(getSelectedMarketsAmount);
  const cashOutPlacedIds = useSelector(getCashOutPlacedIds);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isAuthLoading = useSelector(getLoggedInLoading);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const unmatchedBetsCount = useSelector(
    getCurrentBetsLengthByType(
      isPNCEnabled && !isGameBetslip
        ? undefined
        : {
            type: MatchTypes.UNMATCHED,
            isGameType: isGameBetslip,
            ignoreCancelled: true,
            ignoreFullyMatchedAction: true
          }
    )
  );
  const unmatchedBetsCountWithCancelled = useSelector(
    getCurrentBetsLengthByType(
      isPNCEnabled && !isGameBetslip
        ? undefined
        : { type: MatchTypes.UNMATCHED, isGameType: isGameBetslip, anyCancelled: true, showLapsed: true }
    )
  );
  const matchedBetsCount = useSelector(
    getCurrentBetsLengthByType({ type: MatchTypes.MATCHED, isGameType: isGameBetslip })
  );
  const areCurrentBetsLoaded = useSelector(getAreCurrentBetsLoaded);
  const isAtLeastOneCanBeRemovedBet = useSelector(getIsAtLeastOneCanBeRemovedCurrentBet);
  const isCheckMarketsLoading = useSelector(getIsCheckMarketsLoading);
  const selectedBetsMarketIds = useSelector(getSelectedBetsMarketIds);
  const selectedBetsMarketCurrenciesString = useSelector(getMarketPricesCurrencies(selectedBetsMarketIds));
  const selectedBetsGamesCurrenciesString = useSelector(getGamesPricesCurrencies(selectedBetsMarketIds));

  const selectedBetsMarketCurrencies: (string | null)[] = JSON.parse(
    isGameBetslip ? selectedBetsGamesCurrenciesString : selectedBetsMarketCurrenciesString
  );
  const { isMultiCurrencySupported, isMultiCurrencyChanged } = useMultiCurrencySupporting();
  const { hasPending } = usePlacementNotifications();

  const isCurrencyChanged = useMemo(() => {
    return selectedBetsMarketCurrencies.some(marketCurrency => isMultiCurrencyChanged(marketCurrency || undefined));
  }, [isMultiCurrencyChanged, selectedBetsMarketCurrencies]);

  const hideActions = isMultiCurrencySupported && isCurrencyChanged && selectedBetsMarketCurrencies.length == 1;

  const isPlaceBetsActionsEnabled = newSelectedBetsAmount > 0 && activeTab === EBetslipTabs.PLACE_BETS && !hideActions;
  const isAtLeastOneCanBeRemovedBetRef = useRef(isAtLeastOneCanBeRemovedBet);

  isAtLeastOneCanBeRemovedBetRef.current = isAtLeastOneCanBeRemovedBet;

  const handleTabClick = (tab: EBetslipTabs) => {
    if (tab !== activeTab) {
      dispatch(setActiveTab(tab));

      if (tab !== EBetslipTabs.PLACE_BETS && !hasPending) {
        dispatch(removeAllPlacementNotifications());
      }
    }
  };

  useEffect(() => {
    if (!isLoggedIn && !isAuthLoading) {
      dispatch(removeAllPlacementNotifications());
    }

    const isGamePage = location.pathname.includes(GAMES_BASE_URL);

    if (isLoggedIn && !isAuthLoading && !isGamePage) {
      const placementNotificationsStorageString = localStorage.getItem(BETSLIP_NOTIFICATIONS_STORAGE_NAME);
      const selectedBetsStorageString = localStorage.getItem(BETSLIP_SELECTED_BETS_STORAGE_NAME);
      const selectedBetsFromStorage = selectedBetsStorageString ? JSON.parse(selectedBetsStorageString) : {};
      const placementNotifications = placementNotificationsStorageString
        ? JSON.parse(placementNotificationsStorageString)
        : {};

      if (keys(selectedBetsFromStorage).length || keys(placementNotifications).length) {
        dispatch(checkSportsMarkets({ selectedBets: selectedBetsFromStorage, placementNotifications }));
      } else {
        dispatch(setCheckMarketsLoading(false));
      }
    }
  }, [isLoggedIn, isAuthLoading]);

  useEffect(() => {
    const currentBetSlipType = isGameBetslip ? EBetslipTypes.GAME : EBetslipTypes.EXCHANGE;
    const newBetSlipType = location.pathname.startsWith(GAMES_BASE_URL) ? EBetslipTypes.GAME : EBetslipTypes.EXCHANGE;

    if (currentBetSlipType !== newBetSlipType) {
      dispatch(setBetslipType(newBetSlipType));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isLoggedIn && !matchedBetsCount && !unmatchedBetsCountWithCancelled && areCurrentBetsLoaded) {
      dispatch(setActiveTab(EBetslipTabs.PLACE_BETS));
    }
  }, [isLoggedIn, matchedBetsCount, areCurrentBetsLoaded, unmatchedBetsCountWithCancelled]);

  useEffect(() => {
    // Select Opened bets tab if there are current bets and Selected bets are cancelled
    if (
      isLoggedIn &&
      areCurrentBetsLoaded &&
      !selectedMarketsAmount &&
      !hasPlacementNotifications &&
      !isCheckMarketsLoading
    ) {
      dispatch(setActiveTab(EBetslipTabs.OPEN_BETS));
    }
  }, [isLoggedIn, areCurrentBetsLoaded, isCheckMarketsLoading]);

  useEffect(() => {
    if (cashOutPlacedIds.length) {
      dispatch(setPlacedBets(cashOutPlacedIds));
    }
  }, [cashOutPlacedIds]);

  useEffect(() => {
    dispatch(removeEmptySelectedMarkets());
  }, [location.pathname, activeTab]);

  useEffect(() => {
    if (!isLoggedIn && activeTab === EBetslipTabs.OPEN_BETS) {
      dispatch(setActiveTab(EBetslipTabs.PLACE_BETS));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    return () => {
      if (isAtLeastOneCanBeRemovedBetRef.current) {
        dispatch(removeAllCurrentBetsCanBeRemoved());
        dispatch(removeAllUnmatchedOffersIdsToShowLapsed());
      }
    };
  }, [activeTab]);

  return (
    <div className={styles.betslipWrap}>
      <BetSlipBetsStatusesInjection />
      <BetslipHeader />
      {!collapse && (
        <div className={branding.BETSLIP_WRAP}>
          <div className={styles.tabs}>
            {[EBetslipTabs.PLACE_BETS, EBetslipTabs.OPEN_BETS].map(tab => {
              let betsAmount: number;

              if (tab === EBetslipTabs.OPEN_BETS) {
                if (isPNCEnabled && !isGameBetslip) {
                  betsAmount = matchedBetsCount;
                } else {
                  betsAmount = currentBetsAmount;
                }
              } else {
                betsAmount = selectedBetsAmountToPlace;
              }

              return (
                <button
                  key={tab}
                  className={classNames(styles.tab, branding.BETSLIP_TAB, {
                    [styles.tab__active]: activeTab === tab,
                    [branding.SELECTED]: activeTab === tab
                  })}
                  onClick={() => handleTabClick(tab)}
                >
                  <span>
                    {tab === EBetslipTabs.PLACE_BETS ? t('betslip.labels.placeBets') : t('betslip.labels.openBets')}
                  </span>
                  {betsAmount > 0 && (
                    <span
                      className={classNames(styles.tab__counter, branding.TAB_COUNTER, {
                        [styles.tab__counter__active]: activeTab === tab
                      })}
                    >
                      <span>{betsAmount}</span>
                      {tab === EBetslipTabs.OPEN_BETS && unmatchedBetsCount > 0 && (
                        <span className={classNames(styles.tab__counter__badge, branding.COUNTER_BADGE)} />
                      )}
                    </span>
                  )}
                </button>
              );
            })}
          </div>
          {contentComponents[activeTab]}
          <div className={classNames(styles.panel, branding.PANEL)}>
            {isPlaceBetsActionsEnabled && <PlaceBetsActions />}
            <div className={styles.settings}>
              <BetslipSettings />
            </div>
          </div>
        </div>
      )}
      <BetslipMarkets />
    </div>
  );
};

export default Betslip;
