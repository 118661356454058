import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePlacementNotifications } from 'hooks/usePlacementNotifications';
import { getBetsStatusesRequestInterval } from 'redux/modules/appConfigs/selectors';
import { fetchBetsStatuses } from 'redux/modules/betsStatuses';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';

const BetSlipBetsStatusesInjection = () => {
  const dispatch = useDispatch();

  const { notifications, hasPendingStatuses } = usePlacementNotifications();
  const betsStatusesRequestInterval = useSelector(getBetsStatusesRequestInterval) || '1000';

  const getBetsStatusesInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  const offerIds = notifications
    .filter(({ offerId, offer }) => !!offerId && !offer)
    .map(({ offerId }) => offerId) as number[];

  useEffect(() => {
    if (offerIds?.length && hasPendingStatuses) {
      if (offerIds.length) {
        const fetchBetsStatusesHandler = () => {
          dispatch(
            fetchBetsStatuses({
              offerIds,
              onSuccess: offersStatuses => {
                const noPendingStatus = Object.values(offersStatuses).every(
                  status => status !== BetsStatusesTypes.PENDING
                );

                if (noPendingStatus && getBetsStatusesInterval.current) {
                  clearInterval(getBetsStatusesInterval.current);
                  getBetsStatusesInterval.current = null;
                }
              }
            })
          );
        };

        fetchBetsStatusesHandler();
        getBetsStatusesInterval.current = setInterval(fetchBetsStatusesHandler, parseInt(betsStatusesRequestInterval));
      }
    }

    return () => {
      if (getBetsStatusesInterval.current) {
        clearInterval(getBetsStatusesInterval.current);
        getBetsStatusesInterval.current = null;
      }
    };
  }, [hasPendingStatuses, offerIds]);

  return null;
};

export default BetSlipBetsStatusesInjection;
