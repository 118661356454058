export const inlinePlacementBranding = {
  WRAPPER: 'biab_inline-placement-component-wrapper',
  COMPONENT: 'biab_inline-placement-component',
  BACK: 'biab_back',
  LAY: 'biab_lay',
  ERROR: 'biab_inline-placement-error',
  FANCY_SWAP: 'biab_fancy-swap',
  PLACEMENT_BUTTON: 'biab_inline-placement-btn',
  PLACE_BTN: 'biab_place',
  CANCEL_BTN: 'biab_cancel',
  CONFIRM_BTN: 'biab_confirm',
  DISABLED_BTN: 'biab_disabled',
  EDIT_BTN: 'biab_edit',
  PLACED_BET: 'biab_inline-placement-placed-bet',
  UNMATCHED: 'biab_unmatched',
  PARTIALLY_MATCHED: 'biab_partially-matched',
  MATCHED: 'biab_matched',
  CANCELLED: 'biab_cancelled',
  PROGRESS_WRAPPER: 'biab-inline-placement-progress-wrapper',
  PROGRESS_BAR: 'biab-inline-placement-progress-bar'
};

export const betslipBranding = {
  /** Header */
  HEADER: 'biab_betslip-header',

  MIN_NAV: 'biab_right-min-nav',
  MIN_NAV_ICONS: 'biab_right-min-nav-icons',
  EMPTY_BETSLIP: 'biab_empty-betslip',
  SELECTED_MARKETS: 'biab_selected-markets',
  BETSLIP_COUNTER: 'biab_betlist-counter',
  TAB_COUNTER: 'biab_tab-counter',
  COUNTER_BADGE: 'biab_counter-badge',
  CANCEL_ALL_LINK: 'biab_cancel-all-link',
  UNMATCHED_COUNTER: 'biab_unmatched-counter',
  MATCHED_COUNTER: 'biab_matched-counter',
  UNMATCHED_PROGRESS: 'biab_unmatched-progress',
  UNMATCHED_PROGRESS_BAR: 'biab_unmatched-progress-bar',
  OPEN_BET_EVENT_NAME: 'biab_betslip-open-bet-event-name',
  PERSISTENCE_TYPE: 'biab_betslip-persistence-type',

  /** Selected bet */
  BACK: 'biab_back',
  LAY: 'biab_lay',
  FANCY_SWAP: 'biab_fancy-swap',
  BET_HEADER: 'biab_betslip-bet-header',
  BET_CONTENT: 'biab_betslip-bet-content',
  BET_EVENT_NAME: 'biab_betslip-bet-event-name',
  BET_EVENT_EW_NAME: 'biab_betslip-bet-ew-name',
  PANEL: 'biab_betslip-panel',
  LIVE: 'biab_betslip-live-label',
  SETTINGS: 'biab_betslip-settings',
  BET_BTN: 'biab_betslip-selected-bet-btn',
  DISABLED: 'biab_disabled',
  CANCEL_ALL_BTN: 'biab-betslip-cancel-all-btn',
  PLACE_BETS_BTN: 'biab-betslip-place-bets-btn',
  EDIT_BETS_BTN: 'biab-betslip-edit-bets-btn',
  CONFIRM_BETS_BTN: 'biab-betslip-confirm-bets-btn',
  CONFIRM_NOTIFICATION: 'biab_betslip-confirm-notification',
  LINE_ODDS_NOTIFICATION: 'biab_betslip-line-odds-notification',

  /** Matched & Unmatched bets */
  MATCHED: 'biab_matched',
  UNMATCHED: 'biab_unmatched',
  OPENED_BET: 'biab_betslip-opened-bet',
  OPENED_BET_TITLE: 'biab_betslip-opened-bet-title',
  OPENED_BET_HEADER: 'biab_betslip-opened-bet-header',
  OPENED_BET_PLACED_TIME: 'biab_betslip-opened-bet-placed-time',
  OPENED_BET_LINE_ODDS_MSG: 'biab_betslip-opened-bet-line-odds-msg',
  OPENED_BETS_SIDE_HEADER: 'biab_betslip-opened-bets-side-header',

  /** Unmatched bets */
  OPENED_BET_FRAME: 'biab_betslip-opened-bet-frame',
  OPENED_BET_FRAME_HEADER: 'biab_betslip-opened-bet-frame-header',
  OPENED_BET_BTN: 'biab_betslip-opened-bet-btn',
  OPENED_BET_UPDATE_BTN: 'biab_betslip-opened-bet-update-btn',
  OPENED_BET_TAKE_BTN: 'biab_betslip-opened-bet-take-btn',
  OPENED_BET_CANCEL_BTN: 'biab_betslip-opened-bet-cancel-btn',
  OPENED_BET_CANCELLED_MSG: 'biab_betslip-opened-bet-cancelled-msg',

  /** Matched bets */
  OPENED_BET_PLACEMENT_MSG: 'biab_opened-bet-placement-msg',

  /** Tabs */
  BETSLIP_WRAP: 'biab_betslip-tabs-wrap',
  BETSLIP_TAB: 'biab_betslip-tab',
  SELECTED: 'biab_selected',

  ERROR_MSG: 'biab_betslip-error-msg',

  /** Icons */
  HEADER_FILTER_ICON: 'biab_betslip-header-filter-icon',
  ACTIVE: 'biab_active',
  BETSLIP_COLLAPSE: 'biab_betslip-header-collapse',
  REMOVE_SELECTED_BET_ICON: 'biab_betslip-remove-selected-bet-icon',
  CONFIRM_BETS_CHECKBOX: 'biab_betslip-confirm-bets-checkbox',
  ODDS_ARROW: 'biab_betslip-odds-arrow',
  MATCHED_MSG_ICON: 'biab_betslip-matched-msg-icon',
  PRICE_INDICATOR: 'biab_betslip-price-indicator',
  UP: 'biab_up',
  DOWN: 'biab_down',
  CLOCK_ICON: 'biab_clock-icon',
  SUCCESS: 'biab_success',
  ERROR: 'biab_error',
  INFO: 'biab_info',
  WARNING_ICON: 'biab_warning-icon',
  CHECKMARK_ICON: 'biab_checkmark-icon',
  INFO_ICON: 'biab_info-icon',
  SPINNER_ICON: 'biab_spinner-icon',
  STAR_ICON: 'biab_star-icon',
  BG_COLOR: 'biab_bg-color',

  /** Cancel all button */
  BTN: 'biab_btn',
  CANCEL_ALL_OFFERS_BTN_WRAP: 'biab_cancel-all-bets-wrapper',
  CANCEL_ALL_OFFERS_BTN: 'biab_cancel-btn',
  CONFIRM_CANCEL_ALL_OFFERS_BTN: 'biab_confirm-btn',

  CASH_OUT_ICON: 'biab_cashout-icon',
  BET_CASHED_OUT: 'biab_bet-cashed-out',
  WHAT_IF: 'biab_betslip-what-if'
};

export const todayCardsBranding = {
  SECTION: 'biab_today-cards-section',
  TILE: 'biab_today-cards-tile',
  ACTIVE: 'biab_active'
};

export const asianViewBetslipBranding = {
  BETSLIP: 'biab_av-betslip',
  BACKGROUND: 'biab_av-betslip-bg',
  ACTIONS_CONTAINER: 'biab_av-actions-container',
  ACTIONS_CONTAINER_BORDER: 'biab_av-actions-container-border',
  BACK_BET: 'biab_av-back-bet',
  LAY_BET: 'biab_av-lay-bet',
  SELECTED_BET: 'biab_av-selected-bet',
  BET_SEPARATOR: 'biab_av-bet-separator',
  LOCKED_BET: 'biab_av-locked-bet',
  MATCHED_BET: 'biab_av-matched-bet',
  TRIGGERED_BY_CASH_OUT: 'biab_av-triggered-by-cash-out',
  TRIGGERED_BY_CASH_OUT_LABEL: 'biab_av-triggered-by-cash-out-label',
  PRICE_MORE: 'biab_av-price-more',
  PRICE_LESS: 'biab_av-price-less',
  LIVE_LABEL: 'biab_av-live-label',

  // Betslip tabs
  SELECTED: 'biab_av-selected',
  BETSLIP_TAB: 'biab_av-betslip-tab',
  BETSLIP_TAB_COUNTERS: 'biab_av-betslip-tab-counters',

  // Betslip messages section
  LOCKED_BET_MSG: 'biab_av-locked-bet-msg',
  ODDS_CHANGED_MSG: 'biab_av-odds-changed-msg',
  PLACED_MSG: 'biab_av-placed-msg',
  PLACED_BETTER_MSG: 'biab_av-placed-better_msg',
  INFO_MSG: 'biab_av-info-msg',
  ERROR_MSG: 'biab_av-error_msg',
  ERROR_INPUT: 'biab_av-error_input',
  ERROR_NOTE: 'biab_av-error_note',

  // Odds
  ODDS_UP: 'biab_odds-up',
  ODDS_DOWN: 'biab_odds-down',

  // Betslip - Cash Out tab
  CASH_OUT_MARKET: 'biab_av-cash-out-market',

  // Quick Betting
  QUICK_BETTING: 'biab_av-quick-betting',
  PROGRESS_BAR: 'biab_av-progress-bar',
  PROGRESS_BAR_INDICATOR: 'biab_av-progress-bar-indicator',
  RESULTING_POP_UP: 'biab_av-resulting-pop-up',
  RESULTING_POP_UP_COUNTER: 'biab_av-resulting-pop-up-counter'
};

export const asianViewMiddleSection = {
  MAIN_CONTAINER: 'biab_middle-section',
  MAIN_CONTAINER_BORDER: 'biab_middle-section-border',
  MAIN_CONTAINER_BORDER_BG: 'biab_middle-section-border-bg',
  HEADER: 'biab_av-middle-header',
  ACCOUNT_SUB_HEADER: 'biab_account-sub-header',
  // Leagues dropdown
  LEAGUES_DROPDOWN: 'biab_av-leagues-dropdown',
  LEAGUES_DROPDOWN_FOOTER: 'biab_av-leagues-dropdown-footer',
  // League
  LEAGUE_HEADER: 'biab_av-league-header',
  LEAGUE_HEADER_BORDER: 'biab_av-league-header-border',
  FAVOURITE_TEAM: 'biab_av-favourite-team',
  BACK_COLUMN: 'biab_av-market-back-col',
  LAY_COLUMN: 'biab_av-market-lay-col',
  LIVE_SCORE_CONTAINER: 'biab_av-event-score-container',
  TIME_CONTAINER: 'biab_av-event-time-container',
  // Coupon tabs
  COUPON_TAB: 'biab_av-coupon-tab',
  SELECTED: 'biab_av-selected',
  COUPON_CONTAINER: 'biab_av-coupon-container',
  COUPON_COUNTER: 'biab_av-coupon-counter',
  COUPON_BUTTON: 'biab_av-coupon-button',
  COUPON_BORDER: 'biab_av-coupon-border',
  MARKET_BODY: 'biab_av-market-body',
  MARKET_BORDER: 'biab_av-market-border',
  MARKET_BORDER_BG: 'biab_av-market-border-bg',
  IN_PLAY_EVENT_1: 'biab_av-in-play-event-1',
  IN_PLAY_EVENT_2: 'biab_av-in-play-event-2',
  NON_PLAY_EVENT_1: 'biab_av-non-play-event-1',
  NON_PLAY_EVENT_2: 'biab_av-non-play-event-2',
  // Sticky header
  EVENT_STICKY_HEADER: 'biab_av-event-sticky-header',
  EVENT_STICKY_HEADER_DATE_TIME: 'biab_av-event-sticky-header-date-time',
  EVENT_STICKY_HEADER_LIVE: 'biab_av-event-sticky-header-live',
  EVENT_STICKY_HEADER_SCORE: 'biab_av-event-sticky-header-score',
  // Single Line view & Match Odds view
  SINGLE_LINE_MARKET_HEADER: 'biab_av-single-line-market-header',
  SINGLE_LINE_HDP_GOAL_HEADER: 'biab_av-single-line-hdp-goal-header',
  // Correct Score, Total Goals, Half Time / Full Time
  MARKET_HEADER: 'biab_av-market-header',
  SELECTION_TYPE_HEADER: 'biab_av-selection-type-header',
  SELECTION_HEADER: 'biab_av-selection-header',
  // Cash Out tab / page
  CASH_OUT_MARKET_HEADER: 'biab_av-page-cash-out-market-header',
  CASH_OUT_MARKET: 'biab_av-page-cash-out-market',
  // Cells
  BACK_CELL: 'biab_av-back-cell',
  LAY_CELL: 'biab_av-lay-cell',
  SELECTED_CELL: 'biab_av-selected-cell',
  MORE_PROFITABLE_CELL: 'biab_av-more-profitable-cell',
  MORE_PROFITABLE_INDICATOR: 'biab_av-more-profitable-indicator',
  LESS_PROFITABLE_CELL: 'biab_av-less-profitable-cell',
  LESS_PROFITABLE_INDICATOR: 'biab_av-less-profitable-indicator',
  LOCKED_CELL: 'biab_av-locked-cell',
  // PL Table
  PL_TABLE_POP_UP: 'biab_av-pl-table-pop-up',
  PL_TABLE_NO_DATA: 'biab_av-pl-table-no-data',
  PL_TABLE: 'biab_av-pl-table',
  PL_TABLE_CURRENT_SCORE: 'biab_av-pl-table-current-score',
  // Video streaming & Statistics Header
  WIDGET_HEADER: 'biab_widget-header',
  MORE_PAGE: 'biab_more-page',
  SWITCH_TO: 'biab_switch-to',
  POPULAR_LINKS: 'biab_popular-links',
  OUTRIGHT_MARKET: 'biab_outright-market',
  OUTRIGHT_TIME: 'biab_outright-time',
  MOBILE_SUB_HEADER: 'biab_mobile-sub-header',
  MOBILE_MENU_BTN: 'biab_menu-btn',
  MOBILE_SUB_HEADER_BTN: 'biab_sub-header-btn',
  MOBILE_SUB_HEADER_ACTIVE_BTN: 'biab_active-btn',
  MOBILE_SEARCH_EXPANDED: 'biab_mobile-search-expanded',
  MOBILE_SEARCH: 'biab_mobile-search',
  SEARCH_INPUT: 'biab_search-input',
  AV_MENU: 'biab_av-mobile-menu',
  MENU_COUNTER: 'biab_menu-counter',
  TAB_COUNTER: 'biab_tab-counter',
  EMPTY_MESSAGE: 'biab_empty-message',
  SECONDARY_TEXT: 'biab_secondary-text',
  BET_LIST_TAB: 'biab_av-bet-list-tab',
  BET_LIST_GOAL_INDICATOR: 'biab_av-bet-list-goal-indicator',
  BET_LIST_BOTTOM_LINK: 'biab_av-bet-list-bottom-link',
  VIEW_BY_DROPDOWN: 'biab_av-view-by-dropdown',
  VIEW_BY_ITEM: 'biab_av-view-by-item',
  VIEW_BY_ITEM_COUNTER: 'biab_av-view-by-counter',
  LAY_BETTING_SETTING: 'biab_av-lay-betting-setting',
  MOBILE_LIST_ITEM: 'biab_mobile-list-item'
};

export const asianViewDropdown = {
  // Dropdown
  dropdownBtn: 'biab_av-dropdown-btn',
  dropdownItems: 'biab_av-dropdown-item',
  dropdownItem: 'biab_av-dropdown-item',
  active: 'biab_av-active',
  selected: 'biab_av-selected'
};

export const iconsBranding = {
  TOGGLE: 'biab_toggle',
  ACTIVE: 'biab_active'
};

export const componentsBranding = {
  NOTIFICATION: 'biab_bet-notification',
  FORM_INPUT: 'biab_form-input',
  ERROR: 'biab_error',
  SUCCESS: 'biab_success',
  DISABLED: 'biab_disabled',
  ACTIVE: 'biab_active',
  PRIMARY_BTN: 'biab_primary-btn',
  SECONDARY_BTN: 'biab_secondary-btn',
  TERTIARY_BTN: 'biab_tertiary-btn',
  TAB_CONTAINER: 'biab_tabs-container',
  TAB: 'biab_tab',
  DROPDOWN_ITEM: 'biab_dropdown-item',
  CHECKBOX: 'biab_checkbox',
  RADIO: 'biab_radio-btn'
};

export const asianViewComponents = {
  PRIMARY_BTN: 'biab_av-primary-btn',
  SECONDARY_BTN: 'biab_av-secondary-btn',
  INPUT: 'biab_av-input',
  SKELETON: 'biab_av-skeleton',
  DISABLED: 'biab_av-disabled'
};

export const asianViewPopUps = {
  POP_UP: 'biab_av-pop-up',
  POP_UP_HEADER: 'biab_av-pop-up-header',
  POP_UP_BORDER: 'biab_av-pop-up-border',
  POP_UP_BODY: 'biab_av-pop-up-body',
  POP_UP_FOOTER: 'biab_av-pop-up-footer'
};

export const pagination = {
  PAGINATION: 'biab_pagination',
  INACTIVE_LINK: 'biab_disabled',
  PAGINATION_BTN: 'biab_pagination-btn',
  SELECTED: 'biab_selected'
};

export const bettingHistoryPagesDropdown = {
  dropdownBtn: 'biab_account-dropdown-placeholder',
  dropdownItems: 'biab_account-dropdown-items',
  dropdownItem: 'biab_account-dropdown-item'
};

export const cashOut = {
  CASH_OUT_BTN: 'biab_cash-out-button',
  CASH_OUT_MSG: 'biab_cash-out-message',
  SUCCESS: 'biab_success',
  ERROR: 'biab_error'
};

export const asianViewHeader = {
  SEARCH_BOX: 'biab_av-header-search-box',
  ACTIVE: 'biab_active'
};

export const subHeader = {
  SUBHEADER_WRAPPER: 'biab_subheader-dropdown-wrapper',
  SUBHEADER_HOLDER: 'biab_subheader-dropdown-holder',
  SUBHEADER_ITEM: 'biab_subheader-dropdown-item',
  ACTIVE: 'biab_active'
};

export const asianViewNavigation = {
  SIDEBAR: 'biab_av-sidebar',
  FAVOURITE_ITEM: 'biab_av-favourite-item',
  FAVOURITE_EMPTY: 'biab_av-favourite-empty',
  BALANCE_BLOCK: 'biab_av-navigation-balance-block',
  NAVIGATION_HEADER: 'biab_av-navigation-header',
  NAVIGATION_TAB: 'biab_av-navigation-tab',
  SELECTED: 'biab_av-selected',
  SPORT_HEADER: 'biab_av-navigation-sport-header',
  LIVE_EVENTS_COUNTER: 'biab_av-navigation-live-events-counter',
  NAVIGATION_LINK: 'biab_av-navigation-link'
};

export const asianViewIcons = {
  SEARCH_ICON: 'biab_av-search-icon',
  FAVOURITE_ICON: 'biab_av-favourite-icon',
  NAV_SPORT_ICON: 'biab_av-nav-sport-icon',
  ACTIVE: 'biab_av-active',
  PRICE_MORE_ICON: 'biab_av-price-more-icon',
  PRICE_LESS_ICON: 'biab_av-price-less-icon',
  LOCKED_CELL_ICON: 'biab_av-locked-cell-icon',
  BETSLIP_SPINNER: 'biab_av-betslip-spinner',
  MIDDLE_SECTION_SPORT_ICON: 'biab_av-ms-sport-icon',
  CASH_OUT_ICON: 'biab_av-cash-out-icon',
  ENABLED: 'biab_av-enabled',
  SHOW_MORE_LINES_ICON: 'biab_av-show-more-lines-icon',
  EXPAND_EVENT_ICON: 'biab_av-expand-event-icon',
  RULES_ICON: 'biab_av-rules-icon',
  RULES_ICON_BG: 'biab_av-rules-icon-bg',
  LIMITED_OFFERING_ICON: 'biab_av-limited-offering-icon',
  DROPDOWN_ICON: 'biab_av-dropdown-icon',
  QUICK_BETTING_SPINNER: 'biab_av-quick-betting-spinner',
  PL_TABLE_ICON: 'biab_av-pl-table-icon',
  SELECTED: 'biab_av-selected'
};

export const mobileBetslip = {
  MOBILE_BETSLIP: 'biab_mobile-betslip',
  BACK: 'biab_back',
  LAY: 'biab_lay',
  UNMATCHED: 'biab_unmatched',
  DISABLED: 'biab_disabled',
  BET_HEADER: 'biab_bet-header',
  KEYBOARD_KEY: 'biab_mobile-keyboard-key',
  OPENED_BETS: 'biab_mobile-opened-bets',
  UNMATCHED_INDICATOR: 'biab_unmatched-bets-indicator',
  MATCHED_INDICATOR: 'biab_matched-bets-indicator',
  TAB: 'biab_tab',
  SELECTED: 'biab_selected',
  OPENED_BET: 'biab_mobile-opened-bet'
};

export const mobileComponents = {
  NOTIFICATION: 'biab_mobile-notification',
  INFO: 'biab_info',
  INFO_SECONDARY: 'biab_info-secondary',
  SUCCESS: 'biab_success',
  ERROR: 'biab_error',
  BUTTON: 'biab_mobile-button',
  PRIMARY: 'biab_primary',
  DISABLED: 'biab_disabled',
  FOCUS: 'biab_focus',
  SECONDARY: 'biab_secondary',
  TERTIARY: 'biab_tertiary',
  INPUT: 'biab_mobile-input'
};

export const mobileIcons = {
  LOCK_ICON: 'biab_lock-icon',
  OPEN_LOCK_ICON: 'biab_open-lock-icon',
  CLOCK_ICON: 'biab_clock-icon',
  SUCCESS: 'biab_success',
  ERROR: 'biab_error',
  INFO: 'biab_info',
  WARNING_ICON: 'biab_warning-icon',
  CHECKMARK_ICON: 'biab_checkmark-icon',
  INFO_ICON: 'biab_info-icon',
  SPINNER_ICON: 'biab_spinner-icon',
  STAR_ICON: 'biab_star-icon',
  BG_COLOR: 'biab_bg-color'
};

export const header = {
  SETTINGS_MENU: 'biab_settings-menu',
  SELECTED: 'biab_selected',
  DEFAULT_STAKE: 'biab_default-stake'
};
