import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import AsianViewFavouriteStar from 'components/AsianViewFavouriteStar';
import AsianViewInPlayCell from 'components/AsianViewInPlayCell';
import AsianViewMatchStatistics from 'components/AsianViewMatchStatistics';
import AsianViewBackLayLabels from 'components/AsianViewPageModule/components/Event/MarketCell/AsianViewBackLayLabels';
import SelectionOdds from 'components/AsianViewPageModule/components/Event/MarketCell/SelectionOdds';
import ProfitLossTablePopUpButton from 'components/ProfitLossTablePopUp/components/ProfitLossTablePopUpButton/ProfitLossTablePopUpButton';
import VideoStream from 'components/VideoStream';
import { FAVORITES_TYPES } from 'constants/app';
import { AsianViewSection, TOTAL_GOALS_HEADER } from 'constants/asianView';
import { asianViewMiddleSection as branding } from 'constants/branding';
import useAsianLayBetting from 'hooks/useAsianLayBetting';
import useCustomResizeObserver from 'hooks/useCustomResizeObserver';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { getAsianViewEventIndex } from 'redux/modules/asianView/selectors';
import { TAsianViewEvent } from 'redux/modules/asianView/type';
import { getMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';

import styles from './styles.module.scss';

type TotalGoalsEventProps = {
  sportId: string;
  competitionId: string;
  isFirst?: boolean;
  event: TAsianViewEvent;
};

const TotalGoalsEvent = ({ sportId, competitionId, isFirst, event }: TotalGoalsEventProps) => {
  const translation = useSelector(getTranslation);

  const { translations, name, id, matchStatEnabled, videoStreamingEnabled, sections } = event;
  const market = sections[AsianViewSection.TOP]?.[0];

  const marketPricesId = useSelector(getMarketPricesId(market?.id ?? ''));
  const eventIndex = useSelector(getAsianViewEventIndex(event.id));

  const containerRef = useRef<HTMLDivElement>(null);

  const isLayBetting = useAsianLayBetting();
  const { ref, isIntersecting } = useMarketsPricesVisibleSocketParam({
    marketId: market.id,
    eventId: id,
    section: MarketsPricesSocketParamsSections.AsianViewMiddleSection,
    observerOptions: { rootMargin: '300px' }
  });
  const { height } = useCustomResizeObserver({ ref: containerRef });

  const brandingStyles = {
    [branding.IN_PLAY_EVENT_1]: market?.inPlay && eventIndex % 2 === 0,
    [branding.IN_PLAY_EVENT_2]: market?.inPlay && eventIndex % 2 !== 0,
    [branding.NON_PLAY_EVENT_1]: !market?.inPlay && eventIndex % 2 === 0,
    [branding.NON_PLAY_EVENT_2]: !market?.inPlay && eventIndex % 2 !== 0
  };

  useEffect(() => {
    ref(containerRef.current);
  }, []);

  return (
    <div
      ref={containerRef}
      data-market-id={market.id}
      data-event-id={id}
      data-market-prices="true"
      className={classNames({
        [styles.totalGoals]: isIntersecting,
        [branding.MAIN_CONTAINER_BORDER]: isIntersecting
      })}
    >
      {!isIntersecting && <div style={{ height }} />}
      {isIntersecting && (
        <>
          <div className={classNames(styles.totalGoals__header, brandingStyles)}>
            <div className={styles.totalGoals__titleHeader}>
              <AsianViewInPlayCell
                marketId={market?.id}
                marketStartTime={market?.startTime}
                containerClassName={styles.totalGoals__inPlayCell}
              />
              <div className="biab_asian-view-event-actions">
                <AsianViewFavouriteStar
                  favourite={{
                    entryName: translations[translation],
                    entryId: id,
                    entryType: FAVORITES_TYPES.event,
                    sportId
                  }}
                />
              </div>
              <div className={styles.totalGoals__name} data-tooltip-id={'tooltip'} data-tooltip-html={name}>
                {name}
              </div>
            </div>
            <div className={styles.totalGoals__streming}>
              <ProfitLossTablePopUpButton
                className={styles.totalGoals__plIcon}
                eventId={event.id}
                marketId={market?.id}
              />
              {videoStreamingEnabled && (
                <VideoStream hideLabel visible={videoStreamingEnabled} isInHeader eventId={id} />
              )}
              {matchStatEnabled && <AsianViewMatchStatistics matchStatEnabled={matchStatEnabled} eventId={id} />}
            </div>
          </div>
          <div className={styles.totalGoals__content}>
            <div className={classNames(styles.totalGoals__content__header, branding.MAIN_CONTAINER_BORDER)}>
              {TOTAL_GOALS_HEADER.map(item => (
                <div
                  key={item}
                  className={classNames(
                    styles.totalGoals__content__headerItem,
                    branding.MAIN_CONTAINER_BORDER,
                    branding.SELECTION_HEADER
                  )}
                >
                  {item}
                </div>
              ))}
            </div>
            <div className={styles.totalGoals__content__runners}>
              {marketPricesId && (
                <>
                  {market?.runners?.map(runner => (
                    <div
                      key={runner.id}
                      className={classNames(
                        styles.totalGoals__content__runner,
                        branding.MAIN_CONTAINER_BORDER,
                        brandingStyles
                      )}
                    >
                      {isLayBetting && isFirst && <AsianViewBackLayLabels />}
                      <SelectionOdds
                        competitionId={competitionId}
                        eventId={event.id}
                        marketId={market?.id || ''}
                        runner={runner}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TotalGoalsEvent;
