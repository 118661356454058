import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import AsianViewSingleEventOutrightLink from 'components/AsianViewSingleEventOutrightLink';
import AVChangedOddsNotification from 'components/AVChangedOddsNotification/AVChangedOddsNotification';
import Loader, { CircleColors } from 'components/Loader';
import {
  ASIAN_SINGLE_VIEW_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE,
  ASIAN_VIEW_PAGE_PARAM,
  AsianViewMarketLink,
  AsianViewTimeFilter
} from 'constants/asianView';
import {
  ASIAN_VIEW_BETSLIP_VALIDATION_MESSAGE_TIMEOUT,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS,
  ASIAN_VIEW_PLACE_BET_ERRORS_WITH_INTERVAL
} from 'constants/betslip';
import { asianViewBetslipBranding as branding, asianViewIcons } from 'constants/branding';
import { ASIAN_BASE_URL } from 'constants/locations';
import { MARKET_TYPES } from 'constants/marketTypes';
import { ERROR_BET_IS_EXPIRED, VALIDATION_ERROR_BET_OUTDATED_ODDS } from 'constants/placement';
import useAdjHandicap from 'hooks/useAdjHandicap';
import useAsianOdds from 'hooks/useAsianOdds';
import useAsianViewPlacementHighlighting from 'hooks/useAsianViewPlacementHighlighting';
import { useCurrency } from 'hooks/useCurrency';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import useMinMaxSize from 'hooks/useMinMaxSize';
import {
  getCurrency,
  getIsDesktopInfiniteScrollEnabled,
  getIsOperatorBettingLimitsEnabled,
  getMinAmountToShowOdds
} from 'redux/modules/appConfigs/selectors';
import { getOddsType } from 'redux/modules/appSettings/selectors';
import { getAsianMarket } from 'redux/modules/asianView/selectors';
import {
  removeBetStatus,
  removeSelectedBets,
  setBetSlipValidationMessage,
  setPlaceBetsState,
  updateSelectedBet
} from 'redux/modules/asianViewBetslip';
import {
  getAsianViewBetOfferIdByBetUuid,
  getAsianViewBetStatusByOfferId,
  getAsianViewBetStatusErrorByOfferId,
  getPlaceBetsState
} from 'redux/modules/asianViewBetslip/selectors';
import {
  AsianViewSizeValidationType,
  TAsianSelectedBet,
  TAsianUpdatedBetData,
  TAsianViewPlaceBetErrorMessageId
} from 'redux/modules/asianViewBetslip/type';
import { getAsianCurrentBetByOfferId } from 'redux/modules/asianViewCurrentBets/selectors';
import { EAsianBettingActions } from 'redux/modules/asianViewQuickBetting/type';
import { EPlaceBetsStates } from 'redux/modules/betslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import {
  getBetAmount,
  getBetOdds,
  getIsMarketInPlayByMarketPricesId,
  getIsMarketPrices,
  getIsMarketPricesKeyLineDefinition,
  getMarketPricesBettingTypeById,
  getMarketPricesCurrency,
  getMarketPricesFirstKeyLineHandicap,
  getMarketPricesFirstKeyLineId,
  getMarketPricesRunnerLockedBySelectionId,
  getMarketPricesRunners,
  getMarketPricesRunnerStatusBySelectionId,
  getMarketPricesScoreById,
  getMarketPricesStatusById
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { getUserAsianViewAutoRefresh } from 'redux/modules/user/selectors';
import { MarketStatus, SportId } from 'types';
import { BetDatabaseName, BetTypes, TPrice, TProfit, TSize } from 'types/bets';
import { EInputFieldTypes } from 'types/betslip';
import { RunnersStatuses } from 'types/markets';
import {
  calculatePayout,
  getAsianViewBetUuid,
  getIsValidMarketLink,
  getIsValidTimeFilter,
  replaceHandicaps
} from 'utils/asianView';
import { getIsHandicapLineDisappeared, getIsScoreChanged } from 'utils/avSelectedBet';
import { calculateLiability, calculateSize } from 'utils/liability';
import { getMarketTypes } from 'utils/market';
import convertOdds from 'utils/oddsConverter';
import { validateSize } from 'utils/size';

import BetMax from './components/BetMax';
import BetMin from './components/BetMin';
import Info from './components/Info';
import Input, { IInput } from './components/Input';
import Message from './components/Message';

import styles from './styles.module.scss';

type AVSelectedBetProps = {
  bet: TAsianSelectedBet;
  index: number;
};

const AVSelectedBet = ({ bet, index }: AVSelectedBetProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sportId = SportId.SOCCER, timeFilter, marketLink } = useParams();
  const [searchParams] = useSearchParams();

  const handicap = +(bet.handicap || 0);
  const isBack = bet.betType === BetTypes.BACK;
  const betName = isBack ? BetDatabaseName.BACK : BetDatabaseName.LAY;

  const oddsType = useSelector(getOddsType);
  const userAsianViewAutoRefresh = useSelector(getUserAsianViewAutoRefresh);
  const market = useSelector(getAsianMarket(bet.eventId, bet.marketId));
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(bet.marketId));
  const currencyCode = useSelector(getMarketPricesCurrency(bet.marketId));
  const bettingType = useSelector(getMarketPricesBettingTypeById(bet.marketId));
  const status = useSelector(getMarketPricesStatusById(bet.marketId));
  const minAmountToShowOdds = useSelector(getMinAmountToShowOdds);
  const isInfiniteScrollEnabled = useSelector(getIsDesktopInfiniteScrollEnabled);
  const betOfferId = useSelector(
    getAsianViewBetOfferIdByBetUuid(
      bet.marketId,
      getAsianViewBetUuid({
        marketId: bet.marketId,
        selectionId: bet.selectionId,
        handicap: bet.handicap,
        betType: bet.betType
      })
    )
  );
  const placedBet = useSelector(getAsianCurrentBetByOfferId(betOfferId || bet.offerId || null));
  const betStatus = useSelector(getAsianViewBetStatusByOfferId(betOfferId));
  const betStatusError = useSelector(getAsianViewBetStatusErrorByOfferId(betOfferId));
  const placeBetsState = useSelector(getPlaceBetsState);
  const firstKeyLineId = useSelector(getMarketPricesFirstKeyLineId(bet.marketId));
  const firstKeyLineHandicap = useSelector(getMarketPricesFirstKeyLineHandicap(bet.marketId));
  const isKeyLine = useSelector(getIsMarketPricesKeyLineDefinition(bet.marketId));
  const score = useSelector(getMarketPricesScoreById(bet.marketId));
  let odds = useSelector(getBetOdds(bet.marketId, bet.selectionId, handicap, betName, 0));
  const amount = useSelector(getBetAmount(bet.marketId, bet.selectionId, handicap, betName, 0));
  const runnerStatus = useSelector(getMarketPricesRunnerStatusBySelectionId(bet.marketId, bet.selectionId, handicap));
  const runnerStatuses = useSelector(getMarketPricesRunners(bet.marketId));
  const isLocked = useSelector(getMarketPricesRunnerLockedBySelectionId(bet.marketId, bet.selectionId, handicap));
  const isMarketPrices = useSelector(getIsMarketPrices(bet.marketId));
  const defaultCurrency = useSelector(getCurrency);
  const isOperatorBettingLimitsEnabled = useSelector(getIsOperatorBettingLimitsEnabled);

  const currency = useCurrency(currencyCode);
  const betPrice = useAsianOdds({ bet });
  const adjHandicap = useAdjHandicap(bet);

  const sizeInputRef = useRef<HTMLInputElement>(null);

  const [isStakeHovered, setIsStakeHovered] = useState(false);

  const { isAsianHandicap } = getMarketTypes(market?.marketType ?? '', bettingType);
  const isDefaultFormat = currency?.displayFormat === 1;
  const scoreLabel = isAsianHandicap && bet.score ? `(${bet.score})` : '';
  const isSuspended = status === MarketStatus.SUSPENDED;
  const isClosedStatus = status === MarketStatus.CLOSED;
  const pageParam = isInfiniteScrollEnabled ? '' : `&${ASIAN_VIEW_PAGE_PARAM}=0`;
  const sportIdParam = `&${ASIAN_SINGLE_VIEW_SPORT_ID}=${bet.sportId}`;
  const betSlipCashOutPage = searchParams.get(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE);
  const betSlipCashOutPageParam = betSlipCashOutPage
    ? `&${ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE}=${betSlipCashOutPage}`
    : '';
  const isMatched = (!!bet.offerId || betStatus === BetsStatusesTypes.MATCHED) && !!placedBet;
  const isPending =
    (betStatus === BetsStatusesTypes.PENDING && !betStatusError) ||
    (betStatus === BetsStatusesTypes.MATCHED && !placedBet);
  const isCancelled = betStatus === BetsStatusesTypes.CANCELLED;
  const isExpired = betStatus === BetsStatusesTypes.EXPIRED;
  const isConfirmState = placeBetsState === EPlaceBetsStates.CONFIRM;
  const isConfirmRemovingState = placeBetsState === EPlaceBetsStates.CONFIRM_REMOVING;
  const isScoreChanged = isMarketPrices && getIsScoreChanged({ bet, currentScore: score });
  const isHandicapLineClosed =
    bet.marketType === MARKET_TYPES.altTotalGoals && isMarketPrices && runnerStatus !== RunnersStatuses.ACTIVE;
  const roundedAmount = isDefaultFormat ? Math.floor(amount ?? 0) : Math.ceil(amount ?? 0);
  const oddsTypeLabel = t(`asianView.labels.betslip.oddsType.${oddsType}`);
  const isTimeoutToRemoveValidationMessage =
    !!bet.validationMessageId && ASIAN_VIEW_PLACE_BET_ERRORS_WITH_INTERVAL.includes(bet.validationMessageId);
  const isProgress = bet.bettingAction === EAsianBettingActions.PROGRESS;
  const isLoading = isProgress && !isMatched && !isCancelled && !isExpired;
  const isPlacement = isPending || isProgress || isMatched || isCancelled || isExpired;

  const isHandicapLineDisappeared = useMemo(() => {
    return getIsHandicapLineDisappeared({
      bet,
      firstKeyLineId,
      firstKeyLineHandicap,
      isAsianHandicap,
      isKeyLine,
      runnerStatuses
    });
  }, [bet, firstKeyLineHandicap, firstKeyLineId, isAsianHandicap, isKeyLine, runnerStatuses]);

  if (roundedAmount <= minAmountToShowOdds) {
    odds = 0;
  }

  const preventOddsRefresh = isProgress || (betStatus && !isPending) || isHandicapLineDisappeared;
  const isPrevOddsLower = !preventOddsRefresh && !!(bet.initPrice && +bet.initPrice < (odds || 0));
  const isGoingUp = !preventOddsRefresh && isBack ? isPrevOddsLower : !isPrevOddsLower;
  const hasAutoRefresh =
    !preventOddsRefresh &&
    userAsianViewAutoRefresh &&
    !!odds &&
    odds != bet.initPrice &&
    !isSuspended &&
    !isConfirmState;
  const isDisabledBet = !odds || isSuspended || isHandicapLineDisappeared || isLocked;
  const isPlacedBack = isMatched && placedBet.side === BetTypes.BACK;
  const placedWithBetterOdds =
    isMatched && (isPlacedBack ? placedBet.averagePrice > placedBet.price : placedBet.averagePrice < placedBet.price);
  const isOddsUp = (hasAutoRefresh && isGoingUp && !isDisabledBet) || (placedWithBetterOdds && isMatched);
  const isOddsDown = (hasAutoRefresh && !isGoingUp && !isDisabledBet) || (!placedWithBetterOdds && isMatched);
  const convertedOdds = convertOdds(
    (preventOddsRefresh || isConfirmState ? bet.actualPrice || bet.price : betPrice) || ' ',
    oddsType
  );
  const convertedPlacedOdds = convertOdds((placedWithBetterOdds ? placedBet.averagePrice : betPrice) || ' ', oddsType);

  const { minSizeFormatted, maxSizeFormatted } = useMinMaxSize({ marketId: bet.marketId });
  const { areOddsHighlighted } = useAsianViewPlacementHighlighting({
    odds,
    isDisabledBet,
    hasAutoRefresh
  });
  const minMaxBetDisplayType = 'column';

  const payout = calculatePayout({
    price: (isMatched && placedBet.averagePrice) || betPrice,
    size: bet.size
  });
  const { noFormattedAmount: payoutFormatted } = useFormatCurrency(payout, currencyCode, {
    isCheckIndian: true,
    noRounding: true
  });

  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: bet.marketId,
    eventId: bet.eventId,
    section: MarketsPricesSocketParamsSections.AsianViewDesktopBetSlip
  });

  const setValidationMessage = ({
    message,
    validationType,
    messageId
  }: {
    message: string;
    validationType?: AsianViewSizeValidationType;
    messageId?: null | TAsianViewPlaceBetErrorMessageId;
  }) => {
    dispatch(
      setBetSlipValidationMessage({
        identifier: bet.identifier,
        message,
        ...(!isUndefined(validationType) ? { sizeValidationType: validationType } : {}),
        ...(!isUndefined(messageId) ? { messageId } : {})
      })
    );
  };

  const updateBet = (
    {
      price,
      size,
      newPrice,
      profit,
      noUpdateLocalStorage
    }: {
      price?: TPrice;
      size?: TSize;
      newPrice?: boolean;
      profit?: TProfit;
      noUpdateLocalStorage?: boolean;
    },
    enabledAutoRefresh = false
  ) => {
    if (!isDisabledBet) {
      let updatedSize: TSize;
      let updatedProfit: TProfit;
      const updateData: Partial<TAsianUpdatedBetData> = {};

      if (!isUndefined(profit)) {
        updatedSize = calculateSize(betPrice, profit);
        updatedProfit = profit;
      } else if (!isUndefined(size)) {
        updatedSize = size;
        updatedProfit = calculateLiability(betPrice, size, bet);
      } else if (!isUndefined(price)) {
        updatedProfit = calculateLiability(price, bet.size, bet);
      }

      const newSize = !isUndefined(updatedSize) ? { size: updatedSize } : {};
      const newActualPrice = newPrice ? { actualPrice: price } : {};
      const newProfit = !isUndefined(updatedProfit) ? { profit: updatedProfit } : {};

      const validation = validateSize({
        size: newSize?.size || bet.size,
        betType: bet.betType,
        currency,
        defaultCurrency,
        currencyCode,
        isOperatorBettingLimitsEnabled
      });

      if (!validation.isValid && validation.errorMessage?.text) {
        updateData.validationMessage = t(validation.errorMessage?.text, validation.errorMessage.params ?? {});
        updateData.sizeValidationType = validation.type;
        updateData.validationMessageId = validation.messageId;
      } else {
        if (!enabledAutoRefresh) {
          updateData.validationMessage = '';
        }
        updateData.sizeValidationType = null;
        updateData.validationMessageId = null;
      }

      updateData.isValid = validation.isValid;
      updateData.validSizeValue = validation.validValue;

      dispatch(
        updateSelectedBet({
          identifier: bet.identifier,
          updateLocalStorageBets: !noUpdateLocalStorage,
          data: {
            ...newSize,
            ...newProfit,
            ...newActualPrice,
            ...updateData
          }
        })
      );
    }
  };

  const handleRemoveSelectedBet = () => {
    dispatch(removeSelectedBets([bet.identifier]));
  };

  const isInputDisabled =
    isConfirmState || isConfirmRemovingState || isDisabledBet || isMatched || isPending || isProgress;

  const inputProps: Omit<IInput, 'type'> = {
    currencyCode,
    sizeInputRef,
    isHovered: isStakeHovered,
    ...bet,
    readOnly: isInputDisabled && !isDisabledBet,
    disabled: isInputDisabled && isDisabledBet,
    index,
    updateBet,
    setValidationMessage,
    minSizeFormatted,
    maxSizeFormatted,
    isBackground: true
  };

  useEffect(() => {
    if (isTimeoutToRemoveValidationMessage) {
      setTimeout(() => {
        setValidationMessage({ message: '', messageId: null, validationType: null });
        if (betOfferId) {
          dispatch(removeBetStatus(betOfferId));
        }
      }, ASIAN_VIEW_BETSLIP_VALIDATION_MESSAGE_TIMEOUT);
    }
  }, [isTimeoutToRemoveValidationMessage]);

  useEffect(() => {
    if (!preventOddsRefresh && !hasAutoRefresh && odds && odds !== bet.price) {
      dispatch(
        updateSelectedBet({
          identifier: bet.identifier,
          data: {
            price: odds,
            profit: calculateLiability(odds, bet.size, {
              marketType: bet.marketType,
              bettingType,
              betType: bet.betType
            })
          }
        })
      );
    }
  }, [preventOddsRefresh, hasAutoRefresh]);

  useEffect(() => {
    if (placedBet && placedBet.averagePrice) {
      dispatch(
        updateSelectedBet({
          identifier: bet.identifier,
          data: {
            profit: calculateLiability(placedBet.averagePrice, bet.size, {
              marketType: bet.marketType,
              bettingType,
              betType: bet.betType
            })
          }
        })
      );
    }
  }, [placedBet]);

  useEffect(() => {
    if (betPrice && bet.size) {
      updateBet({ price: betPrice, newPrice: true, noUpdateLocalStorage: true });
    }
  }, []);

  useEffect(() => {
    if (!preventOddsRefresh && hasAutoRefresh && betPrice && betPrice !== bet.price) {
      updateBet({ price: betPrice, newPrice: true, noUpdateLocalStorage: true }, hasAutoRefresh);
    }
  }, [preventOddsRefresh, hasAutoRefresh, betPrice, bet.price]);

  useEffect(() => {
    if (bet.isInPlay !== isInPlay && !isDisabledBet) {
      dispatch(updateSelectedBet({ identifier: bet.identifier, data: { isInPlay } }));
    }
  }, [isInPlay, isDisabledBet]);

  useEffect(() => {
    if (isDisabledBet !== bet.isDisabled) {
      dispatch(updateSelectedBet({ identifier: bet.identifier, data: { isDisabled: isDisabledBet } }));
    }
  }, [isDisabledBet]);

  useEffect(() => {
    if (isScoreChanged || isHandicapLineClosed || isClosedStatus) {
      handleRemoveSelectedBet();
    }
  }, [isScoreChanged, isHandicapLineClosed, isClosedStatus]);

  useEffect(() => {
    if (isAsianHandicap && adjHandicap !== bet.adjHandicap) {
      dispatch(
        updateSelectedBet({
          identifier: bet.identifier,
          data: {
            adjHandicap,
            selectionName: replaceHandicaps(bet.selectionName, bet.adjHandicap, adjHandicap)
          }
        })
      );
    }
  }, [adjHandicap, isAsianHandicap]);

  useEffect(() => {
    if (isCancelled) {
      dispatch(
        setBetSlipValidationMessage({
          identifier: bet.identifier,
          message: t(VALIDATION_ERROR_BET_OUTDATED_ODDS),
          sizeValidationType: null,
          messageId: ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX026
        })
      );

      if (placeBetsState !== EPlaceBetsStates.SELECT) {
        dispatch(setPlaceBetsState(EPlaceBetsStates.SELECT));
      }
    }
  }, [isCancelled]);

  useEffect(() => {
    if (betStatusError || isExpired) {
      dispatch(
        setBetSlipValidationMessage({
          identifier: bet.identifier,
          messageId: isExpired ? null : betStatusError?.id ?? null,
          message: isExpired ? t(ERROR_BET_IS_EXPIRED) : betStatusError?.message ?? '',
          sizeValidationType: null
        })
      );

      if (placeBetsState !== EPlaceBetsStates.SELECT) {
        dispatch(setPlaceBetsState(EPlaceBetsStates.SELECT));
      }
    }
  }, [betStatusError, isExpired]);

  useEffect(() => {
    if (betStatusError || (betOfferId && betStatus && !isPending)) {
      dispatch(updateSelectedBet({ identifier: bet.identifier, data: { bettingAction: null } }));
    }
  }, [betOfferId, betStatusError, betStatus, isPending]);

  useEffect(() => {
    if (isOddsDown !== bet.isLowerPrice) {
      dispatch(updateSelectedBet({ identifier: bet.identifier, data: { isLowerPrice: isOddsDown } }));
    }
  }, [isOddsDown]);

  return (
    <div
      ref={ref}
      data-selection-id={bet.selectionId}
      data-market-id={bet.marketId}
      data-event-id={bet.eventId}
      data-market-prices={true}
      className={classNames(styles.bet, {
        [styles.bet__back]: isBack,
        [styles.bet__lay]: !isBack,
        [styles.bet__disabled]: isDisabledBet,
        [branding.SELECTED_BET]: !isDisabledBet,
        [branding.BACK_BET]: isBack && !isDisabledBet,
        [branding.LAY_BET]: !isBack && !isDisabledBet,
        [branding.LOCKED_BET]: isDisabledBet
      })}
    >
      <div className={styles.bet__header}>
        {bet.isInPlay && (
          <span className={classNames(styles.bet__live, branding.LIVE_LABEL)}>
            {t('asianView.labels.betslip.live')}
          </span>
        )}
        <AsianViewSingleEventOutrightLink
          isOutrightLink={bet.marketLink === AsianViewMarketLink.OUTRIGHT}
          sportId={bet.sportId || sportId}
          competitionId={bet.competitionId}
          marketId={bet.marketId}
          eventId={bet.eventId}
          competitionName={bet.competitionName}
          marketName={bet.marketName}
          eventName={bet.eventName}
          className={styles.bet__link}
        />
        {!isConfirmRemovingState && !isConfirmState && (
          <i onClick={handleRemoveSelectedBet} className={classNames('biab_tour-icon-close', styles.bet__closeIcon)} />
        )}
      </div>
      {bet.marketLink !== AsianViewMarketLink.OUTRIGHT && (
        <Link
          to={{
            pathname: `${ASIAN_BASE_URL}/sport/${bet.sportId || sportId}/timeFilter/${
              !timeFilter || !getIsValidTimeFilter(timeFilter) ? AsianViewTimeFilter.Today : timeFilter
            }/marketLink/${
              !marketLink || !getIsValidMarketLink(marketLink) ? AsianViewMarketLink.HDP_AND_OU : marketLink
            }`,
            search: `${ASIAN_SINGLE_VIEW_COMPETITION_ID}=${bet.competitionId}${sportIdParam}${pageParam}${betSlipCashOutPageParam}`
          }}
          className={styles.bet__link}
        >
          {bet.competitionName}
        </Link>
      )}
      <div className={classNames(styles.bet__border, branding.BET_SEPARATOR)} />
      <div className={styles.bet__marketName}>
        {bet.marketLink === AsianViewMarketLink.OUTRIGHT
          ? `${bet.selectionName} ${scoreLabel}`
          : `${bet.marketName} - ${bet.selectionName} ${scoreLabel}`}
      </div>
      <div className={styles.bet__info}>
        <div
          className={classNames({
            [styles.bet__odds]: placedWithBetterOdds || (hasAutoRefresh && areOddsHighlighted),
            [styles.bet__odds__up]: isOddsUp && (placedWithBetterOdds || areOddsHighlighted),
            [styles.bet__odds__down]: isOddsDown && (placedWithBetterOdds || areOddsHighlighted),
            [branding.PRICE_MORE]: isOddsUp && (placedWithBetterOdds || areOddsHighlighted),
            [branding.PRICE_LESS]: isOddsDown && (placedWithBetterOdds || areOddsHighlighted)
          })}
        >
          @<span className={styles.bet__odds__bold}>{placedWithBetterOdds ? convertedPlacedOdds : convertedOdds}</span>{' '}
          ({oddsTypeLabel})
          {(placedWithBetterOdds || (hasAutoRefresh && !isDisabledBet && areOddsHighlighted)) && (
            <i
              className={classNames(`fa2 fa2-sort-${isOddsUp ? 'up' : 'down'} biab_indicator`, {
                [asianViewIcons.PRICE_MORE_ICON]: isOddsUp,
                [asianViewIcons.PRICE_LESS_ICON]: !isOddsUp
              })}
            />
          )}
        </div>
        <span>{t(isBack ? 'asianView.labels.betslip.backBetFor' : 'asianView.labels.betslip.layBetAgainst')}</span>
      </div>
      <div className={styles.bet__form}>
        <Input type={isBack ? EInputFieldTypes.SIZE : EInputFieldTypes.PROFIT} {...inputProps} />
        <Input type={isBack ? EInputFieldTypes.PROFIT : EInputFieldTypes.SIZE} {...inputProps} />
      </div>
      {!isDisabledBet && !isConfirmState && !isConfirmRemovingState && !isPlacement && (
        <div className={classNames(styles.bet__info, styles.bet__minMaxValues)}>
          <BetMin marketId={bet.marketId} displayType={minMaxBetDisplayType} />
          <BetMax
            bet={bet}
            sizeInputRef={sizeInputRef}
            setIsHovered={setIsStakeHovered}
            setValidationMessage={setValidationMessage}
            updateBet={updateBet}
            displayType={minMaxBetDisplayType}
          />
        </div>
      )}
      <div className={classNames(styles.bet__info, styles.bet__payout, branding.BET_SEPARATOR)}>
        <span>{t('asianView.labels.betslip.payout')}:</span>
        <span>{payoutFormatted}</span>
      </div>
      {bet.validationMessage && !isPending && !isDisabledBet && (
        <Message
          identifier={bet.identifier}
          message={bet.validationMessage}
          type="error"
          customClassName={branding.ERROR_MSG}
        />
      )}
      {isMatched && (
        <Message
          type={isOddsUp || placedWithBetterOdds ? 'successUp' : 'success'}
          message={t(
            isOddsUp || placedWithBetterOdds
              ? 'asianView.labels.betslip.successPlacedBetWithBetterOdds'
              : 'asianView.labels.betslip.successPlacedBet',
            { odds: placedWithBetterOdds ? convertedPlacedOdds : convertedOdds, oddsType: oddsTypeLabel }
          )}
          customClassName={isOddsUp || placedWithBetterOdds ? branding.PLACED_BETTER_MSG : branding.PLACED_MSG}
        />
      )}
      {hasAutoRefresh && !isSuspended && !isDisabledBet && !isPending && !isMatched && (
        <Info
          message={<AVChangedOddsNotification isGoingUp={isGoingUp} odds={odds} initPrice={bet.initPrice} />}
          customClassName={branding.ODDS_CHANGED_MSG}
        />
      )}
      {isDisabledBet && !isPending && (
        <Info
          message={t('asianView.labels.selectedBet.unavailable')}
          isLockIcon
          customClassName={branding.LOCKED_BET_MSG}
        />
      )}
      {!isDisabledBet && isLoading && (
        <Loader
          circleColor={CircleColors.BLACK}
          className={`${styles.bet__loader} ${asianViewIcons.BETSLIP_SPINNER}`}
        />
      )}
    </div>
  );
};

export default memo(AVSelectedBet);
