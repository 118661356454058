import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useIntersectionObserver } from 'usehooks-ts';

import AsianViewFavouriteStar from 'components/AsianViewFavouriteStar';
import AsianViewInPlayCell from 'components/AsianViewInPlayCell';
import AsianViewMatchStatistics from 'components/AsianViewMatchStatistics';
import CashOut from 'components/CashOut';
import ProfitLossTablePopUpButton from 'components/ProfitLossTablePopUp/components/ProfitLossTablePopUpButton/ProfitLossTablePopUpButton';
import VideoStream from 'components/VideoStream';
import { FAVORITES_TYPES } from 'constants/app';
import { AsianViewSection } from 'constants/asianView';
import { asianViewMiddleSection as branding } from 'constants/branding';
import { MARKET_TYPES } from 'constants/marketTypes';
import useAsianLayBetting from 'hooks/useAsianLayBetting';
import useCustomResizeObserver from 'hooks/useCustomResizeObserver';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { getAsianViewEventIndex } from 'redux/modules/asianView/selectors';
import { TAsianViewEvent, TAsianViewMarket } from 'redux/modules/asianView/type';
import { getAsianViewCashOutCounterByEventId } from 'redux/modules/asianViewCashOutCounter/selectors';
import { getIsMarketInPlayByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { SportId } from 'types';

import AsianViewBackLayLabels from '../Event/MarketCell/AsianViewBackLayLabels';

import MatchOddsHTMarketRunners from './components/MatchOddsHTMarketRunners';

import styles from './styles.module.scss';

interface MatchOddsHTEventProps {
  event: TAsianViewEvent;
  competitionId: string;
  isFirst: boolean;
  index: number;
}

const getMarkets = (event: TAsianViewEvent) => {
  let ftMarket: TAsianViewMarket | undefined, htMarket: TAsianViewMarket | undefined;

  event.sections[AsianViewSection.TOP]?.forEach(currentMarket => {
    if (currentMarket.marketType === MARKET_TYPES.halfTime) {
      htMarket = currentMarket;
    } else if (currentMarket.marketType === MARKET_TYPES.matchOdds) {
      ftMarket = currentMarket;
    }
  });

  return { fullTimeMarket: ftMarket, halfTimeMarket: htMarket };
};

const MatchOddsHTEvent = ({ event, competitionId, isFirst }: MatchOddsHTEventProps) => {
  const { sportId = SportId.SOCCER } = useParams();

  const { fullTimeMarket, halfTimeMarket } = getMarkets(event);
  const activeMarket = fullTimeMarket || halfTimeMarket;

  const translation = useSelector(getTranslation);
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(activeMarket?.id ?? ''));
  const cashOutCounter = useSelector(getAsianViewCashOutCounterByEventId(sportId, event.id));
  const eventIndex = useSelector(getAsianViewEventIndex(event.id));
  const home = event.homeTeamTranslations ? event.homeTeamTranslations[translation] : '';
  const away = event.awayTeamTranslations ? event.awayTeamTranslations[translation] : '';
  const runners = [home, away];

  const containerRef = useRef<HTMLDivElement>(null);

  const isLayBetting = useAsianLayBetting();
  const { ref, isIntersecting } = useIntersectionObserver({ rootMargin: '400px' });
  const { height } = useCustomResizeObserver({ ref: containerRef });

  const showCashOut = useMemo(() => {
    return event.sections.top?.some(({ cashOutEnabled }) => cashOutEnabled);
  }, [event.sections.top]);
  const hasMarket = !!fullTimeMarket || !!halfTimeMarket;

  useEffect(() => {
    ref(containerRef.current);
  }, []);

  return (
    <div
      ref={containerRef}
      className={classNames({
        'biab_asian-view-event': hasMarket,
        [styles.matchOddsHTEvent]: hasMarket,
        [styles.matchOddsHTEvent__border]: hasMarket && isIntersecting,
        [branding.MAIN_CONTAINER_BORDER]: hasMarket && isIntersecting,
        [branding.IN_PLAY_EVENT_1]: isInPlay && eventIndex % 2 === 0 && hasMarket,
        [branding.IN_PLAY_EVENT_2]: isInPlay && eventIndex % 2 !== 0 && hasMarket,
        [branding.NON_PLAY_EVENT_1]: !isInPlay && eventIndex % 2 === 0 && hasMarket,
        [branding.NON_PLAY_EVENT_2]: !isInPlay && eventIndex % 2 !== 0 && hasMarket
      })}
    >
      {!isIntersecting && <div style={{ height }} />}
      {activeMarket && isIntersecting && (
        <>
          <AsianViewInPlayCell marketId={activeMarket.id} marketStartTime={activeMarket.startTime} />
          <div
            className={classNames(
              'biab_asian-view-event-selections-wrapper',
              styles.matchOddsHTEvent__participants,
              branding.MAIN_CONTAINER_BORDER
            )}
          >
            <div>
              {runners.map((runner, index) => (
                <p key={index} className={styles.matchOddsHTEvent__participants__name}>
                  <span data-tooltip-id={'tooltip'} data-tooltip-html={runner}>
                    {runner}
                  </span>
                </p>
              ))}
            </div>
            <div className={styles.matchOddsHTEvent__rightSection}>
              <ProfitLossTablePopUpButton eventId={event.id} marketId={fullTimeMarket?.id || halfTimeMarket?.id} />
              <div className={classNames('biab_asian-view-event-actions', styles.matchOddsHTEvent__actions)}>
                <AsianViewFavouriteStar
                  favourite={{
                    entryName: event.translations[translation],
                    entryId: event.id,
                    entryType: FAVORITES_TYPES.event,
                    sportId
                  }}
                />
                {showCashOut && (
                  <CashOut
                    showLabel={false}
                    showInfoIcon={false}
                    className={{
                      container: styles.matchOddsHTEvent__cashout,
                      icon: styles.matchOddsHTEvent__cashout__icon
                    }}
                    disabled={!cashOutCounter}
                    isAvPLTableAvailable
                  />
                )}
              </div>
            </div>
          </div>
          <div className={classNames(styles.matchOddsHTEvent__cols)}>
            {[fullTimeMarket, halfTimeMarket].map(market => {
              if (!market) {
                return new Array(3).fill(0).map((_, runnerIndex) => {
                  return (
                    <div
                      className={classNames(styles.matchOddsHTEvent__prices, branding.MAIN_CONTAINER_BORDER, {
                        [styles.matchOddsHTEvent__prices__first]: isFirst,
                        [styles.matchOddsHTEvent__prices__first__empty]: isFirst
                      })}
                      key={runnerIndex}
                    >
                      {isFirst && isLayBetting && (
                        <>
                          <AsianViewBackLayLabels />
                          <div className={styles.matchOddsHTEvent__prices__empty} />
                        </>
                      )}
                    </div>
                  );
                });
              }

              return (
                <MatchOddsHTMarketRunners
                  key={market.id}
                  market={market}
                  competitionId={competitionId}
                  eventId={event.id}
                  isFirst={isFirst}
                />
              );
            })}
          </div>
          <div className={classNames(styles.matchOddsHTEvent__statsAndStream, branding.MAIN_CONTAINER_BORDER)}>
            {event.videoStreamingEnabled && (
              <VideoStream hideLabel visible={event.videoStreamingEnabled} isInHeader eventId={event.id} />
            )}
            {event.matchStatEnabled && (
              <AsianViewMatchStatistics matchStatEnabled={event.matchStatEnabled} eventId={event.id} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MatchOddsHTEvent;
