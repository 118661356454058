import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ResponsibleBettingSkeleton from 'components/ResponsibleBetting/components/ResponsibleBettingSkeleton';
import { componentsBranding } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import { ResponsibleBettingTab } from 'types/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingSectionProps {
  tab: ResponsibleBettingTab;
  isOpened: boolean;
  onClick: () => void;
  children?: ReactNode;
}

export const ResponsibleBettingSection = ({ tab, isOpened, onClick, children }: ResponsibleBettingSectionProps) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  const { labelTranslationKey, btnLabelTranslationKey, descriptionTranslationKey } = tab;

  return (
    <div className={styles.container}>
      {labelTranslationKey && descriptionTranslationKey && btnLabelTranslationKey ? (
        <div
          className={classNames(styles.content, {
            [styles.content__mobile]: isMobile
          })}
        >
          <h4 className={styles.title}>{t(labelTranslationKey)}</h4>
          <p className={styles.description}>{t(descriptionTranslationKey)}</p>
          {isOpened ? (
            children
          ) : (
            <button onClick={onClick} className={classNames(styles.button, componentsBranding.PRIMARY_BTN)}>
              {t(btnLabelTranslationKey)}
            </button>
          )}
        </div>
      ) : (
        <ResponsibleBettingSkeleton />
      )}
    </div>
  );
};
