import { TFunction } from 'react-i18next';

import { ONE_DAY_IN_MS, ONE_HOUR_IN_MS, ONE_MINUTE_IN_MS } from 'constants/date';
import {
  RESPONSIBLE_BETTING_DEFAULT_SCALE_GAP,
  RESPONSIBLE_BETTING_DEFAULT_SCALE_WIDTH,
  RESPONSIBLE_BETTING_TIME_UNIT_DAYS_AMOUNT,
  RESPONSIBLE_BETTING_TIME_UNITS,
  SELF_EXCLUSION_TIME_UNITS_DURATION,
  updatedResponsibleBettingTimeUnits
} from 'constants/responsibleBetting';
import { ResponsibleBettingDisplaySettings } from 'redux/modules/appConfigs/type';
import { PagesFromBackend } from 'redux/modules/pages/types';
import {
  CombinedResponsibleBettingTimeUnit,
  ResponsibleBettingReportsAvailability,
  ResponsibleBettingSettings
} from 'redux/modules/responsibleBetting/type';
import { DropdownItem } from 'types';
import {
  ResponsibleBettingDisplayedContent,
  ResponsibleBettingEndDate,
  ResponsibleBettingLimit,
  ResponsibleBettingOperatorLimit,
  ResponsibleBettingReportTypes,
  ResponsibleBettingSelfExclusionTimeUnitKeys,
  ResponsibleBettingStartDate,
  ResponsibleBettingTimeUnitKeys,
  ResponsibleBettingTimeUnits,
  UpdatedResponsibleBettingDisplayedContent
} from 'types/responsibleBetting';

import { applyTimezone, getDaysDifference, getTodayDate } from './date';

export const getFirstEnabledResponsibleBettingTab = (
  {
    exposureLimitEnabled,
    dayLimitEnabled,
    reminderEnabled,
    lossLimitEnabled,
    selfExclusionEnabled
  }: ResponsibleBettingDisplaySettings,
  multiCurrencySupported: boolean
) => {
  if (exposureLimitEnabled && !multiCurrencySupported) {
    return ResponsibleBettingDisplayedContent.ExposureLimit;
  } else if (lossLimitEnabled && !multiCurrencySupported) {
    return ResponsibleBettingDisplayedContent.LossLimit;
  } else if (selfExclusionEnabled) {
    return ResponsibleBettingDisplayedContent.SelfExclusion;
  } else if (reminderEnabled) {
    return ResponsibleBettingDisplayedContent.Reminder;
  } else if (dayLimitEnabled) {
    return ResponsibleBettingDisplayedContent.DayLimit;
  }

  return undefined;
};

export const getResponsibleBettingTabs = (
  {
    exposureLimitEnabled,
    lossLimitEnabled,
    selfExclusionEnabled,
    dayLimitEnabled,
    reminderEnabled
  }: ResponsibleBettingDisplaySettings,
  multiCurrencySupported: boolean
) => {
  return [
    {
      labelTranslationKey: 'responsibleBetting.titles.exposureLimit',
      name: ResponsibleBettingDisplayedContent.ExposureLimit,
      isVisible: exposureLimitEnabled && !multiCurrencySupported
    },
    {
      labelTranslationKey: 'responsibleBetting.titles.lossLimit',
      name: ResponsibleBettingDisplayedContent.LossLimit,
      isVisible: lossLimitEnabled && !multiCurrencySupported
    },
    {
      labelTranslationKey: 'responsibleBetting.titles.selfExclusion',
      name: ResponsibleBettingDisplayedContent.SelfExclusion,
      isVisible: selfExclusionEnabled
    },
    {
      labelTranslationKey: 'responsibleBetting.titles.reminder',
      name: ResponsibleBettingDisplayedContent.Reminder,
      isVisible: reminderEnabled
    },
    {
      labelTranslationKey: 'responsibleBetting.titles.dayLimit',
      name: ResponsibleBettingDisplayedContent.DayLimit,
      isVisible: dayLimitEnabled
    }
  ];
};

export const getUpdatedResponsibleBettingTabs = (
  { lossLimitEnabled, selfExclusionEnabled, timeLimitEnabled, reminderEnabled }: ResponsibleBettingDisplaySettings,
  multiCurrencySupported: boolean
) => {
  const updatedResponsibleBettingTabs = [
    {
      labelTranslationKey: 'responsibleBetting.titles.lossLimit',
      btnLabelTranslationKey: 'responsibleBetting.buttons.setUpLossLimit',
      name: UpdatedResponsibleBettingDisplayedContent.LossLimit,
      isVisible: lossLimitEnabled && !multiCurrencySupported,
      descriptionTranslationKey: 'responsibleBetting.message.LOSS_LIMIT_DEFAULT'
    },
    {
      labelTranslationKey: 'responsibleBetting.titles.selfExclusion',
      btnLabelTranslationKey: 'responsibleBetting.buttons.startSelfExclusion',
      name: UpdatedResponsibleBettingDisplayedContent.SelfExclusion,
      isVisible: selfExclusionEnabled,
      descriptionTranslationKey: 'responsibleBetting.message.SELF_EXCLUSION_DEFAULT'
    },
    {
      labelTranslationKey: 'responsibleBetting.titles.timeLimit',
      btnLabelTranslationKey: 'responsibleBetting.buttons.setUpTimeLimit',
      name: UpdatedResponsibleBettingDisplayedContent.TimeLimit,
      isVisible: timeLimitEnabled,
      descriptionTranslationKey: 'responsibleBetting.message.TIME_LIMIT_DEFAULT'
    },
    {
      labelTranslationKey: 'responsibleBetting.titles.reminder',
      btnLabelTranslationKey: 'responsibleBetting.buttons.setUpReminder',
      name: UpdatedResponsibleBettingDisplayedContent.Reminder,
      isVisible: reminderEnabled,
      descriptionTranslationKey: 'responsibleBetting.message.REMINDER_DEFAULT'
    }
  ];

  return updatedResponsibleBettingTabs;
};

export const getInitDate = ({
  rbSettings,
  dateKey,
  operatorLimitKey
}: {
  rbSettings: ResponsibleBettingSettings | null;
  dateKey: ResponsibleBettingStartDate | ResponsibleBettingEndDate;
  operatorLimitKey: ResponsibleBettingOperatorLimit;
}) => {
  const date = rbSettings?.rgTools?.[dateKey];
  const operatorLimit = rbSettings?.[operatorLimitKey];

  if (operatorLimit) {
    return null;
  }

  if (typeof date === 'number') {
    const currentDate = new Date(date);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    return currentDate;
  }

  return null;
};

export const getInitLimit = (
  rbSettings: ResponsibleBettingSettings | null,
  limitKey: ResponsibleBettingLimit,
  operatorLimitKey?: ResponsibleBettingOperatorLimit
) => {
  const operatorLimit = operatorLimitKey && rbSettings?.[operatorLimitKey];
  const limit = rbSettings?.rgTools?.[limitKey];

  if (operatorLimit) {
    return String(operatorLimit);
  }

  return typeof limit === 'number' ? String(limit) : '';
};

export const parseSessionTime = (sessionStartTime: number, t: TFunction) => {
  const sessionTime = new Date().getTime() - sessionStartTime;

  const sessionMinutes = Math.floor(((sessionTime % ONE_DAY_IN_MS) % ONE_HOUR_IN_MS) / ONE_MINUTE_IN_MS);
  const sessionHours = Math.floor((sessionTime % ONE_DAY_IN_MS) / ONE_HOUR_IN_MS);
  let parsedMinutes = '';
  let parsedHours = '';

  if (sessionMinutes) {
    if (sessionMinutes === 1) {
      parsedMinutes = ` ${sessionMinutes} ${t('responsibleBetting.labels.sessionReminder.minute.1')}`;
    } else {
      parsedMinutes = ` ${sessionMinutes} ${t('responsibleBetting.labels.sessionReminder.minute.plural')}`;
    }
  }

  if (sessionHours) {
    if (sessionHours === 1) {
      parsedHours = ` ${sessionHours} ${t('responsibleBetting.labels.sessionReminder.hour.1')}`;
    } else {
      parsedHours = ` ${sessionHours} ${t('responsibleBetting.labels.sessionReminder.hour.plural')}`;
    }
  }

  return `${parsedHours}${parsedMinutes}`;
};

export const setCalendarSelectSize = (element: Element, size: string) => {
  element.addEventListener('focus', () => {
    element.setAttribute('size', size);
  });

  element.addEventListener('blur', () => {
    element.setAttribute('size', '0');
  });

  element.addEventListener('change', function (this: HTMLSelectElement) {
    this.blur();
  });
};

export const calculateLimitIndexes = (
  currentLimit: number | null,
  initialLimit: number | null,
  newLimit: number | null,
  currentLimitEndDate: Date | null,
  initialPeriod: CombinedResponsibleBettingTimeUnit,
  newPeriod: CombinedResponsibleBettingTimeUnit
): {
  newLossLimitIndex: number;
  initialLossLimitIndex: number;
  currentLossLimitIndex: number;
} => {
  const indexes = {
    newLossLimitIndex: 0,
    initialLossLimitIndex: 0,
    currentLossLimitIndex: 0
  };

  if (currentLimitEndDate && initialPeriod && newPeriod && currentLimit && initialLimit && newLimit) {
    const currentLimitAvailableDays = getDaysDifference(getTodayDate(), currentLimitEndDate);

    const newLimitAvailableDays = getTimeUnitDaysAmount(newPeriod);
    const initialLimitAvailableDays = getTimeUnitDaysAmount(initialPeriod);

    indexes.newLossLimitIndex = newLimit / newLimitAvailableDays;
    indexes.initialLossLimitIndex = initialLimit / initialLimitAvailableDays;
    indexes.currentLossLimitIndex = currentLimit / currentLimitAvailableDays;
  }

  return indexes;
};

const addMonthsAndAdjust = (date: Date, months: number): Date => {
  const originalDate = date.getDate();
  date.setMonth(date.getMonth() + months);

  if (date.getDate() < originalDate) {
    date.setMonth(date.getMonth() + 1, 0);
  }

  return date;
};

export const getTimeUnitKey = (timeUnit: CombinedResponsibleBettingTimeUnit) => {
  let timeUnitKey = '';
  if (timeUnit) {
    timeUnitKey = RESPONSIBLE_BETTING_TIME_UNITS[timeUnit as ResponsibleBettingTimeUnitKeys];
  }
  return timeUnitKey;
};

export const getTimeUnitDaysAmount = (timeUnit: CombinedResponsibleBettingTimeUnit) => {
  let daysAmount = 0;
  if (timeUnit) {
    daysAmount = RESPONSIBLE_BETTING_TIME_UNIT_DAYS_AMOUNT[timeUnit as ResponsibleBettingTimeUnitKeys];
  }
  return daysAmount;
};

export const calculateNewPeriodStartDate = (
  startDateOfCurrentPeriod: number | null,
  timeUnit: CombinedResponsibleBettingTimeUnit,
  cooldownDays: number | undefined,
  timezone: string,
  timezoneCookieEnabled: boolean
): Date | undefined => {
  if (startDateOfCurrentPeriod && timeUnit && cooldownDays !== undefined) {
    let periodEndDate = new Date(startDateOfCurrentPeriod);

    if (timeUnit === ResponsibleBettingTimeUnits.MONTH) {
      periodEndDate = addMonthsAndAdjust(periodEndDate, 1);
    } else {
      const periodLength = getTimeUnitDaysAmount(timeUnit);
      periodEndDate.setDate(periodEndDate.getDate() + periodLength);
    }

    const cooldownEndDate = new Date();
    cooldownEndDate.setDate(cooldownEndDate.getDate() + cooldownDays);

    while (periodEndDate <= cooldownEndDate) {
      if (timeUnit === ResponsibleBettingTimeUnits.MONTH) {
        periodEndDate = addMonthsAndAdjust(periodEndDate, 1);
      } else {
        const periodLength = getTimeUnitDaysAmount(timeUnit);
        periodEndDate.setDate(periodEndDate.getDate() + periodLength);
      }
    }

    return applyTimezone(new Date(periodEndDate), timezone, timezoneCookieEnabled);
  }
};

export const calculateResponsibleBettingScaleWidth = (
  currentLimitAmount: number | null,
  spentLimitAmount: number | null
) => {
  const width = { spent: 0, available: RESPONSIBLE_BETTING_DEFAULT_SCALE_WIDTH };

  if (currentLimitAmount === null || spentLimitAmount === null || currentLimitAmount === 0) {
    return width;
  }

  const spentPercentage = (spentLimitAmount / currentLimitAmount) * RESPONSIBLE_BETTING_DEFAULT_SCALE_WIDTH;

  width.spent = Math.min(spentPercentage, RESPONSIBLE_BETTING_DEFAULT_SCALE_WIDTH);
  width.available = Math.max(
    RESPONSIBLE_BETTING_DEFAULT_SCALE_WIDTH - width.spent - RESPONSIBLE_BETTING_DEFAULT_SCALE_GAP,
    0
  );

  return width;
};

export const convertTimeUnitsToDropdown = (t: TFunction): DropdownItem[] => {
  return updatedResponsibleBettingTimeUnits.map((timeUnit, index) => ({
    id: index,
    value: timeUnit.value,
    label: t(timeUnit.label)
  }));
};

export const getSelfExclusionReportsExportOptions = (
  reportsAvailability: ResponsibleBettingReportsAvailability | null
) => {
  const reportsExportOptions = [
    {
      label: 'responsibleBetting.labels.myCurrentBets',
      period: 'responsibleBetting.labels.allCurrentBets',
      type: ResponsibleBettingReportTypes.CurrentBetsReport,
      isVisible: reportsAvailability?.myCurrentBetsReport
    },
    {
      label: 'responsibleBetting.labels.myPastBets',
      period: 'responsibleBetting.labels.last3Months',
      type: ResponsibleBettingReportTypes.PastBetsReport,
      isVisible: reportsAvailability?.myPastBetsReport
    },
    {
      label: 'responsibleBetting.labels.bettingProfitLoss',
      period: 'responsibleBetting.labels.last3Months',
      type: ResponsibleBettingReportTypes.ProfitAndLossReport,
      isVisible: reportsAvailability?.bettingProfitAndLossReport
    },
    {
      label: 'responsibleBetting.labels.accountStatement',
      period: 'responsibleBetting.labels.last3Months',
      type: ResponsibleBettingReportTypes.AccountStatementReport,
      isVisible: reportsAvailability?.accountStatementReport
    }
  ];

  return reportsExportOptions;
};

export const convertSelfExclusionPeriodsToDropdown = (periods: string[] | undefined, t: TFunction): DropdownItem[] => {
  if (!periods) return [];

  return [...periods]
    .sort((a, b) => {
      const durationA = SELF_EXCLUSION_TIME_UNITS_DURATION[a as ResponsibleBettingSelfExclusionTimeUnitKeys];
      const durationB = SELF_EXCLUSION_TIME_UNITS_DURATION[b as ResponsibleBettingSelfExclusionTimeUnitKeys];
      return durationA - durationB;
    })
    .map((period, index) => ({
      id: index,
      value: period,
      label: t(`responsibleBetting.labels.${period}`)
    }));
};

export const getSelfExclusionPageType = (
  selfExclusionEndDate: number,
  selfExclusionEnabled: boolean,
  selfExcludedThroughApi: boolean,
  selfExcludedThroughApiUntil: number
): string => {
  const today = new Date().getTime();

  if (
    selfExclusionEndDate === 0 &&
    !selfExclusionEnabled &&
    !selfExcludedThroughApi &&
    selfExcludedThroughApiUntil === 0
  ) {
    return '';
  }

  if (
    (selfExclusionEnabled && selfExclusionEndDate > today) ||
    (selfExcludedThroughApi && selfExcludedThroughApiUntil > today) ||
    (selfExcludedThroughApi && selfExcludedThroughApiUntil === 0 && selfExclusionEndDate > today)
  ) {
    return PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_START;
  }

  if (
    (selfExclusionEnabled && selfExclusionEndDate < today) ||
    (selfExcludedThroughApi && selfExcludedThroughApiUntil < today) ||
    (selfExcludedThroughApi && selfExcludedThroughApiUntil === 0 && selfExclusionEndDate < today)
  ) {
    return PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_END;
  }

  if (
    (selfExcludedThroughApi && selfExclusionEnabled && selfExclusionEndDate > today) ||
    (selfExcludedThroughApi && selfExclusionEnabled && selfExclusionEndDate < today)
  ) {
    return PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_START;
  }

  return '';
};

export const getSelfExclsuionDisplayDate = (
  selfExclusionEndDate: number,
  selfExcludedThroughApiUntil: number
): number => {
  const today = new Date().getTime();

  if (selfExcludedThroughApiUntil > today && selfExclusionEndDate > today) {
    return Math.max(selfExclusionEndDate, selfExcludedThroughApiUntil);
  }

  if (selfExcludedThroughApiUntil > today && selfExclusionEndDate < today) {
    return selfExcludedThroughApiUntil;
  }

  return selfExclusionEndDate || selfExcludedThroughApiUntil;
};

export const getIsLimitLessStrict = (
  currentLimit: number | null,
  currentTimeUnit: CombinedResponsibleBettingTimeUnit,
  newLimit: number | null,
  newTimeUnit: CombinedResponsibleBettingTimeUnit
): boolean => {
  if (currentLimit && currentTimeUnit && newLimit && newTimeUnit) {
    const currentIndex =
      currentLimit * (1 / RESPONSIBLE_BETTING_TIME_UNIT_DAYS_AMOUNT[currentTimeUnit as ResponsibleBettingTimeUnitKeys]);

    const newIndex =
      newLimit * (1 / RESPONSIBLE_BETTING_TIME_UNIT_DAYS_AMOUNT[newTimeUnit as ResponsibleBettingTimeUnitKeys]);

    return currentIndex <= newIndex;
  }
  return false;
};

export const parseMinutesToHoursAndMinutes = (minutesAmount: number, t: TFunction): string => {
  const hours = Math.floor(minutesAmount / 60);
  const minutes = minutesAmount % 60;

  if (hours > 0 && minutes > 0) {
    return `${t('responsibleBetting.labels.hoursMinutes', {
      hours,
      minutes
    })}`;
  } else if (hours > 0) {
    return `${t('responsibleBetting.labels.hoursHours', {
      hours
    })}`;
  } else {
    return `${t('responsibleBetting.labels.minutesMinutes', {
      minutes
    })}`;
  }
};
