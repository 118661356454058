import { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { AV_PL_TABLE_POP_UP_MARKETS_ONE_COLUMN_SIZE } from 'constants/asianView';
import useDevice from 'hooks/useDevice';
import { getPLFTMarkets, getPLHTMarkets } from 'redux/modules/profitLossTablePopUp/selectors';

import styles from './ProfitLossTablePopUpMarkets.module.scss';

const ProfitLossTablePopUpMarkets = () => {
  const { t } = useTranslation();

  const halfTimeMarkets = useSelector(getPLHTMarkets);
  const fullTimeMarkets = useSelector(getPLFTMarkets);

  const { isMobile, isLandscape } = useDevice();

  const [isOpened, setIsOpened] = useState(false);

  const markets = [
    { list: fullTimeMarkets, title: t('asianView.labels.column.fullTime') },
    { list: halfTimeMarkets, title: t('asianView.labels.column.firstHalf') }
  ];

  return (
    <>
      <button className={styles.toggleBtn} onClick={() => setIsOpened(prevState => !prevState)}>
        <i className={classNames('biab_custom-icon-info-circle', styles.toggleBtn__icon)} />
        <span className={styles.toggleBtn__text}>{t('asianView.labels.plTable.marketsTitle')}</span>
        <i
          className={classNames('av-icon', styles.toggleBtn__icon, {
            'av-icon-arrow-down': !isOpened,
            'av-icon-arrow-up': isOpened
          })}
        />
      </button>
      {isOpened &&
        markets.map(({ list, title }) => {
          if (!list.length) {
            return null;
          }

          return (
            <Fragment key={title}>
              <p className={styles.marketsTitle}>{title}</p>
              <ul
                className={classNames(styles.marketsList, {
                  [styles.marketsList__2columns]: list.length > AV_PL_TABLE_POP_UP_MARKETS_ONE_COLUMN_SIZE,
                  [styles.marketsList__mobile]: isMobile && !isLandscape
                })}
              >
                {list.map(market => {
                  return (
                    <li key={market} className={styles.market}>
                      {market}
                    </li>
                  );
                })}
              </ul>
            </Fragment>
          );
        })}
    </>
  );
};

export default memo(ProfitLossTablePopUpMarkets);
