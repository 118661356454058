import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { isString } from 'lodash';

import QuickBets from 'components/Betslip/components/QuickBets';
import RGModalMessage from 'components/Betslip/components/RGModalMessage';
import SelectedBetOddsNotificationMessage from 'components/Betslip/components/SelectedBetOddsNotificationContent/SelectedBetOddsNotificationContent';
import NewVirtualKeyboard from 'components/NewVirtualKeyborad/NewVirtualKeyboard';
import { BETSLIP_LINE_INT_ODDS } from 'constants/betslip';
import { mobileBetslip as branding, mobileComponents, mobileIcons } from 'constants/branding';
import { MobilePlacementTypes } from 'constants/inlinePlacement';
import {
  LOADING_TIMEOUT_MESSAGE,
  VALIDATION_ERROR_BET_OUTDATED_LINE,
  VALIDATION_ERROR_BET_OUTDATED_ODDS,
  VALIDATION_ERROR_DIFFERENT_CURRENCY_MARKET
} from 'constants/placement';
import {
  BETSLIP_BACKERS_ODDS,
  BETSLIP_BACKERS_STAKE,
  BETSLIP_BACKERS_UNITS,
  BETSLIP_LIABILITY,
  BETSLIP_ODDS,
  BETSLIP_STAKE,
  BETSLIP_UNITS
} from 'constants/tooltip';
import useConfirmBets from 'hooks/useConfirmBets';
import { useCurrency } from 'hooks/useCurrency';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { useBetslipLabels } from 'hooks/useLabels';
import { useMarketUnits } from 'hooks/useMarketUnits';
import { useIsMobilePlacementDisabled } from 'hooks/useMobilePlacement';
import useMobilePlacementNotificationMatchedBySystem from 'hooks/useMobilePlacementNotificationMatchedBySystem';
import { usePlacementData } from 'hooks/usePlacement';
import usePostMessage from 'hooks/usePostMessage';
import useWindowSize from 'hooks/useWindowSize';
import {
  getBalanceWsEnabled,
  getBetslipSpinnerTime,
  getCurrency,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled,
  getIsSportLayOddsDisabled,
  getMobileSettingsVirtualKeyboardBetslip,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setRGErrorMessage } from 'redux/modules/betslip';
import { EBetFocusFields } from 'redux/modules/betslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { setCurrentBetAction } from 'redux/modules/currentBets';
import { getCurrentBetByOfferId, getCurrentBetsByOldOfferId } from 'redux/modules/currentBets/selectors';
import { ECurrentBetActions, TCurrentBet } from 'redux/modules/currentBets/type';
import { getCurrentGameIsLayOddsDisabled, getCurrentGameName, getIsCurrentGame } from 'redux/modules/games/selectors';
import {
  failureInlinePlacedBet,
  removeInlineSelectedBet,
  removeProcessingMobileBet,
  setInlinePlacedSize,
  setIsMobilePlacementConfirmationStep,
  setMobileInlineSelectedBetToRestore,
  setMobilePlacementNotification,
  setProcessingMobileBet,
  successInlinePlacedBet
} from 'redux/modules/inlinePlacement';
import {
  getInlineSelectedBetByMarket,
  getIsMobilePlacementConfirmationStep
} from 'redux/modules/inlinePlacement/selectors';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { getStatusByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { getMarketDataById, getMarketPricesById } from 'redux/modules/placement/selectors';
import { TPlacementError } from 'redux/modules/placement/type';
import { fetchBalance } from 'redux/modules/user';
import {
  getAccountSettings,
  getUserAsianViewAutoRefresh,
  getUserCurrency,
  getUserLoading
} from 'redux/modules/user/selectors';
import { MarketStatus, PageBlocks, PlacementPage } from 'types';
import { BetTypes, EPersistenceTypes, TPrice, TSize } from 'types/bets';
import { EBetslipTypes, TLabels } from 'types/betslip';
import { Actions, Statuses } from 'types/inlinePlacement';
import { BettingType } from 'types/markets';
import {
  formatBestPrice,
  getBestPriceFromMarketPrices,
  getBestPrices,
  isResponsibleGamblingError
} from 'utils/betslip';
import { calculatePayout, calculateSize } from 'utils/liability';
import { getLiabilityValue } from 'utils/mobileBetslip';
import { getPriceMetadata } from 'utils/price';
import { precisionFormat, validateSize } from 'utils/size';

import LiabilityInput from './Liability/LiabilityInput';
import MobilePlacementClosedMessage from './MobilePlacementClosedMessage/MobilePlacementClosedMessage';
import MobilePlacementValidationMessage from './MobilePlacementValidationMessage/MobilePlacementValidationMessage';
import NoEditableInput from './NoEditableInput/NoEditableInput';
import PersistenceRadioGroup from './PersistenceRadioGroup/PersistenceRadioGroup';
import Liability from './Liability';
import PriceInput from './PriceInput';
import SizeInput from './SizeInput';

import styles from './styles.module.scss';

interface MobilePlacementProps {
  /**
   * Bet data that consists of the market and selection information, price and size, offerId (if bet is placed)
   */
  bet: TInlineSelectedBet;

  /**
   * Place where component was added.
   */
  pageBlock?: PageBlocks;
  /**
   * On closing the betslip
   */
  onClosingBetslip?: (isVisible: boolean) => void;
  page?: PlacementPage;
  containerClassName?: string;
}

const initialInvalidInputs = {
  [EBetFocusFields.PRICE]: false,
  [EBetFocusFields.SIZE]: false
};

function MobilePlacement({
  bet,
  pageBlock = PageBlocks.HOME,
  onClosingBetslip = () => {},
  page,
  containerClassName = ''
}: MobilePlacementProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isGame = pageBlock === PageBlocks.GAME;

  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);
  const virtualKeyboardBetslip = useSelector(getMobileSettingsVirtualKeyboardBetslip);
  const betslipSpinnerTime = useSelector(getBetslipSpinnerTime);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const loadingUser = useSelector(getUserLoading);
  const userCurrency = useSelector(getUserCurrency);
  const market = useSelector(getMarketDataById(pageBlock, bet.marketId, bet.sportId));
  const inlineSelectedBet = useSelector(getInlineSelectedBetByMarket(pageBlock, bet.marketId));
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const currentBet = useSelector(getCurrentBetByOfferId(bet?.currentOfferId || 0));
  const currentOldBets = useSelector(getCurrentBetsByOldOfferId(bet?.currentOfferId || 0));
  const isCurrentGameData = useSelector(getIsCurrentGame);
  const currentGameName = useSelector(getCurrentGameName);
  const accountSettings = useSelector(getAccountSettings);
  const marketPrices = useSelector(getMarketPricesById(pageBlock, bet.marketId));
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);
  const defaultCurrency = useSelector(getCurrency);
  const autoRefreshIsEnabled = useSelector(getUserAsianViewAutoRefresh);
  const currency = useCurrency(defaultCurrency.currencyCode);
  const marketStatus = useSelector(getStatusByMarketPricesId(bet.marketId));
  const isConfirmationStep = useSelector(getIsMobilePlacementConfirmationStep);
  const isSportsDisabledLayOdds = useSelector(
    getIsSportLayOddsDisabled(bet.type === BetTypes.LAY ? bet.sportId : undefined)
  );
  const isGamesLayOddsDisabled = useSelector(getCurrentGameIsLayOddsDisabled(isGame && bet.type === BetTypes.LAY));

  const selectedBet =
    inlineSelectedBet && inlineSelectedBet.offers && inlineSelectedBet?.currentOfferId
      ? inlineSelectedBet.offers[inlineSelectedBet?.currentOfferId]
      : null;

  const [invalidInputs, setInvalidInputs] =
    useState<Record<Exclude<EBetFocusFields, EBetFocusFields.LIABILITY>, boolean>>(initialInvalidInputs);
  const [currentFocus, setCurrentFocus] = useState<EBetFocusFields | null>(EBetFocusFields.SIZE);
  const [priceValue, setPriceValue] = useState<TPrice>(bet.price);
  const [prevPriceValue, setPrevPriceValue] = useState<TPrice>(bet.price);
  const [sizeValue, setSizeValue] = useState<TSize>(bet.size);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasErrorMessage, setHasErrorMessage] = useState(false);
  const [status, setStatus] = useState<Statuses>(Statuses.NEW);
  const [placedBet, setPlacedBet] = useState<TCurrentBet | null>(selectedBet || null);
  const [isTakeOffer, setIsTakeOffer] = useState(false);
  const [persistenceType, setPersistenceType] = useState<EPersistenceTypes>(EPersistenceTypes.LAPSE);
  const [liabilityValue, setLiabilityValue] = useState<number | string | undefined>(
    bet.type === BetTypes.LAY
      ? getLiabilityValue({
          marketId: bet.marketId,
          type: bet.type,
          handicap: bet.handicap,
          selectionId: bet.selectionId,
          priceValue,
          sizeValue,
          marketDefinition: marketPrices?.marketDefinition
        })
      : undefined
  );

  const marketType = market?.description.marketType;
  const bettingType = market?.description.bettingType;
  const isDisabledLayOdds = isGame ? isGamesLayOddsDisabled : isSportsDisabledLayOdds;
  const marketPrice = getBestPrices({ marketPrices, ...bet });
  const bestPrice = getBestPriceFromMarketPrices(marketPrice, marketType ?? '', bettingType ?? '');
  const marketCurrency = marketPrices?.currency;
  const isCurrencyChanged =
    isLoggedIn && !loadingUser && !!marketCurrency && !!userCurrency && userCurrency !== marketCurrency;
  const currentPrice = !isConfirmationStep && autoRefreshIsEnabled && isPNCEnabled && !isGame ? bestPrice : priceValue;
  const isBack = bet.type === BetTypes.BACK;
  const { isDisabled } = useIsMobilePlacementDisabled(bet);
  const isPrevOddsLower = !isDisabled && !!(bet.price && +bet.price < (bestPrice || 0));
  const isGoingUp = !isDisabled && isBack ? isPrevOddsLower : !isPrevOddsLower;
  const isOddsChangedIndicatorsEnabled =
    isPNCEnabled &&
    autoRefreshIsEnabled &&
    !isGame &&
    !!bet.price &&
    !!bestPrice &&
    bet.price !== bestPrice &&
    !isDisabled;
  const isLowerPrice = isOddsChangedIndicatorsEnabled && !isGoingUp;
  const showOddsChangedIndicators = isOddsChangedIndicatorsEnabled && !isConfirmationStep;
  const isEditActionParam = searchParams.get('action') === 'edit';
  const offerIdFromParams = Number(searchParams.get('offerId'));

  const { isConfirmBetsBeforePlacement } = useConfirmBets();
  const { placeBetLogin } = usePostMessage();
  const isClosed = marketStatus === MarketStatus.CLOSED;

  useMobilePlacementNotificationMatchedBySystem({
    bet: currentBet,
    pageBlock,
    onMatched: () => dispatch(removeInlineSelectedBet(bet))
  });
  const labels: TLabels = useBetslipLabels({
    eventTypeId: market?.eventType?.id ?? '',
    bettingType: market?.description?.bettingType ?? '',
    marketType: market?.description?.marketType ?? '',
    marketUnit: market?.description?.lineRangeInfo?.marketUnit ?? null,
    isMobilePlacement: true
  });
  const { isLandscape } = useWindowSize();

  const placementTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const prevSizeValue = useRef<TSize>(bet.size);

  const isLayBet = bet.type === BetTypes.LAY;
  const stringifiedMarketDefinition = JSON.stringify(marketPrices?.marketDefinition);
  const lineRangeInfo = market?.description?.lineRangeInfo;
  const marketUnit = lineRangeInfo?.marketUnit || 'runs';
  const marketName = isGame && isCurrentGameData ? currentGameName : market?.marketName ?? '';
  const selectionName =
    bet.selectionName ||
    (market?.runners?.find(runner => runner.selectionId === bet.selectionId && runner.handicap === bet.handicap)
      ?.runnerName ??
      '');
  const isLineMarket = bettingType === BettingType.LINE;
  const priceLadderDescription = market?.description.priceLadderDescription;
  const isBetslipVisible = !((status === Statuses.PLACED || bet.action !== Actions.EDIT) && placedBet);

  const liability = useMemo(() => {
    if (isLayBet) {
      return undefined;
    }

    if (isLineMarket) {
      return sizeValue; // For Back Line market liability equals stake
    }

    return getLiabilityValue({
      marketId: bet.marketId,
      type: bet.type,
      handicap: bet.handicap,
      selectionId: bet.selectionId,
      priceValue,
      sizeValue,
      marketDefinition: marketPrices?.marketDefinition
    });
  }, [
    bet.marketId,
    bet.selectionId,
    bet.handicap,
    bet.type,
    priceValue,
    sizeValue,
    marketPrices?.marketDefinition,
    isLayBet,
    isLineMarket
  ]);

  const payout = calculatePayout(currentPrice, sizeValue, {
    marketType,
    bettingType,
    eachWayDivisor: bet.eachWayDivisor,
    betType: bet.type
  });

  const getIsPlacementBtnEnabled = () => {
    const { max: maxPrice, min: minPrice } = getPriceMetadata({
      price: priceValue,
      marketType: marketType || '',
      bettingType: bettingType || '',
      lineRangeInfo,
      priceLadderDescription
    });

    let isEnabled = false;

    if (
      !isDisabled &&
      priceValue &&
      Number(priceValue) >= minPrice &&
      Number(priceValue) <= maxPrice &&
      sizeValue &&
      !isNaN(+sizeValue) &&
      Number(sizeValue) <= currency.maxBetSize &&
      Number(sizeValue) >= currency.minBetSize
    ) {
      if (bet.currentOfferId) {
        isEnabled =
          Number(sizeValue) !== Number(prevSizeValue.current) ||
          Number(priceValue) !== Number(prevPriceValue) ||
          (!!placedBet && placedBet.persistenceType !== persistenceType);
      } else {
        isEnabled = true;
      }
    }

    return isEnabled;
  };

  const isPlacementBtnEnabled = getIsPlacementBtnEnabled();

  useEffect(() => {
    if (isLayBet && currentFocus !== EBetFocusFields.LIABILITY) {
      setLiabilityValue(
        getLiabilityValue({
          marketId: bet.marketId,
          type: bet.type,
          handicap: bet.handicap,
          selectionId: bet.selectionId,
          priceValue: currentPrice,
          sizeValue,
          marketDefinition: marketPrices?.marketDefinition
        })
      );
    }
  }, [
    isLayBet,
    bet.marketId,
    bet.selectionId,
    bet.handicap,
    currentPrice,
    sizeValue,
    bet.type,
    stringifiedMarketDefinition
  ]);

  useEffect(() => {
    if (priceValue !== bet.price) {
      setPriceValue(bet.price);
      setStatus(Statuses.NEW);
    }
  }, [bet.price]);

  useEffect(() => {
    const isCancelling =
      currentBet &&
      (currentBet.action == ECurrentBetActions.CANCELLING || currentBet.action == ECurrentBetActions.CANCELLING_ALL);
    const isEditing = currentBet && currentBet.action == ECurrentBetActions.EDITING;

    if (
      currentBet &&
      (isCancelling || (isEditing && currentOldBets.length !== 0)) &&
      currentBet.offerState == BetsStatusesTypes.CANCELLED
    ) {
      setPlacedBet(currentBet);
      setStatus(Statuses.PLACED);
    }
  }, [currentBet, currentOldBets]);

  const onSuccessPlacement = (placedBets: TCurrentBet[], cancelledBets: TCurrentBet[]) => {
    if (placedBets.length) {
      dispatch(successInlinePlacedBet({ ...bet, placedBet: placedBets[0] }));
      if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
        dispatch(fetchBalance());
      }
    }

    if (cancelledBets.length) {
      dispatch(setCurrentBetAction({ offerId: cancelledBets[0].offerId, action: null }));
    }
  };

  const onErrorPlacement = (error: TPlacementError | string = '') => {
    const newErrorMessage = isString(error) ? error : error?.response?.data?.message ?? '';

    setErrorMessage(newErrorMessage);
    setStatus(Statuses.NEW);
    dispatch(failureInlinePlacedBet({ ...bet, error }));
    setHasErrorMessage(true);

    if (isResponsibleGamblingError(error)) {
      dispatch(setRGErrorMessage(error));
    }

    if (placedBet) {
      setStatus(Statuses.PLACED_EDIT);
      dispatch(removeProcessingMobileBet(placedBet.offerId));
    } else {
      setStatus(Statuses.NEW);
    }
  };

  const onCancelledPlacement = () => {
    if (isPNCEnabled && !isGame) {
      setIsTakeOffer(true);
      setErrorMessage(isLineMarket ? t(VALIDATION_ERROR_BET_OUTDATED_LINE) : t(VALIDATION_ERROR_BET_OUTDATED_ODDS));
      setStatus(Statuses.CONFIRM);
    }
  };

  const placement = usePlacementData({
    eachWayDivisor: market?.description?.eachWayDivisor,
    numberOfWinners: market?.numberOfWinners,
    successPlacement: onSuccessPlacement,
    errorPlacement: onErrorPlacement,
    onCancelledPlacement,
    isMobilePlacement: true
  });

  const marketUnitTranslated = useMarketUnits(marketUnit);

  const getPlaceButtonLabel = () => {
    if (isConfirmationStep) {
      return t('inlinePlacement.labels.confirmBet');
    } else if (isLowerPrice && !isLineMarket) {
      return t('placement.labels.acceptOdds');
    } else if (isTakeOffer) {
      return isLineMarket
        ? t('betslip.actions.placeBetAtAvailableUnits', { unit: marketUnitTranslated })
        : t('betslip.actions.placeBetAtAvailableOdds');
    } else if (bet.currentOfferId) {
      return t('inlinePlacement.labels.updateBet');
    } else {
      return t('inlinePlacement.labels.placeBet');
    }
  };

  const confirmBet = () => {
    if (isLoggedIn) {
      const betType = isGame ? EBetslipTypes.GAME : EBetslipTypes.EXCHANGE;
      const options = {
        isTakeOffer,
        betType,
        pageBlock,
        ...(isGame && market?.round ? { round: market?.round } : {})
      };
      setStatus(Statuses.PROGRESS);
      setHasErrorMessage(false);

      if (placedBet) {
        dispatch(
          setMobilePlacementNotification({
            pageBlock,
            data: {
              marketId: placedBet.marketId,
              offerId: placedBet.offerId,
              betUuid: bet.betUuid!,
              priceToShow: currentPrice,
              sizeToShow: sizeValue
            },
            placementType: MobilePlacementTypes.Edit
          })
        );

        dispatch(setProcessingMobileBet({ offerIdOrBetUuid: placedBet.offerId, isLoading: true }));

        placement.editBetsHandler({
          marketId: bet.marketId,
          bets: [
            {
              price: currentPrice,
              size: sizeValue,
              side: placedBet.side,
              selectionId: placedBet.selectionId,
              handicap: placedBet.handicap,
              offerId: placedBet.offerId,
              sizeRemaining: placedBet.sizeRemaining,
              persistenceType,
              page,
              betUuid: bet.betUuid
            }
          ],
          options
        });
      } else {
        dispatch(
          setMobilePlacementNotification({
            pageBlock,
            data: {
              marketId: bet.marketId,
              betUuid: bet.betUuid!,
              bet
            },
            placementType: MobilePlacementTypes.Place
          })
        );

        if (bet.betUuid) {
          dispatch(setProcessingMobileBet({ offerIdOrBetUuid: bet.betUuid, isLoading: true }));
        }

        placement.placeBetsHandler({
          bets: [
            {
              marketId: bet.marketId,
              price: currentPrice,
              size: sizeValue,
              handicap: bet.handicap,
              selectionId: bet.selectionId,
              side: bet.type.toUpperCase(),
              page,
              persistenceType,
              betUuid: bet.betUuid
            }
          ],
          options
        });
      }

      dispatch(removeInlineSelectedBet(bet));
      dispatch(setMobileInlineSelectedBetToRestore(bet));
    } else {
      placeBetLogin();
    }
  };

  const removeBet = (isManualClicked?: boolean) => {
    dispatch(removeInlineSelectedBet(bet));

    if (isEditActionParam && offerIdFromParams && isManualClicked) {
      searchParams.delete('action');
      searchParams.delete('offerId');

      setSearchParams(searchParams, { replace: true });
    }
  };

  useEffect(() => {
    if (placedBet) {
      setPersistenceType(placedBet.persistenceType);
    }
  }, [placedBet]);

  useEffect(() => {
    const newPrice = getBestPriceFromMarketPrices(marketPrice, marketType ?? '', bettingType ?? '');

    if (newPrice != priceValue && bestPrice != newPrice) {
      if (isTakeOffer) {
        setPrevPriceValue(priceValue);
        setPriceValue(newPrice);
      }
    }
  }, [bettingType, isTakeOffer, marketPrice, marketType]);

  useEffect(() => {
    const currentOfferId = inlineSelectedBet?.currentOfferId;

    if (
      [Statuses.PROGRESS, Statuses.TIMEOUT_HIDDEN].includes(status) &&
      currentOfferId &&
      inlineSelectedBet.offers &&
      inlineSelectedBet.offers[currentOfferId] &&
      [BetsStatusesTypes.MATCHED, BetsStatusesTypes.PLACED, BetsStatusesTypes.CANCELLED].includes(
        inlineSelectedBet.offers[currentOfferId].offerState
      )
    ) {
      setPlacedBet(inlineSelectedBet.offers[currentOfferId]);
      setStatus(Statuses.PLACED);
    }
  }, [inlineSelectedBet]);

  useEffect(() => {
    if (status === Statuses.PROGRESS) {
      placementTimeout.current = setTimeout(() => {
        setErrorMessage(t(LOADING_TIMEOUT_MESSAGE));
        setStatus(Statuses.TIMEOUT_HIDDEN);
      }, +betslipSpinnerTime * 1000);
    }

    if (status === Statuses.PLACED && !hasErrorMessage) {
      onClosingBetslip(true);
      removeBet();
    }

    return () => {
      if (placementTimeout.current) {
        clearTimeout(placementTimeout.current);
        placementTimeout.current = null;
      }
    };
  }, [status, bet.action]);

  const onConfirmBtnClick = () => {
    if (isPlacementBtnEnabled) {
      editBet();
    }
  };

  const editBet = () => {
    if (isConfirmBetsBeforePlacement && !isConfirmationStep && !bet.currentOfferId) {
      dispatch(setIsMobilePlacementConfirmationStep(true));
      if (autoRefreshIsEnabled && isPNCEnabled && !isGame) {
        setPriceValue(bestPrice);
      }
    } else {
      confirmBet();
    }
  };

  const handleChangeInvalidInputs = (input: Exclude<EBetFocusFields, EBetFocusFields.LIABILITY>, value: boolean) => {
    setInvalidInputs(prevState => ({
      ...prevState,
      [input]: value
    }));
  };

  useEffect(() => {
    dispatch(
      setInlinePlacedSize({
        betUuid: bet.betUuid,
        price: priceValue || 0,
        size: sizeValue || 0,
        marketId: bet.marketId,
        pageBlock
      })
    );
  }, [priceValue, sizeValue]);

  useEffect(() => {
    setPriceValue(bet.price);
    if (!bet.size && accountSettings?.defaultStake && !isGame) {
      const defStake = accountSettings?.defaultStakes.find(item => item?.defaultValue);
      setSizeValue(defStake?.value ?? '');
    } else {
      setSizeValue(bet.size ?? '');
    }
    if (bet.currentOfferId) {
      if (bet.offers) {
        setPlacedBet(bet.offers[bet.currentOfferId]);
      }
      setStatus(bet.action ? Statuses.PLACED_EDIT : Statuses.PLACED);
    } else {
      setStatus(Statuses.NEW);
      setPlacedBet(null);
    }
  }, [bet.selectionId, bet.type, bet.action]);

  useEffect(() => {
    return () => {
      onClosingBetslip(false);
      dispatch(removeInlineSelectedBet(bet));
      dispatch(setIsMobilePlacementConfirmationStep(false));
    };
  }, []);

  useEffect(() => {
    if (isDisabledLayOdds) {
      dispatch(removeInlineSelectedBet(bet));
    }
  }, [isDisabledLayOdds]);

  const updateCurrentFocus = (newFocus: EBetFocusFields | null) => {
    setCurrentFocus(newFocus);
  };

  const handleValidateSizeInput = (isLiabilityField?: boolean) => {
    const {
      isValid,
      validValue,
      errorMessage: sizeErrorMessage
    } = validateSize({
      size: sizeValue,
      betType: bet.type,
      currency,
      defaultCurrency,
      isOperatorBettingLimitsEnabled: false,
      isGame,
      isLiabilityField
    });

    if (!isValid) {
      handleChangeInvalidInputs(EBetFocusFields.SIZE, true);

      if (sizeErrorMessage) {
        setErrorMessage(t(sizeErrorMessage.text, sizeErrorMessage.params));
      }

      const computedLiabilityValue = getLiabilityValue({
        marketId: bet.marketId,
        type: bet.type,
        handicap: bet.handicap,
        selectionId: bet.selectionId,
        priceValue,
        sizeValue: validValue,
        marketDefinition: marketPrices?.marketDefinition
      });

      setSizeValue(validValue);
      setLiabilityValue(computedLiabilityValue);
    }
  };

  const handlerQuickBets = useCallback(
    (value: string | number) => {
      const newSizeValue = precisionFormat(+(sizeValue || 0) + +value);
      setSizeValue(newSizeValue);
      setCurrentFocus(EBetFocusFields.SIZE);
    },
    [sizeValue]
  );

  const handleKeyClick = (key: string) => {
    const validation = /^\d+\.{0,1}\d{0,2}$/;

    if (currentFocus === EBetFocusFields.PRICE) {
      if (priceValue === '0' && key !== '.') return;
      let newPriceValue = priceValue === 0 ? key : priceValue + key;

      if (key === '00' && !priceValue) {
        newPriceValue = '0';
      }

      if (validation.test(newPriceValue)) {
        setPriceValue(newPriceValue);
      }
    } else if (currentFocus === EBetFocusFields.SIZE) {
      if (sizeValue === '0' && key !== '.') return;
      let newSizeValue = sizeValue + key;

      if (key === '00' && !sizeValue) {
        newSizeValue = '0';
      }
      if (validation.test(newSizeValue)) {
        setSizeValue(newSizeValue);
      }
    } else {
      const stringLiabilityValue = liabilityValue === 0 ? '' : liabilityValue;
      if (stringLiabilityValue === '0' && key !== '.') return;
      let newLiabilityValue = stringLiabilityValue + key;

      if (key === '00' && !stringLiabilityValue) {
        newLiabilityValue = '0';
      }

      if (validation.test(newLiabilityValue)) {
        setLiabilityValue(newLiabilityValue);
        const newSizeValue = calculateSize(priceValue, newLiabilityValue, { bettingType: bet.bettingType });
        setSizeValue(newSizeValue);
      }
    }
  };

  const handleBackSpace = () => {
    if (currentFocus === EBetFocusFields.PRICE) {
      if (priceValue) {
        const newPriceValue = priceValue.toString().slice(0, -1);

        setPriceValue(newPriceValue);
      }
    } else if (currentFocus === EBetFocusFields.SIZE) {
      if (sizeValue) {
        const newSizeValue = sizeValue.toString().slice(0, -1);

        setSizeValue(newSizeValue);
      }
    } else {
      const newLiabilityValue = liabilityValue?.toString().slice(0, -1) ?? '';
      const newSizeValue = calculateSize(priceValue, newLiabilityValue, { bettingType: bet.bettingType });

      setLiabilityValue(newLiabilityValue);
      setSizeValue(newSizeValue);
    }
  };

  const onClickHeaderBtn = () => {
    if (isConfirmationStep) {
      dispatch(setIsMobilePlacementConfirmationStep(false));
    } else {
      removeBet(true);
    }
  };

  const onChangeLiability = (newLiability: string | number) => {
    const newSizeValue = calculateSize(priceValue, newLiability, { bettingType: bet.bettingType });

    setLiabilityValue(newLiability);
    setSizeValue(newSizeValue);
  };

  const getOddsTooltipData = () => {
    if (isConfirmationStep) {
      if (isLineMarket) {
        const key = isLayBet ? BETSLIP_BACKERS_UNITS : BETSLIP_UNITS;
        return { key, options: { UNITS: marketUnit.toLocaleLowerCase() } };
      }

      return { key: isLayBet ? BETSLIP_BACKERS_ODDS : BETSLIP_ODDS };
    }
  };

  const resetErrorMessage = () => {
    setErrorMessage('');
    setInvalidInputs(initialInvalidInputs);
  };

  const stakeTooltipKey = isLayBet ? BETSLIP_BACKERS_STAKE : BETSLIP_STAKE;
  const bestPriceFormatted = formatBestPrice(bestPrice, bet.marketType, bet.bettingType ?? '');

  if (isClosed) {
    return <MobilePlacementClosedMessage bet={bet} />;
  }

  return (
    <>
      <div
        className={classNames(
          containerClassName,
          bet.type === BetTypes.BACK ? branding.BACK : branding.LAY,
          branding.MOBILE_BETSLIP,
          { [branding.DISABLED]: isDisabled }
        )}
      >
        {isBetslipVisible && (
          <>
            <div
              className={classNames(
                styles.header,
                {
                  [styles.header__back]: bet.type === BetTypes.BACK,
                  [styles.header__lay]: bet.type === BetTypes.LAY,
                  [styles.header__unmatched]: bet.action === Actions.EDIT,
                  [styles.header__disabled]: isDisabled && bet.action !== Actions.EDIT,
                  [branding.UNMATCHED]: bet.action === Actions.EDIT
                },
                branding.BET_HEADER
              )}
            >
              <p
                className={classNames(styles.header__title, {
                  [styles.header__title__unmatched]: bet.action === Actions.EDIT
                })}
              >
                {labels[bet.type]?.bet}:
                <span className={styles.header__title__selection}>
                  {selectionName}
                  {isLineMarket && !isPNCEnabled ? ` @${BETSLIP_LINE_INT_ODDS}` : ''}
                </span>{' '}
                - {marketName}
              </p>
              <button className={styles.header__close__btn} onClick={onClickHeaderBtn}>
                {isConfirmationStep ? (
                  <i className={classNames('biab_custom-icon-edit', styles.header__edit)} />
                ) : (
                  <i
                    className={classNames('biab_custom-icon-close', styles.header__close, {
                      [styles.header__close__unmatched]: bet.action === Actions.EDIT
                    })}
                  />
                )}
              </button>
            </div>
            {isCurrencyChanged ? (
              <div className={styles.currencyChangedWrapper}>
                <MobilePlacementValidationMessage
                  message={t(VALIDATION_ERROR_DIFFERENT_CURRENCY_MARKET, { currency_ISO_code: marketCurrency ?? '' })}
                  type="info"
                />
              </div>
            ) : (
              <div
                className={classNames('biab_open-bet-details', styles.betDetails, {
                  [styles.betDetails__disabled]: isDisabled
                })}
              >
                <div className={classNames({ [styles.landscape__top]: isLandscape })}>
                  <div
                    className={classNames(styles.inputsWrapper, {
                      [styles.inputsWrapper__landscape]: isLandscape
                    })}
                  >
                    {(isPNCEnabled && !isGame) || isConfirmationStep || isDisabled ? (
                      <NoEditableInput
                        value={currentPrice}
                        label={isLineMarket && !isPNCEnabled ? t('market.units.runs') : t('betslip.labels.price.back')}
                        showOddsChangedIndicators={showOddsChangedIndicators}
                        isGoingUp={isGoingUp}
                        isLandscape={isLandscape}
                        tooltipData={getOddsTooltipData()}
                      />
                    ) : (
                      <PriceInput
                        setErrorMessage={setErrorMessage}
                        setPriceValue={setPriceValue}
                        priceLadderDescription={priceLadderDescription}
                        lineRangeInfo={lineRangeInfo}
                        marketType={marketType || ''}
                        bettingType={bettingType || ''}
                        betType={bet.type}
                        marketUnit={marketUnit}
                        priceValue={priceValue || ''}
                        currentFocus={currentFocus}
                        setFocused={() => updateCurrentFocus(EBetFocusFields.PRICE)}
                        isInvalid={invalidInputs[EBetFocusFields.PRICE]}
                        onChangeInvalidInputs={handleChangeInvalidInputs}
                      />
                    )}
                    {isConfirmationStep || isDisabled ? (
                      <NoEditableInput
                        value={sizeValue}
                        label={t('betslip.labels.size.back')}
                        isLandscape={isLandscape}
                        tooltipData={isConfirmationStep ? { key: stakeTooltipKey } : null}
                      />
                    ) : (
                      <SizeInput
                        setSizeValue={setSizeValue}
                        sizeValue={sizeValue ?? ''}
                        currentFocus={currentFocus}
                        setFocused={() => updateCurrentFocus(EBetFocusFields.SIZE)}
                        isInvalid={invalidInputs[EBetFocusFields.SIZE]}
                        validateSizeInput={handleValidateSizeInput}
                      />
                    )}
                  </div>
                  <div
                    className={classNames(styles.payoutAndLiability, {
                      [styles.payoutAndLiability__landscape]: isLandscape
                    })}
                  >
                    {!isLandscape && <Payout payout={payout} isLandscape={false} />}
                    {isLayBet ? (
                      <>
                        {isConfirmationStep || isDisabled ? (
                          <NoEditableInput
                            value={liabilityValue}
                            label={t('betslip.labels.liability')}
                            isLandscape={isLandscape}
                            tooltipData={isConfirmationStep ? { key: BETSLIP_LIABILITY } : null}
                          />
                        ) : (
                          <LiabilityInput
                            onChangeLiability={onChangeLiability}
                            liabilityValue={liabilityValue ?? ''}
                            currentFocus={currentFocus}
                            setFocused={() => updateCurrentFocus(EBetFocusFields.LIABILITY)}
                            validateSizeInput={handleValidateSizeInput}
                          />
                        )}
                      </>
                    ) : (
                      <Liability
                        liability={liability}
                        isLandscape={isLandscape}
                        isConfirmationStep={isConfirmationStep}
                      />
                    )}
                  </div>
                  {isLandscape && (
                    <PlaceButton
                      placementLabel={getPlaceButtonLabel()}
                      onClick={onConfirmBtnClick}
                      isDisabled={!isPlacementBtnEnabled}
                      showBorder={isConfirmationStep || isLowerPrice}
                      isTakeOffer={isTakeOffer}
                      isLandscape={isLandscape}
                    >
                      <Payout payout={payout} isLandscape />
                    </PlaceButton>
                  )}
                </div>
                {placedBet?.persistenceEnabled && (
                  <PersistenceRadioGroup
                    bet={bet}
                    persistenceType={persistenceType}
                    setPersistenceType={setPersistenceType}
                    pageBlock={pageBlock}
                  />
                )}
                {errorMessage && (
                  <MobilePlacementValidationMessage
                    message={errorMessage}
                    type="warning"
                    resetErrorMessage={resetErrorMessage}
                    className={classNames({ [styles.landscape__validationMsg]: isLandscape })}
                  />
                )}
                {showOddsChangedIndicators && (
                  <div
                    className={classNames(
                      styles.oddsChangedNotification,
                      mobileComponents.NOTIFICATION,
                      mobileComponents.INFO,
                      {
                        [styles.oddsChangedNotification__landscape]: isLandscape,
                        [styles.oddsChangedNotification__marginTop]: !!errorMessage
                      }
                    )}
                  >
                    <div className={styles.oddsChangedNotification__inner}>
                      <i
                        className={classNames(
                          'biab_custom-icon-info-circle',
                          styles.oddsChangedNotification__icon,
                          mobileIcons.INFO_ICON
                        )}
                      />
                      <SelectedBetOddsNotificationMessage
                        isLineMarket={isLineMarket}
                        isGoingUp={isGoingUp}
                        odds={bestPriceFormatted}
                        initPrice={bet.price}
                      />
                    </div>
                  </div>
                )}
                <QuickBets
                  handler={handlerQuickBets}
                  isDisabled={isConfirmationStep || isDisabled}
                  quickBetClassname={styles.quickBet}
                  emptyContainer={isLandscape ? undefined : <div className={styles.emptyQuickBets} />}
                  customClassName={styles.quickBets}
                  shouldIgnoreBlurEvent
                />
                {virtualKeyboardBetslip && (
                  <NewVirtualKeyboard
                    onKeyClick={handleKeyClick}
                    onBackSpace={handleBackSpace}
                    isDisabledKeys={isConfirmationStep || isDisabled}
                    containerClassName={classNames(styles.keyboard, { [styles.keyboard__landscape]: isLandscape })}
                    shouldIgnoreBlurEvent
                  />
                )}
                {!isLandscape && (
                  <PlaceButton
                    placementLabel={getPlaceButtonLabel()}
                    onClick={onConfirmBtnClick}
                    isDisabled={!isPlacementBtnEnabled}
                    showBorder={isConfirmationStep || isLowerPrice}
                    isTakeOffer={isTakeOffer}
                    isLandscape={isLandscape}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
      <RGModalMessage />
    </>
  );
}

export default MobilePlacement;

type PlaceButtonProps = {
  placementLabel: string;
  isDisabled: boolean;
  showBorder: boolean;
  onClick: () => void;
  isTakeOffer: boolean;
  isLandscape: boolean;
};

function PlaceButton({
  placementLabel,
  isDisabled,
  children,
  onClick,
  isTakeOffer,
  showBorder,
  isLandscape
}: PropsWithChildren<PlaceButtonProps>) {
  return (
    <button
      onClick={onClick}
      type="button"
      disabled={isDisabled}
      className={classNames(styles.placeBtn, mobileComponents.BUTTON, mobileComponents.PRIMARY, {
        [styles.placeBtn__confirm]: showBorder || isTakeOffer,
        biab_disabled: isDisabled,
        [styles.landscape__placeBtn]: isLandscape,
        [mobileComponents.FOCUS]: showBorder,
        [mobileComponents.DISABLED]: isDisabled
      })}
    >
      {placementLabel}
      {children}
    </button>
  );
}

type PayoutProps = {
  payout: number | string;
  isLandscape: boolean;
};

function Payout({ payout, isLandscape }: PayoutProps) {
  const { t } = useTranslation();

  const { noFormattedAmount: formattedPayout } = useFormatCurrency(payout || 0, '', {
    noRounding: true,
    isCheckIndian: true
  });

  return (
    <p
      className={classNames(styles.payout, {
        [styles.payout__landscape]: isLandscape
      })}
    >
      {t('betslip.labels.payout')}:{' '}
      <span
        className={classNames(styles.payout__value, {
          [styles.payout__value__landscape]: isLandscape
        })}
      >
        {formattedPayout}
      </span>
    </p>
  );
}
