import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import useDevice from 'hooks/useDevice';
import useElementSize from 'hooks/useElementSize';
import { getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getBackendPageData } from 'redux/modules/pages/selectors';

import commonStyles from '../styles.module.scss';

const ResponsibleBettingSelfExclusionSuspendedTemplate = () => {
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const data = useSelector(getBackendPageData);
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const headerHeight = useSelector(getElementHeightByName(EElementNames.HEADER_HEIGHT));
  const footerHeight = useSelector(getElementHeightByName(EElementNames.FOOTER_HEIGHT));

  const hasMinHeightStyle = !(isMobile && isIframeEnabled);
  const minHeightStyle = hasMinHeightStyle ? { minHeight: `calc(100vh - ${headerHeight + footerHeight}px)` } : {};

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <div
      className={classNames(commonStyles.container, {
        [commonStyles.container__mobile]: isMobile
      })}
      ref={componentRef}
      style={{
        ...minHeightStyle
      }}
    >
      <div className={commonStyles.content}>
        <div className={commonStyles.iconWrapper}>
          <i className={classNames(commonStyles.icon, commonStyles.icon__unlocked, 'biab_custom-icon-unlocked')} />
        </div>
        <h3 className={commonStyles.title}>{data?.name}</h3>
        <div className={commonStyles.description} dangerouslySetInnerHTML={{ __html: unescape(data?.content ?? '') }} />
      </div>
    </div>
  );
};

export default ResponsibleBettingSelfExclusionSuspendedTemplate;
