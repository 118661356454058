import {
  ACCOUNT_BASE_URL,
  ASIAN_BASE_URL,
  ASIAN_MOBILE_BET_LIST_URL,
  ASIAN_MOBILE_FAVOURITES_URL,
  ASIAN_VIEW_MORE_URL,
  GAMES_BASE_URL,
  MOBILE_ACCOUNT_SETTINGS_BASE_URL,
  MOBILE_LOGIN_BASE_URL,
  MY_BETS_BASE_URL,
  SEARCH_BASE_URL,
  SPORT_BASE_URL
} from 'constants/locations';
import { SportId } from 'types';
import { IRunnersStatisticsMap, ITimeFormHistoryColumns } from 'types/markets';

export const MOBILE_MAX_DISPLAY_SIZE = 999;
export const NEXT_RACE_COUNT = 4;
export const POPULAR_MARKET_MIN_COUNT = 2;
export const POPULAR_MARKET_MAX_COUNT = 4;
export const RACE_UPDATE_INTERVAL = 15000;
export const BALANCE_UPDATE_INTERVAL = 10000;
export const AUTO_CASH_OUT_GET_MARKETS_INTERVAL = 3000;
export const SOCCER_PERIOD_TIME = 45;
export const SOCCER_EXTRA_PERIOD_TIME = 15;
export const SOCCER_NUMBER_OF_PENALTIES = 5;

export const IOS_BUNDLE = 'IOS_BUNDLE';

export const BIAB_CUSTOM_POPUP = 'BIAB_CUSTOM_POPUP';
export const PREV_PAGE_BEFORE_LOGIN = 'PREV_PAGE_BEFORE_LOGIN';

export const OPEN_ALL_SPORT = 'OPEN_ALL_SPORT';

export const SPORTS_NAME: Record<string, string> = {
  [SportId.HORSE_RACING]: 'horseRacing',
  [SportId.GRAY_HOUND_RACING]: 'greyhoundRacing'
};

export const BETTING_TYPES = {
  asianHandicapSingleLine: 'ASIAN_HANDICAP_SINGLE_LINE',
  asianHandicapDoubleLine: 'ASIAN_HANDICAP_DOUBLE_LINE',
  line: 'LINE',
  odds: 'ODDS',
  altTotalGoals: 'ALT_TOTAL_GOALS',
  anyNumberOfWinners: 'ANY_NUMBER_OF_WINNERS'
};

export const FAVORITES_TYPES: Record<string, string> = {
  event: 'EVENT',
  market: 'MARKET',
  sport: 'EVENT_TYPE',
  country: 'COUNTRY',
  competition: 'COMPETITION',
  group: 'GROUP'
};

export const RACING_SPORT: Record<string, boolean> = {
  [SportId.HORSE_RACING]: true,
  [SportId.GRAY_HOUND_RACING]: true
};

export const RACING_SPORT_WITH_TODAY: Record<string, boolean> = {
  [SportId.HORSE_RACING]: true,
  [SportId.GRAY_HOUND_RACING]: true,
  [SportId.TODAY_GRAY_HOUND_RACING]: true,
  [SportId.TODAY_HORSE_RACING]: true
};

export const IS_SPECIAL_HEADER_ON_EVENT: Record<string, boolean> = {
  [SportId.TENNIS]: true,
  [SportId.SOCCER]: true,
  [SportId.CRICKET]: true,
  [SportId.BASKETBALL]: true
};

export const ROUTER_BASE_URL = window.environmentConfig?.rootPath || '/customer';

export const APPLICATION_TYPE_WEB = 'WEB';

export const EXCHANGE = 'EXCHANGE';
export const GAME = 'GAME';

export const ONLY_MOBILE_URLS = [
  ACCOUNT_BASE_URL,
  MOBILE_LOGIN_BASE_URL,
  SPORT_BASE_URL,
  SEARCH_BASE_URL,
  MOBILE_ACCOUNT_SETTINGS_BASE_URL,
  `${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}`,
  ASIAN_MOBILE_FAVOURITES_URL,
  ASIAN_VIEW_MORE_URL,
  ASIAN_MOBILE_BET_LIST_URL,
  `${ASIAN_BASE_URL}${MOBILE_LOGIN_BASE_URL}`,
  `${GAMES_BASE_URL}${MOBILE_LOGIN_BASE_URL}`,
  `${ASIAN_BASE_URL}${MOBILE_ACCOUNT_SETTINGS_BASE_URL}`,
  `${GAMES_BASE_URL}${MOBILE_ACCOUNT_SETTINGS_BASE_URL}`,
  `${ASIAN_BASE_URL}${ACCOUNT_BASE_URL}`,
  `${GAMES_BASE_URL}${ACCOUNT_BASE_URL}`
];

export const TIME_FILTERS = ['all', 'in_play', 'today', 'tomorrow', 'future'];

export const PRECISE_LEN = Math.pow(10, 3);

export const TODAY_CARD_PREFIX = 'tc-';
export const MOBILE_LIST_VIEW_MAX_TABS = 5;
export const LIST_VIEW_TAB_WIDTH = 120;
export const LONG_MARKETS_SCROLL_NUMBER = 10;
export const LANDSCAPE_MIN_WIDTH = 480;
export const MOBILE_MAX_NAV_MENU_LENGTH = 30;
export const COLLAPSIBLE_SIDEBAR_WINDOW_WIDTH = 1280;

export const MAX_PRICE = 1000;
export const MIN_PRICE = 1.01;

export const ALL_MARKETS_TAB_NAME = 'All Markets';
export const IS_SCROLLING_SECTION = 'scrolling';

export const CSRF_TOKEN_URLS =
  '.*/api/currentBets|.*/api/account/info|.*/api/account/statement.*|.*/api/myBets.*|.*/api/total/profit.*';

export const TIME_FORM_RUNNER_DETAILS: Array<keyof IRunnersStatisticsMap> = [
  'form',
  'trainer',
  'owner',
  'age',
  'weight',
  'officialRating',
  'pedigree',
  'daysSinceLastRace',
  'jockeyClaim',
  'gear'
];

export const TIME_FORM_KEY_GOING = 'going';
export const TIME_FORM_HISTORY_MAX_COUNT = 6;
export const TIME_FORM_HISTORY_MOBILE_MAX_COLUMNS = 6;

export const TIME_FORM_HISTORY_COLUMNS: ITimeFormHistoryColumns[] = [
  { key: 'date', label: 'timeform.date', isShort: true },
  { key: 'course', label: 'timeform.course', isShort: true },
  { key: 'distance', label: 'timeform.distance', isShort: true },
  { key: 'going', label: 'timeform.going', isShort: true },
  { key: 'pos', label: 'timeform.pos', isShort: true },
  { key: 'type', label: 'timeform.type', isShort: true },
  { key: 'or', label: 'timeform.OR', isShort: false },
  { key: 'bsp', label: 'timeform.BSP', isShort: false },
  { key: 'ipHiLo', label: 'timeform.IPHiLo', isShort: false },
  { key: 'jockey', label: 'timeform.jockey', isShort: false }
];

export const PL_TABLE_HEADER = ['4+', '4', '3', '2', '1', '1', '2', '3', '4', '4+'];

export const MOBILE_PL_TABLE_HEADER = ['4+', '4', '3', '2', '1', '', '1', '2', '3', '4', '4+'];

export const DEFAULT_CURRENCY = 'USD';
export const BASKETBALL_NORMAL_MATCH = 4;

export enum PopupTypes {
  FAVORITE = 'favorite',
  LOGIN_POPUP = 'loginPopup',
  TWO_FACTOR_AUTH = 'twoFactorAuth'
}

export enum PostMessageTypes {
  LOGIN = 'evLoggedIn',
  LOGOUT = 'loggedOut',
  MY_BETS = 'myBets',
  APP_LOADED = 'evLoaded',
  ROUTE_CHANGE = 'routeChange',
  DAY_LIMIT = 'dayLimitExceed',
  LOGIN_TO_PLACE_BET = 'loginToPlaceBet',
  HEIGHT_CHANGE = 'heightChange'
}

export enum NavigationItemType {
  MY_BETS = 'myBets'
}

export const TFA_ACTIVATION_TIMEOUT = 60000;
export const TFA_CODE_LENGTH = 6;

export const tooltipStatus: Record<string, string> = {
  SUSPENDED: 'tooltip.suspendedStatus',
  CLOSED: 'tooltip.closedStatus'
};

export const KEY_NAME_ESC = 'Escape';
export const KEY_EVENT_TYPE = 'keyup';

export enum BroadCastHandledTypes {
  LOGOUT = 'LOGOUT'
}

export enum BroadCastChannels {
  USER_ACTIONS = 'USER_ACTIONS'
}

export const GAME_BETTING = 'Game Betting';

export const SET = 'set';

export const CASHOUT_HEADER_HEIGHT = 47;

export const OPERATOR = {
  POKER_STAR: 'ps',
  AB: 'ab',
  ORBIT: 'orbit',
  EWL: 'ewl',
  FUN: 'FUN',
  DEFAULT: 'DEFAULT',
  A2F: 'a2f',
  NEXTSPRINT: 'nextsprint'
};

export const EXTRA_SMALL_SCREE_SIZE = 370;

export const SLICES_NAMES = {
  RACING: '@racing',
  MARKETS_PRICES: '@marketsPrices',
  EVENT: '@event',
  LIVE_SCORE: '@liveScore',
  POPULAR_MARKETS: '@popularMarkets',
  WHAT_IF: '@whatIf',
  ASIAN_SPORTS: '@asianSports',
  ASIAN_VIEW: '@asianView',
  ASIAN_VIEW_BETSLIP: '@asianViewBetslip',
  ASIAN_VIEW_QUICK_BETTING: '@asianViewQuickBetting',
  ASIAN_VIEW_MOBILE_BETSLIP: '@asianViewMobileBetslip',
  ASIAN_VIEW_SEARCH: '@asianViewSearch',
  ASIAN_VIEW_WIDGETS: '@asianViewWidgets',
  CURRENT_BETS: '@currentBets',
  ASIAN_CURRENT_BETS: '@asianCurrentBets',
  APP_CONFIGS: '@appConfigs',
  APP_SETTINGS: '@appSettings',
  AUTH: '@auth',
  ASIAN_VIEW_FAVOURITES: '@asianViewFavourites',
  OBSERVER: '@observer',
  TOOLTIP: '@tooltip',
  CASH_OUT_COUNTER: '@cashOutCounter',
  BANNERS: '@banners',
  COUPON_SETTINGS: '@couponSettings',
  ASIAN_VIEW_LEAGUES_FILTER: '@asianViewLeaguesFilter',
  ASIAN_VIEW_POPULAR_LINKS: '@asianViewPopularLinks',
  LOGIN_POP_UP: '@loginPopUp',
  COLLAPSE: '@collapse',
  ASIAN_VIEW_BET_SLIP_CASH_OUT: '@asianViewBetSlipCashOut',
  EVENT_CASH_OUT: '@eventCashOut',
  BACKEND_PAGE: '@backendPage',
  USER: '@user',
  RESPONSIBLE_BETTING: '@responsibleBetting',
  MARKET: '@market',
  MULTI_MARKET: '@multiMarket',
  BET_SLIP: '@betSlip',
  PLACEMENT: '@placement',
  INLINE_PLACEMENT: '@inlinePlacement',
  CASH_OUT: '@cashOut',
  COMPETITIONS: '@competitions',
  FEATURED_MARKETS: '@featuredMarkets',
  NEXT_RACES: '@nextRaces',
  PL_TABLE: '@plTable',
  GAMES: '@games',
  CANCEL_ACTIONS: '@cancelActions',
  MY_BETS: '@myBets',
  WEB_SOCKET: '@webSocket',
  HOME: '@home',
  IN_PLAY: '@inPlay',
  SEARCH: '@search',
  SPORTS: '@sports',
  TOURS: '@tours',
  LIST_VIEW_TABS: '@listViewTabs',
  FAVOURITES: '@favourites',
  META: '@meta',
  VIDEO_STREAM: '@videoStream',
  POPULAR: '@popular',
  POKER_STAR: '@pokerStar',
  FANCY_MARKET: '@fancyMarket',
  SPORT_PAGE: '@sportPage',
  PLACEMENT_MESSAGE: '@placementMessage',
  TOP_5_VIEW: '@top5View',
  BETS_STATUSES: '@betsStatuses',
  PROFIT_LOSS_TABLE_POP_UP: '@profitLossTablePopUp'
} as const;

export const ASIAN_TABS_EARLY_PREFIX = 'early';
export const ASIAN_TABS_TODAY_PREFIX = 'today';
export const ASIAN_TABS_LIVE_PREFIX = 'live';
export const ASIAN_TABS = [
  {
    title: 'Early',
    key: ASIAN_TABS_EARLY_PREFIX
  },
  {
    title: 'Today',
    key: ASIAN_TABS_TODAY_PREFIX
  },
  {
    title: 'Live',
    key: ASIAN_TABS_LIVE_PREFIX
  }
];
export const IS_VISITED_APP_SESSION_STORAGE_KEY = 'IS_VISITED_APP';
export const MAX_DOUBLE_DIGIT_NUMBER = 99;
export const MAX_TRIPLE_DIGIT_NUMBER = 999;
export const FEATURED_MARKET_MAX_RUNNERS_LENGTH_TO_DISPLAY = 4;
export const CHANGE_BANNER_INTERVAL = 3000;
export const MAX_BANNERS_ITEMS = 5;

export const BannerNames = {
  homePage: 'homePage',
  subPageContent: 'subPageContent',
  badge: 'badge',
  badgeSkyscraper: 'badgeSkyscraper',
  promotion: 'promotion',
  logo: 'logo',
  footerLogo: 'footerLogo',
  favicon: 'favicon',
  notFoundBackground: 'notFoundBackground'
} as const;

export type BannerNamesKeys = typeof BannerNames[keyof typeof BannerNames];

export const GeneralWebSocketSubscriptionTypes = {
  currentBets: 'CURRENT_BETS',
  cashOutQuote: 'CASH_OUT_QUOTE',
  autoCashOut: 'AUTO_CASH_OUT',
  eventUpdates: 'EVENT_UPDATES',
  eventUpdatesPL: 'EVENT_INFO_UPDATES_PL', // subscription type for profit loss table pop up
  eventInfoUpdates: 'EVENT_INFO_UPDATES', // shortened version of event-updates data -> without updateDetails field
  balance: 'BALANCE',
  cashOutCounter: 'CASH_OUT_COUNTER',
  cashOutQuoteAsianBetSlip: 'CASH_OUT_QUOTE_ASIAN_BET_SLIP',
  cashOutQuoteAsianEvent: 'CASH_OUT_QUOTE_ASIAN_EVENT',
  autoCashOutAsianBetSlip: 'AUTO_CASH_OUT_ASIAN_BET_SLIP',
  autoCashOutAsianEvent: 'AUTO_CASH_OUT_ASIAN_EVENT',
  properties: 'PROPERTIES'
} as const;

export type GeneralWebSocketSubscriptionTypesKeys =
  typeof GeneralWebSocketSubscriptionTypes[keyof typeof GeneralWebSocketSubscriptionTypes];

export const PROFIT_LOSS_TABLE_POP_UP_FETCH_DATA_INTERVAL = 15000;
export const PROFIT_LOSS_TABLE_POP_UP_COUNT_DOWN_INTERVAL = 1000;
export const PROFIT_LOSS_TABLE_POP_UP_REFRESH_DATA_INIT_SECONDS = 15;

export const AV_SUCCESS_STATUS_CASH_OUT_MARKETS_REQUEST_INTERVAL = 3000;
export const PROGRESS_BAR_INTERVAL = 1000;

export const BODY_ID = 'biab_body';

// FYI: the need to create this constant arises because at some point pages (in particular during refresh) are not loaded yet and the url is an empty string
export const RESPONSIBLE_BETTING_SELF_EXCLUSION_PAGE_URL = '/pages/en/rg_tools_self_exclusion_start_page_link';

// FIY: Maximum value for setTimeout (about 24.85 days)
export const SAFE_TIME_OUT_PERIOD_IN_MINUTES = 35784;
