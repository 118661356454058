import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import WhatIfValue from 'components/WhatIf/components/WhatIfValue';
import { getCurrentWhatIf } from 'components/WhatIf/helpers';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { getBetslipLoading } from 'redux/modules/betslip/selectors';
import { getWhatIfCashOut } from 'redux/modules/cashOut/selectors';
import { getCurrentWhatIfById, getNewWhatIfById } from 'redux/modules/whatIf/selectors';

import styles from './styles.module.scss';

interface NetPLValueProps {
  selectionId: number;
  marketId: string;
  commission: number;
  eachWayDivisor?: number | null;
  currency: string | undefined;
  numberOfWinners: number | undefined;
  handicap: number;
  classes?: Record<string, string>;
}

const NetPLValue = ({
  selectionId,
  handicap,
  marketId,
  commission,
  eachWayDivisor,
  currency,
  numberOfWinners,
  classes
}: NetPLValueProps) => {
  const isNetOfCommissionChecked = true;

  const currentBets = useSelector(getCurrentWhatIfById(marketId));
  const newBets = useSelector(getNewWhatIfById(marketId));
  const cashOutProfit = useSelector(getWhatIfCashOut(marketId, selectionId));
  const isBetslipLoading = useSelector(getBetslipLoading);

  const { isMultiCurrencySupported, isMultiCurrencyChanged } = useMultiCurrencySupporting();

  const isCurrencyChanged = isMultiCurrencyChanged(newBets[0]?.currency);
  const isMultiCurrencyMessage = isMultiCurrencySupported && isCurrencyChanged;
  const stringifiedCurrentBets = JSON.stringify(currentBets);
  const stringifiedNewBets = JSON.stringify(newBets);

  const { currentPL, updateBet } = useMemo(() => {
    let currentPlVal: string[] = [];
    let updateBetVal: string[] = [];

    if (!isBetslipLoading) {
      currentPlVal = getCurrentWhatIf({
        bets: currentBets.length ? currentBets : [],
        eachWayDivisor,
        numberOfWinners,
        selectionId,
        handicap
      });

      if (!isMultiCurrencyMessage) {
        const isNew = !!(currentBets.length && newBets.length);

        updateBetVal = getCurrentWhatIf({
          bets: isNew ? [...newBets, ...currentBets] : newBets,
          eachWayDivisor,
          numberOfWinners,
          selectionId,
          handicap,
          isNew
        });
      }
    }

    return { currentPL: currentPlVal, updateBet: updateBetVal };
  }, [
    stringifiedCurrentBets,
    stringifiedNewBets,
    isBetslipLoading,
    isMultiCurrencyMessage,
    selectionId,
    handicap,
    numberOfWinners,
    eachWayDivisor
  ]);

  const getCashOutProfit = () => {
    const matchedPL = parseFloat(currentPL[0]);
    if (cashOutProfit) {
      return `${(cashOutProfit.profit - matchedPL) * cashOutProfit.ratio + matchedPL}`;
    }

    return '';
  };

  return (
    <div className={classNames(styles.container, classes?.container ?? '')}>
      {currentPL.map((item, index) => (
        <WhatIfValue
          key={index}
          item={item}
          commission={commission}
          currency={currency}
          isProfitNetOfCommissionChecked={isNetOfCommissionChecked}
          isFinish={index < currentPL.length - 1}
          isPLTable
        />
      ))}

      <div className={styles.wrapBlock}>
        {(!!updateBet.length || (cashOutProfit && currentPL.length === 1)) && (
          <div className={styles.itemContainer}>
            <div className={styles.itemContainer}>
              {cashOutProfit && (
                <WhatIfValue item={getCashOutProfit()} commission={commission} currency={currency} isPLTable />
              )}
              {!cashOutProfit &&
                updateBet.map((item, index) => (
                  <WhatIfValue
                    key={`${index}_${item}`}
                    item={item}
                    isProfitNetOfCommissionChecked={isNetOfCommissionChecked}
                    commission={commission || 0}
                    currency={currency}
                    isFinish={index < updateBet.length - 1}
                    isPLTable
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(NetPLValue);
