import { BETTING_TYPES } from 'constants/app';
import { LINE_MARKET_ODDS } from 'constants/placement';
import { BetTypes, TPrice, TSize } from 'types/bets';
import { TBetslipMarketDefinition } from 'types/betslip';

import { calculateLiability } from './liability';
import { getMarketLiability } from './totalLiability';

export const getLiabilityValue = ({
  marketId,
  selectionId = 0,
  handicap,
  type,
  priceValue,
  sizeValue,
  marketDefinition
}: {
  priceValue: TPrice;
  sizeValue: TSize;
  marketDefinition?: TBetslipMarketDefinition;
  marketId: string;
  selectionId: number;
  handicap?: number;
  type: BetTypes;
}) => {
  const price = marketDefinition?.bettingType === BETTING_TYPES.line ? LINE_MARKET_ODDS : priceValue;

  const betData = {
    marketId,
    selectionId,
    handicap,
    type,
    price,
    size: sizeValue
  };

  return getMarketLiability({
    backs: type === BetTypes.BACK ? [betData] : [],
    lays: type !== BetTypes.BACK ? [{ ...betData, ...{ profit: calculateLiability(price, sizeValue) } }] : [],
    market: marketDefinition
  });
};
