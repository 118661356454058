import { useSelector } from 'react-redux';
import classNames from 'classnames';

import SelectedMarket from 'components/Betslip/components/SelectedMarket';
import { betslipBranding } from 'constants/branding';
import { getSelectedMarkets } from 'redux/modules/betslip/selectors';

import styles from './styles.module.scss';

const SelectedMarkets = () => {
  const selectedMarkets = useSelector(getSelectedMarkets);

  return (
    <div className={classNames(styles.selectedMarkets, betslipBranding.SELECTED_MARKETS)}>
      {selectedMarkets.map(marketId => (
        <SelectedMarket key={`b-market-${marketId}`} marketId={marketId} />
      ))}
    </div>
  );
};

export default SelectedMarkets;
