import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import Pagination from 'components/Pagination';
import { ASIAN_TABS_EARLY_PREFIX } from 'constants/app';
import {
  ASIAN_VIEW_LIST_SIZE,
  AsianViewMarketLink,
  AsianViewSorting,
  AsianViewTimeFilter,
  AsianViewTimeFrame
} from 'constants/asianView';
import useAsianSingleView from 'hooks/useAsianSingleView';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import useLeaguesParam from 'hooks/useLeaguesParam';
import usePagination from 'hooks/usePagination';
import {
  getAsianViewMiddleSectionPageSize,
  getIsDesktopInfiniteScrollEnabled,
  getIsMobileInfiniteScrollEnabled,
  getIsPropertiesLoaded
} from 'redux/modules/appConfigs/selectors';
import { setScrollUpdate } from 'redux/modules/appSettings';
import { fetchAsianViewList } from 'redux/modules/asianView';
import {
  getAreEventCouponsLoaded,
  getAsianViewCompetitionKeys,
  getAsianViewDaysValue,
  getAsianViewLast,
  getAsianViewLoading,
  getAsianViewTotalElements,
  getAsianViewTotalPages,
  getIsMobileAsianView
} from 'redux/modules/asianView/selectors';
import { getLeaguesCurParams, getLeaguesIsLoaded } from 'redux/modules/asianViewLeaguesFilter/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { CookieNames } from 'types';
import { ESortingTypes } from 'types/asianView';

import CompetitionSection from '../CompetitionSection';
import LoadingSkeleton from '../LoadingSkeleton';

import styles from './styles.module.scss';

const OddView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { timeFilter = '', sportId = '', marketLink = '', timeFrame } = useParams();
  const [cookies] = useCookies([CookieNames.ASIAN_VIEW_SORTING_FILTER]);

  const competitionKeys = useSelector(getAsianViewCompetitionKeys);
  const curLeaguesParam = useSelector(getLeaguesCurParams);
  const isDesktopInfiniteScrollEnabled = useSelector(getIsDesktopInfiniteScrollEnabled);
  const isMobileInfiniteScrollEnabled = useSelector(getIsMobileInfiniteScrollEnabled);
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const totalPages = useSelector(getAsianViewTotalPages);
  const totalElements = useSelector(getAsianViewTotalElements);
  const itemsCountOnPage = useSelector(getAsianViewMiddleSectionPageSize);
  const isLast = useSelector(getAsianViewLast);
  const loading = useSelector(getAsianViewLoading);
  const daysValue = useSelector(getAsianViewDaysValue);
  const isLeaguesLoaded = useSelector(getLeaguesIsLoaded);
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const areEventCouponsLoaded = useSelector(getAreEventCouponsLoaded);

  const leagues = useLeaguesParam();
  const { isAsianSingleView } = useAsianSingleView();

  const [page, setPage] = useState(0);

  const isInfiniteScrollEnabled = isMobileAsianView ? isMobileInfiniteScrollEnabled : isDesktopInfiniteScrollEnabled;
  const sortingFilter = (cookies[CookieNames.ASIAN_VIEW_SORTING_FILTER] as ESortingTypes) || ESortingTypes.NORMAL;
  const numberOfCompetitions = competitionKeys.length;
  const isEarlyTab = timeFilter === ASIAN_TABS_EARLY_PREFIX;
  const marketLinkParam = isAsianSingleView ? AsianViewMarketLink.HDP_AND_OU : (marketLink as AsianViewMarketLink);

  const handleFetchAsianViewListPagination = (nextPage: number) => {
    const timeFrameParam = isEarlyTab
      ? { timeFrame: isMobileAsianView ? (timeFrame as AsianViewTimeFrame) ?? AsianViewTimeFrame.ALL : daysValue }
      : {};

    dispatch(
      fetchAsianViewList({
        id: sportId,
        page: nextPage,
        size: itemsCountOnPage,
        payload: {
          marketLink: marketLinkParam as AsianViewMarketLink,
          timeFilter: timeFilter as AsianViewTimeFilter,
          competitions: leagues,
          sections: [],
          sorting: AsianViewSorting[sortingFilter],
          ...timeFrameParam
        },
        isInfiniteScrollEnabled: false
      })
    );

    dispatch(setScrollUpdate({ top: 0, trigger: true, offset: 0 }));
  };

  const {
    page: paginationPage,
    onClickLastPage,
    onClickNextPage,
    onClickPrevPage,
    onClickFirstPage,
    onClickPage,
    changePage
  } = usePagination({
    onChangePage: handleFetchAsianViewListPagination,
    totalPages,
    isPaginationEnabled: !isInfiniteScrollEnabled
  });

  const timeFrameParam = isEarlyTab
    ? { timeFrame: isMobileAsianView ? (timeFrame as AsianViewTimeFrame) ?? AsianViewTimeFrame.ALL : daysValue }
    : {};

  useEffect(() => {
    if (isPropertiesLoaded && isLeaguesLoaded && curLeaguesParam === `${sportId}-${marketLinkParam}-${timeFilter}`) {
      if (isInfiniteScrollEnabled && page !== 0) {
        setPage(0);
      }

      const offsetOrPage = isInfiniteScrollEnabled ? { offset: 0 } : { page: paginationPage };

      dispatch(
        fetchAsianViewList({
          ...offsetOrPage,
          id: sportId,
          size: isInfiniteScrollEnabled ? ASIAN_VIEW_LIST_SIZE : itemsCountOnPage,
          payload: {
            marketLink: marketLinkParam as AsianViewMarketLink,
            timeFilter: timeFilter as AsianViewTimeFilter,
            competitions: leagues,
            sections: [],
            sorting: AsianViewSorting[sortingFilter],
            ...timeFrameParam
          },
          isInfiniteScrollEnabled,
          changePage,
          resetPrev: true
        })
      );
    }
  }, [
    dispatch,
    marketLinkParam,
    timeFilter,
    sortingFilter,
    leagues,
    sportId,
    isPropertiesLoaded,
    timeFrameParam?.timeFrame,
    daysValue,
    isLeaguesLoaded,
    isLoggedIn
  ]);

  useEffect(() => {
    if (page !== 0 && !isLast && isInfiniteScrollEnabled) {
      dispatch(
        fetchAsianViewList({
          id: sportId,
          offset: page * ASIAN_VIEW_LIST_SIZE,
          size: ASIAN_VIEW_LIST_SIZE,
          payload: {
            marketLink: marketLinkParam as AsianViewMarketLink,
            timeFilter: timeFilter as AsianViewTimeFilter,
            competitions: leagues,
            sections: [],
            sorting: AsianViewSorting[sortingFilter],
            ...timeFrameParam
          },
          isInfiniteScrollEnabled: true
        })
      );
    }
  }, [page, isLast]);

  const infiniteScrollCallback = useCallback(() => {
    if (!loading && !isLast && isInfiniteScrollEnabled) {
      setPage(prevPage => prevPage + 1);
    }
  }, [loading, isLast, isInfiniteScrollEnabled]);

  const { lastElementRef } = useInfiniteScroll<HTMLDivElement>({ callback: infiniteScrollCallback });

  if (areEventCouponsLoaded && !loading && (!totalPages || !competitionKeys.length)) {
    return (
      <div className={styles.oddView__emptyContainer}>
        <div className={styles.noData}>{t('inplay.noData')}</div>
      </div>
    );
  }

  return (
    <div
      className={classNames({
        [styles.oddView__loading]: loading
      })}
    >
      <div className={styles.oddView}>
        {competitionKeys.map((competitionKey, index) => {
          return (
            <CompetitionSection
              key={`${competitionKey}_${index}`}
              competitionKey={competitionKey}
              isLast={index === numberOfCompetitions - 1}
            />
          );
        })}
      </div>
      {loading ? <LoadingSkeleton /> : isInfiniteScrollEnabled && <div ref={lastElementRef} />}
      {!isInfiniteScrollEnabled && totalElements > itemsCountOnPage && !loading && totalElements > 0 && (
        <Pagination
          page={paginationPage}
          totalPages={totalPages}
          onClickFirstPage={onClickFirstPage}
          onClickLastPage={onClickLastPage}
          onClickNextPage={onClickNextPage}
          onClickPage={onClickPage}
          onClickPrevPage={onClickPrevPage}
        />
      )}
    </div>
  );
};

export default OddView;
