import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { betslipBranding } from 'constants/branding';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getCurrentBetByOfferId } from 'redux/modules/currentBets/selectors';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { Actions } from 'types/inlinePlacement';
import { getInlinePlacedOfferStatuses } from 'utils/inlinePlacement';

import styles from './styles.module.scss';

type TInlinePlacedBetIconProps = { bet: TInlineSelectedBet };

const InlinePlacedBetIcon = ({ bet }: TInlinePlacedBetIconProps) => {
  const isPNCEnabled = useSelector(getPNCEnabledSetting);

  const currentBet = useSelector(getCurrentBetByOfferId(bet?.placedBet?.offerId || 0));

  const { isFullyMatched, isUnmatched, isPartiallyMatched, isCancelled, isLapsed, isPlacedWithBetterOdds } =
    getInlinePlacedOfferStatuses(currentBet ?? bet.placedBet, isPNCEnabled);

  const isPlacedState = bet.action === Actions.PLACED;
  const isRemovedState = bet.action === Actions.REMOVED;

  const isIconEnabled =
    isFullyMatched || isUnmatched || isCancelled || (isPartiallyMatched && !isPlacedState) || isRemovedState;

  if (!isIconEnabled) {
    return null;
  }

  return (
    <i
      className={classNames(styles.icon, {
        ['biab_custom-icon-success-circle']: isFullyMatched && !isPlacedWithBetterOdds,
        ['fa2 fa2-clock-icon']: isUnmatched || isPartiallyMatched,
        ['biab_custom-icon-warning-circle']: isCancelled || isRemovedState,
        ['biab_custom-icon-success-star-circle']: isPlacedWithBetterOdds,
        [styles.icon__danger]: isUnmatched || isPartiallyMatched,
        [styles.icon__info]: isCancelled || isRemovedState || isLapsed,
        [betslipBranding.CLOCK_ICON]: isUnmatched || isPartiallyMatched,
        [betslipBranding.ERROR]: isUnmatched || isPartiallyMatched,
        [betslipBranding.INFO]: isCancelled || isRemovedState || isLapsed,
        [betslipBranding.CHECKMARK_ICON]: isFullyMatched && !isPlacedWithBetterOdds,
        [betslipBranding.STAR_ICON]: isPlacedWithBetterOdds,
        [betslipBranding.WARNING_ICON]: isCancelled || isRemovedState
      })}
    >
      <span className={classNames('path1', betslipBranding.BG_COLOR)} />
      <span className="path2" />
    </i>
  );
};

export default InlinePlacedBetIcon;
