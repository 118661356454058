import { useSelector } from 'react-redux';

import { getPlacementNotifications } from 'redux/modules/betslip/selectors';
import { getExpiredOfferIds, getStatusesByOfferIds } from 'redux/modules/betsStatuses/selectors';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { getCurrentBetsByOfferIds } from 'redux/modules/currentBets/selectors';
import { getIsPlaceBetsLoading } from 'redux/modules/placement/selectors';

export const usePlacementNotifications = () => {
  const notificationsMap = useSelector(getPlacementNotifications);
  const expiredOfferIds = useSelector(getExpiredOfferIds);
  const isPlaceBetsLoading = useSelector(getIsPlaceBetsLoading);

  const notifications = Object.values(notificationsMap);
  const offerIds = notifications.filter(bet => bet.offerId !== null).map(bet => +(bet.offerId || 0));
  const offers = useSelector(getCurrentBetsByOfferIds(offerIds));
  const betsStatuses = useSelector(getStatusesByOfferIds(offerIds));
  const pendingNotifications = notifications.filter(
    notification =>
      notification.offerId &&
      !notification.error &&
      (!betsStatuses[notification.offerId] || betsStatuses[notification.offerId] === BetsStatusesTypes.PENDING)
  );
  const numberOfErrors = notifications.filter(
    bet => bet.error || (bet.offerId && expiredOfferIds.includes(bet.offerId.toString()))
  ).length;
  const numberOfNotifications = notifications.length;
  const numberOfPendingNotifications = pendingNotifications.length;
  const hasPendingStatuses = numberOfPendingNotifications > 0;
  const hasPending =
    isPlaceBetsLoading ||
    (numberOfPendingNotifications > 0 && numberOfErrors !== numberOfNotifications) ||
    (numberOfErrors === 0 && !hasPendingStatuses && offers.length === 0);

  return {
    notifications,
    betsStatuses,
    numberOfNotifications,
    numberOfPendingNotifications,
    numberOfErrors,
    hasPending,
    hasPendingStatuses,
    offers
  };
};
