import { inlinePlacementBranding as branding } from 'constants/branding';
import { LINE_MARKET_ODDS } from 'constants/placement';
import { TSelectedBet } from 'redux/modules/betslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { BetTypes } from 'types/bets';
import { BettingType } from 'types/markets';

export const getInlinePlacedOfferStatuses = (bet?: TCurrentBet | null, isPNCEnabled = false) => {
  let betStatus = '';
  let betCssClass = '';
  let odds;

  const isBack = bet?.side === BetTypes.BACK;
  const price = +(bet?.price || 0);
  const averagePrice = +(bet?.averagePrice || 0);
  const averagePriceRounded = +(bet?.averagePriceRounded || 0);
  const sizeMatched = +(bet?.sizeMatched || 0);
  const sizeRemaining = +(bet?.sizeRemaining || 0);
  const sizeCancelled = +(bet?.sizeCancelled || 0);
  const sizeLapsed = +(bet?.sizeLapsed || 0);
  const isLineMarket = bet && bet.bettingType === BettingType.LINE;

  switch (true) {
    case sizeMatched === 0 && sizeRemaining > 0:
      betStatus = BetsStatusesTypes.UNMATCHED;
      betCssClass = branding.UNMATCHED;
      odds = bet?.price;
      break;
    case sizeMatched > 0 && sizeRemaining > 0:
      odds = bet?.averagePriceRounded;
      betStatus = BetsStatusesTypes.PARTIALLY_MATCHED;
      betCssClass = branding.PARTIALLY_MATCHED;
      break;
    case sizeMatched > 0 && sizeRemaining === 0:
      betStatus = BetsStatusesTypes.MATCHED;
      betCssClass = branding.MATCHED;
      odds = bet?.averagePriceRounded;
      break;
    case sizeMatched === 0 && sizeRemaining === 0 && sizeCancelled > 0:
      betStatus = BetsStatusesTypes.CANCELLED;
      betCssClass = branding.CANCELLED;
      odds = bet?.price;
      break;
    case sizeMatched === 0 &&
      sizeRemaining === 0 &&
      sizeCancelled === 0 &&
      bet?.offerState === BetsStatusesTypes.MATCHED:
      betStatus = BetsStatusesTypes.MATCHED;
      betCssClass = branding.MATCHED;
      odds = bet?.averagePriceRounded;
      break;
    case sizeMatched === 0 &&
      sizeRemaining === 0 &&
      sizeCancelled === 0 &&
      bet?.offerState === BetsStatusesTypes.PLACED:
      betStatus = BetsStatusesTypes.UNMATCHED;
      betCssClass = branding.UNMATCHED;
      odds = bet?.price;
      break;
    case sizeLapsed > 0 && bet?.offerState === BetsStatusesTypes.LAPSED:
      betStatus = BetsStatusesTypes.LAPSED;
      betCssClass = branding.CANCELLED;
      odds = bet?.price;
      break;
  }

  const isFullyMatched = betStatus === BetsStatusesTypes.MATCHED;
  const isUnmatched = betStatus === BetsStatusesTypes.UNMATCHED;
  const isPartiallyMatched = betStatus === BetsStatusesTypes.PARTIALLY_MATCHED;
  const isCancelled = betStatus === BetsStatusesTypes.CANCELLED;
  const isLapsed = betStatus === BetsStatusesTypes.LAPSED;

  const isPlacedWithBetterOdds =
    isPNCEnabled && isFullyMatched && (isBack ? averagePrice > price : averagePrice < price);

  return {
    isFullyMatched,
    isUnmatched,
    isPartiallyMatched,
    isCancelled,
    isLapsed,
    isPlacedWithBetterOdds,
    betStatus,
    betCssClass,
    odds: isLineMarket ? LINE_MARKET_ODDS : isPlacedWithBetterOdds ? averagePriceRounded : odds
  };
};

export const getMarketInfoFromBet = (bet: TSelectedBet) => {
  return {
    sportId: bet.sportId,
    eventId: bet.eventId,
    marketType: bet.marketType,
    bettingType: bet.bettingType,
    marketName: bet.marketName,
    eventName: bet.eventName,
    gameName: bet.gameName,
    raceName: bet.raceName,
    marketStartTime: bet.marketStartTime,
    eachWayDivisor: bet.eachWayDivisor,
    marketUnit: bet.marketUnit,
    lineRangeInfo: bet.lineRangeInfo,
    priceLadderDescription: bet.priceLadderDescription,
    runners: bet.runners,
    fancyView: bet.fancyView,
    numberOfWinners: bet.numberOfWinners,
    marketId: bet.marketId
  };
};

export const getBetIdentityData = (bet: TInlineSelectedBet) => {
  return { betUuid: bet.betUuid, marketId: bet.marketId, pageBlock: bet.pageBlock };
};
