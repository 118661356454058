import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { CHECK_LIMIT_INTERVAL } from 'constants/responsibleBetting';
import useDevice from 'hooks/useDevice';
import useElementSize from 'hooks/useElementSize';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getBackendPageData } from 'redux/modules/pages/selectors';
import { PagesFromBackend } from 'redux/modules/pages/types';
import { fetchResponsibleBettingSettings } from 'redux/modules/responsibleBetting';
import {
  getCustomerSelfExclusionStartDate,
  getResponsibleBettingSettings
} from 'redux/modules/responsibleBetting/selectors';
import { getSelfExclsuionDisplayDate, getSelfExclusionPageType } from 'utils/responsibleBetting';

import commonStyles from '../styles.module.scss';

const ResponsibleBettingSelfExclusionTemplate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  const data = useSelector(getBackendPageData);
  const rbSettings = useSelector(getResponsibleBettingSettings);
  const customerSelfExclusionEndDate = useSelector(getCustomerSelfExclusionStartDate);
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const headerHeight = useSelector(getElementHeightByName(EElementNames.HEADER_HEIGHT));
  const footerHeight = useSelector(getElementHeightByName(EElementNames.FOOTER_HEIGHT));

  const checkInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  const hasMinHeightStyle = !(isMobile && isIframeEnabled);
  const minHeightStyle = hasMinHeightStyle ? { minHeight: `calc(100vh - ${headerHeight + footerHeight}px)` } : {};

  const date = customerSelfExclusionEndDate
    ? customerSelfExclusionEndDate
    : getSelfExclsuionDisplayDate(
        rbSettings?.rgTools?.selfExclusionEndDate ?? 0,
        rbSettings?.selfExcludedThroughApiUntil ?? 0
      );

  const formattedDate = useLongDateFormat({
    time: date,
    hasYear: true,
    hasTime: true
  });

  const informationText = t(data?.information || '', {
    date: `${isMobile ? `<br/>` : ''}<strong>${formattedDate}</strong>`,
    interpolation: { escapeValue: false }
  });

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height }));
    }
  });

  const checkHasPageChanged = () => {
    const page = getSelfExclusionPageType(
      rbSettings?.rgTools?.selfExclusionEndDate ?? 0,
      !!rbSettings?.rgTools?.selfExclusionEnabled || false,
      rbSettings?.selfExcludedThroughApi || false,
      rbSettings?.selfExcludedThroughApiUntil ?? 0
    );

    if (page !== PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_START) {
      dispatch(fetchResponsibleBettingSettings());
    }
  };

  useEffect(() => {
    if (!checkInterval.current) {
      checkInterval.current = setInterval(checkHasPageChanged, CHECK_LIMIT_INTERVAL);
    }

    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));

      if (checkInterval.current) {
        clearInterval(checkInterval.current);
      }
    };
  }, []);

  return (
    <div
      className={commonStyles.container}
      ref={componentRef}
      style={{
        ...minHeightStyle
      }}
    >
      <div className={commonStyles.content}>
        <div className={commonStyles.iconWrapper}>
          <i className={classNames(commonStyles.icon, commonStyles.icon__locked, 'biab_custom-icon-locked')} />
        </div>
        <h3 className={commonStyles.title}>{data?.name}</h3>
        <div
          className={classNames(commonStyles.descriptionContainer, {
            [commonStyles.descriptionContainer__mobile]: isMobile
          })}
        >
          <p className={commonStyles.description} dangerouslySetInnerHTML={{ __html: unescape(informationText) }} />
        </div>
        <p className={commonStyles.description} dangerouslySetInnerHTML={{ __html: unescape(data?.content ?? '') }} />
      </div>
    </div>
  );
};

export default ResponsibleBettingSelfExclusionTemplate;
