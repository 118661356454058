import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isInteger, unescape } from 'lodash';

import { BETSLIP_LINE_INT_ODDS } from 'constants/betslip';
import { ExchangeTypes } from 'constants/myBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { useMarketUnits } from 'hooks/useMarketUnits';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { TSelectedBet } from 'redux/modules/betslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { getUserAsianViewAutoRefresh } from 'redux/modules/user/selectors';
import { CookieNames } from 'types';
import { BetTypes } from 'types/bets';
import { BettingType } from 'types/markets';
import { getBetNotificationStatusKey } from 'utils/betslip';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

type SingleBetNotificationProps = {
  bet: TSelectedBet | TCurrentBet;
  offer?: TCurrentBet;
  isError: boolean;
  isPlacedSingleWithBetterOdds: boolean;
  setIsPlacedWithBetterOdds: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
};

const SingleBetNotification = ({
  bet,
  offer,
  isError,
  isPlacedSingleWithBetterOdds,
  setIsPlacedWithBetterOdds,
  isLoading
}: SingleBetNotificationProps) => {
  const { t } = useTranslation();
  const [, setCookie] = useCookies();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const autoRefreshIsEnabled = useSelector(getUserAsianViewAutoRefresh);

  const sizeRemaining = (offer && offer.sizeRemaining) || 0;
  const sizeMatched = (offer && offer.sizeMatched) || 0;
  const sizeCancelled = (offer && offer.sizeCancelled) || 0;
  const size = Number(bet.size) || Number(sizeRemaining) || Number(sizeMatched) || Number(sizeCancelled);

  const { formattedAmount: formattedSize } = useFormatCurrency(size, bet.currency, {
    isCheckIndian: true,
    noRounding: true
  });

  const marketUnitTranslated = useMarketUnits((bet?.marketUnit || offer?.marketUnit) ?? 'points');

  const statuses = useMemo(() => {
    const isPartiallyMatchedBet = !isLoading && !!offer?.sizeRemaining && !!offer.sizeMatched;
    const isMatchedBet = !isLoading && !isPartiallyMatchedBet && !!offer?.sizeMatched;
    const isUnmatchedBet = !isLoading && !isMatchedBet && !!offer?.sizeRemaining;
    const isCancelledBet = !isLoading && !!offer?.sizeCancelled;
    return {
      isPlacing: isLoading || (!isLoading && !isError && !offer),
      isMatchedBet,
      isPartiallyMatchedBet,
      isUnmatchedBet,
      isCancelledBet
    };
  }, [isLoading, offer?.sizeCancelled, offer?.sizeMatched, offer?.sizeRemaining]);

  const isMatched = !!offer && (!!offer.offerId || offer.offerState === BetsStatusesTypes.MATCHED);
  const isPlacedBack = isMatched && offer.side === BetTypes.BACK;
  const isLineMarket = (bet.bettingType || offer?.bettingType) === BettingType.LINE;
  const isPlacedWithBetterOdds =
    isPNCEnabled &&
    !isGameBetSlip &&
    isMatched &&
    (isPlacedBack ? offer.averagePrice > offer.price : offer.averagePrice < offer.price);
  let priceToShow = bet.price;

  if (isPlacedWithBetterOdds) {
    priceToShow = offer?.averagePriceRounded;
  } else if (isPNCEnabled && !isGameBetSlip && autoRefreshIsEnabled) {
    priceToShow = (bet as TSelectedBet).actualPrice || offer?.averagePriceRounded;
  }

  const interval = (bet as TSelectedBet)?.lineRangeInfo?.interval || (bet as TCurrentBet).interval || 0;
  const price =
    !isInteger(priceToShow) && isLineMarket && isInteger(interval) ? Math.floor(+(priceToShow || 0)) + 1 : priceToShow;

  const moreInformationText = t(
    statuses.isPartiallyMatchedBet || statuses.isMatchedBet
      ? 'openBets.messages.moreInformation'
      : `marketBetslip.messages.${statuses.isCancelledBet ? 'cancelled' : 'unmatched'}MoreInformation`,
    { rootPath: getEnvironmentRootPath() }
  );

  const messageKey = getBetNotificationStatusKey({
    isError,
    isPNCEnabled,
    isPlacedWithBetterOdds,
    isGameBetSlip,
    ...statuses
  });

  useEffect(() => {
    if (isPlacedWithBetterOdds !== isPlacedSingleWithBetterOdds) {
      setIsPlacedWithBetterOdds(isPlacedWithBetterOdds);
    }
  }, [isPlacedWithBetterOdds]);

  const getSelectionName = () => {
    if (isLineMarket) {
      return `${bet.selectionName} @${BETSLIP_LINE_INT_ODDS}`;
    } else {
      return bet.selectionName;
    }
  };

  return (
    <div>
      <p className={styles.text}>
        {t(`betslip.labels.type.${(bet.type || bet.side)?.toLowerCase()}`)}:{' '}
        <strong className={styles.bold}>{getSelectionName()}</strong> –{' '}
        {isGameBetSlip ? (offer ? bet.eventName : (bet as TSelectedBet).gameName) : bet.marketName}
      </p>
      <p className={styles.text}>
        {isLineMarket && (
          <>
            <strong className={styles.bold}>
              {t('placement.labels.stakeForUnits', { stake: formattedSize, units: `${price} ${marketUnitTranslated}` })}
            </strong>{' '}
          </>
        )}
        {!isLineMarket && (
          <>
            <strong className={styles.bold}>{formattedSize}</strong> @<strong className={styles.bold}>{price}</strong>{' '}
          </>
        )}{' '}
        – {messageKey && t(messageKey)}
      </p>
      {!statuses.isPlacing && !isError && !statuses.isCancelledBet && !statuses.isUnmatchedBet && !!offer && (
        <div
          className={classNames(styles.moreInfo, 'biab_opened-bet-placement-msg')}
          dangerouslySetInnerHTML={{ __html: unescape(moreInformationText) }}
          onClick={() => setCookie(CookieNames.EXCHANGE_TYPE, ExchangeTypes.SportsGames)}
        />
      )}
    </div>
  );
};

export default SingleBetNotification;
