import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  VALIDATION_ERROR_DUTCHING_CALCULATOR_MAX_STAKE,
  VALIDATION_ERROR_DUTCHING_CALCULATOR_MIN_STAKE
} from 'constants/placement';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getCurrencyMaxBetSize, getCurrencyMinBetSize } from 'redux/modules/appConfigs/selectors';
import { setDutchingCalculatorData } from 'redux/modules/betslip';
import {
  getIsMaxStakeMessageBetSlipDutchingCalculator,
  getIsMinStakeMessageBetSlipDutchingCalculator,
  getSelectedBetsByMarketSideType,
  getSelectedMarketInfo
} from 'redux/modules/betslip/selectors';
import { BetTypes } from 'types/bets';
import { getSelectedBetKey } from 'utils/betslip';

import SelectedBet from '../SelectedBet';
import SelectedBetsSubHeader from '../SelectedBetsSubHeader';
import SelectedMarketError from '../SelectedMarketError';

type TSelectedBetsProps = {
  marketId: string;
  type: BetTypes;
};
const SelectedBets = ({ marketId, type }: TSelectedBetsProps) => {
  const selectedBets = useSelector(getSelectedBetsByMarketSideType(marketId, type));
  const marketInfo = useSelector(getSelectedMarketInfo(marketId));
  const isDutchingCalculatorMinStakeMessage = useSelector(
    getIsMinStakeMessageBetSlipDutchingCalculator({ marketId, type })
  );
  const isDutchingCalculatorMaxStakeMessage = useSelector(
    getIsMaxStakeMessageBetSlipDutchingCalculator({ marketId, type })
  );

  if (!selectedBets.length) {
    return null;
  }

  return (
    <>
      {marketInfo && <SelectedBetsSubHeader marketInfo={marketInfo} type={type} />}
      {selectedBets.map(bet => (
        <SelectedBet key={getSelectedBetKey(bet)} bet={bet} />
      ))}
      {isDutchingCalculatorMinStakeMessage && <DutchingCalculatorMinStakeError marketId={marketId} type={type} />}
      {isDutchingCalculatorMaxStakeMessage && <DutchingCalculatorMaxStakeError marketId={marketId} type={type} />}
    </>
  );
};

function DutchingCalculatorMinStakeError({ marketId, type }: { marketId: string; type: BetTypes }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const minBetSize = useSelector(getCurrencyMinBetSize);

  const { noFormattedAmount } = useFormatCurrency(minBetSize, '', {
    noCommas: true,
    isCheckIndian: true,
    noRounding: true,
    ignorePrecision: true
  });

  return (
    <SelectedMarketError
      errorMessage={t(VALIDATION_ERROR_DUTCHING_CALCULATOR_MIN_STAKE, { min_allowed_stake: noFormattedAmount })}
      onClose={() => dispatch(setDutchingCalculatorData({ marketId, type, data: { isMinStakeMessage: false } }))}
    />
  );
}

function DutchingCalculatorMaxStakeError({ marketId, type }: { marketId: string; type: BetTypes }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const maxBetSize = useSelector(getCurrencyMaxBetSize);

  const { noFormattedAmount } = useFormatCurrency(maxBetSize, '', {
    noCommas: true,
    isCheckIndian: true,
    noRounding: true,
    ignorePrecision: true
  });

  return (
    <SelectedMarketError
      errorMessage={t(VALIDATION_ERROR_DUTCHING_CALCULATOR_MAX_STAKE, { max_allowed_stake: noFormattedAmount })}
      onClose={() => dispatch(setDutchingCalculatorData({ marketId, type, data: { isMaxStakeMessage: false } }))}
    />
  );
}

export default SelectedBets;
