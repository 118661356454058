import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CombinedResponsibleBettingTimeUnit } from 'redux/modules/responsibleBetting/type';
import { DropdownItem } from 'types';
import {
  ResponsibleBettingMobileDropdownTypeKeys,
  ResponsibleBettingMobileDropdownTypes
} from 'types/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingMobileDropdownProps {
  data: DropdownItem[];
  onSelectOption: (option: DropdownItem) => void;
  selectedValue: CombinedResponsibleBettingTimeUnit | string;
  centered?: boolean;
  label: string;
  type?: ResponsibleBettingMobileDropdownTypeKeys;
}

const ResponsibleBettingMobileDropdown = ({
  data,
  onSelectOption,
  selectedValue,
  centered = true,
  label,
  type = ResponsibleBettingMobileDropdownTypes.Horizontal
}: ResponsibleBettingMobileDropdownProps) => {
  const { t } = useTranslation();
  const isVertical = type === ResponsibleBettingMobileDropdownTypes.Vertical;
  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>
      <div
        className={classNames(styles.optionsContainer, {
          [styles.optionsContainer__vertical]: isVertical
        })}
      >
        {data.map((option, index) => {
          const isLastElem = data.length === index + 1;
          return (
            <button
              key={option.id}
              onClick={() => onSelectOption(option)}
              className={classNames(styles.option, {
                [styles.option__selected]: selectedValue === option.value,
                [styles.option__vertical]: isVertical,
                [styles.option__centered]: centered,
                [styles.option__last]: isLastElem
              })}
            >
              {option.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ResponsibleBettingMobileDropdown;
