import { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type SelectedBetNotificationProps = {
  message: string | ReactNode;
};

const SelectedBetNotification = ({ message }: SelectedBetNotificationProps) => {
  return (
    <div className={styles.selectedBetNotification}>
      <div className={styles.selectedBetNotification__inner}>
        <i className={classNames('biab_custom-icon-info-circle', styles.selectedBetNotification__icon)} />
        {typeof message === 'string' ? <p className={styles.selectedBetNotification__text}>{message}</p> : message}
      </div>
    </div>
  );
};

export default SelectedBetNotification;
