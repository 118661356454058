import { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { isInteger, unescape } from 'lodash';

import {
  BET_SLIP_UNMATCHED_BET_PROCESS_MESSAGE_TIMEOUT,
  BET_SLIP_UNMATCHED_BET_WAS_NOT_PLACED_MESSAGE_TIMEOUT,
  BETSLIP_LINE_INT_ODDS
} from 'constants/betslip';
import { mobileComponents as branding, mobileIcons } from 'constants/branding';
import {
  MOBILE_PLACEMENT_ERROR_NOTIFICATION_REMOVE_TIME_OUT,
  MOBILE_PLACEMENT_SUCCESS_NOTIFICATION_REMOVE_TIME_OUT,
  MobilePlacementTypes
} from 'constants/inlinePlacement';
import { GAMES_BASE_URL } from 'constants/locations';
import { VALIDATION_ERROR_BET_OUTDATED_LINE, VALIDATION_ERROR_BET_OUTDATED_ODDS } from 'constants/placement';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { useMarketUnits } from 'hooks/useMarketUnits';
import { getBetsStatusesRequestInterval, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { TSelectedBet } from 'redux/modules/betslip/type';
import { fetchBetsStatuses } from 'redux/modules/betsStatuses';
import { getExpiredOfferIds, getPlacedBetStatusByOfferId } from 'redux/modules/betsStatuses/selectors';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import {
  getCancelledBetsActualNumber,
  getCancelledBetsExpectedNumber,
  getIsCancelActionMessage
} from 'redux/modules/cancelActions/selectors';
import { fetchCurrentBets } from 'redux/modules/currentBets';
import { getCurrentBetsByOfferIds, getNewOffersByOldOfferIds } from 'redux/modules/currentBets/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import {
  moveMobileInlineSelectedBetFromRestoreToActive,
  removeMobileInlineSelectedBetToRestore,
  removeMobilePlacementNotificationsByIds,
  removeProcessingMobileBet
} from 'redux/modules/inlinePlacement';
import { getMobilePlacementNotifications } from 'redux/modules/inlinePlacement/selectors';
import { MobilePlacementNotification } from 'redux/modules/inlinePlacement/type';
import { PageBlocks } from 'types';
import { BetTypes } from 'types/bets';
import { MobilePlacementType } from 'types/inlinePlacement';
import { BettingType } from 'types/markets';
import { getIsFullyMatched, getIsPartiallyMatched } from 'utils/betslip';
import { getEnvironmentRootPath } from 'utils/navigation';

import CancelAllBetsMessage from '../CancelAllBetsMessage';

import styles from './MobilePlacementNotifications.module.scss';

const MobilePlacementNotifications = () => {
  const location = useLocation();

  const notificationsMap = useSelector(getMobilePlacementNotifications(location.pathname.includes(GAMES_BASE_URL)));
  const expiredOfferIds = useSelector(getExpiredOfferIds);
  const cancelledBetsActualNumber = useSelector(getCancelledBetsActualNumber);
  const cancelledBetsExpectedNumber = useSelector(getCancelledBetsExpectedNumber);
  const isCancelActionMessage = useSelector(getIsCancelActionMessage);

  const { notifications, offerIds } = useMemo(() => {
    const notificationsValue = Object.values(notificationsMap).sort((a, b) => b.displayOrder - a.displayOrder);
    const offerIdsValue = notificationsValue
      .filter(bet => bet.offerId !== null)
      .map(bet => +(bet.offerId || 0))
      .filter(offerId => !!offerId);

    return { notifications: notificationsValue, offerIds: offerIdsValue };
  }, [notificationsMap]);

  const offers = useSelector(getCurrentBetsByOfferIds(offerIds));
  const newOffers = useSelector(getNewOffersByOldOfferIds(offerIds));

  return createPortal(
    <div className={styles.container}>
      {isCancelActionMessage && (
        <CancelAllBetsMessage numberOfCancelled={cancelledBetsActualNumber} numberOfAll={cancelledBetsExpectedNumber} />
      )}
      {notifications.map(notification => {
        const newOffer = newOffers.find(({ oldOfferId }) => notification.offerId === oldOfferId);
        let offer = newOffer;

        if (!offer) {
          offer = offers.find(({ offerId }) => notification.offerId === offerId);
        }

        return (
          <MobilePlacementNotificationItem
            key={notification.betUuid || notification.offerId}
            notification={notification}
            offer={offer}
            newOffer={newOffer}
            expiredOfferIds={expiredOfferIds}
          />
        );
      })}
    </div>,
    document.getElementById('root')!
  );
};

export default MobilePlacementNotifications;

type MobilePlacementNotificationItemProps = {
  expiredOfferIds: string[];
  notification: MobilePlacementNotification;
  offer?: TCurrentBet;
  newOffer?: TCurrentBet;
};

function MobilePlacementNotificationItem({
  expiredOfferIds,
  offer,
  notification,
  newOffer
}: MobilePlacementNotificationItemProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const betStatusByOfferId = useSelector(getPlacedBetStatusByOfferId(notification.offerId));
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const betsStatusesRequestInterval = useSelector(getBetsStatusesRequestInterval) || '1000';
  const marketUnitTranslated = useMarketUnits((notification?.bet?.marketUnit || offer?.marketUnit) ?? 'points');

  const getBetsStatusesInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const isLoadingRef = useRef<boolean>(true);

  const [isTimeoutError, setIsTimeoutError] = useState(false);
  const [isLongPlacement, setIsLongPlacement] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [oldOffer, setOldOffer] = useState(offer);

  const isGame = notification.pageBlock === PageBlocks.GAME;
  let isLoading = false;

  if (notification.placementType === MobilePlacementTypes.Place) {
    isLoading =
      (notification.error === null && !notification.offerId) ||
      (!!notification.offerId &&
        offer?.offerId !== notification.offerId &&
        !expiredOfferIds.includes(notification.offerId.toString()));
  } else if (notification.placementType === MobilePlacementTypes.Cancel && notification.offerId) {
    isLoading =
      !!notification.offerId &&
      (betStatusByOfferId !== BetsStatusesTypes.CANCELLED || offer?.offerState !== BetsStatusesTypes.CANCELLED);
    isLoading = !!offer && !Number(offer?.sizeRemaining) && !!Number(offer?.sizeCancelled) ? false : isLoading;
  } else if (notification.placementType === MobilePlacementTypes.Edit) {
    isLoading = !newOffer;
  }

  const isError =
    !!notification.error || (!!notification.offerId && expiredOfferIds.includes(notification.offerId.toString()));
  isLoading =
    isError || isTimeoutError || notification.placementType === MobilePlacementTypes.MatchedBySystem
      ? false
      : isLoading;

  if (!isLoading && isLoadingRef.current) {
    isLoadingRef.current = isLoading;
  }

  isLoading = isLoadingRef.current;
  const isFullyMatched = !!offer && getIsFullyMatched(offer);
  const isPartiallyMatched = !!offer && getIsPartiallyMatched(offer);
  const isCancelled = !!offer && !!Number(offer.sizeCancelled) && !isLoading;
  const isUnmatched = !!offer && !!Number(offer.sizeRemaining) && !Number(offer.sizeCancelled);
  const isPlacedWithBetterOdds =
    isPNCEnabled &&
    !isGame &&
    isFullyMatched &&
    offer &&
    (offer.side === BetTypes.BACK ? offer.averagePrice > offer.price : offer.averagePrice < offer.price);
  const bet = oldOffer || offer || notification.bet || null;
  const betType = (bet as TCurrentBet)?.side?.toLowerCase() || (bet as TSelectedBet)?.type?.toLowerCase();
  const isLine = (bet as TCurrentBet)?.bettingType === BettingType.LINE;
  const interval =
    (bet !== null && ((bet as TSelectedBet)?.lineRangeInfo?.interval || (bet as TCurrentBet).interval)) || 0;
  let price = !isInteger(bet?.price) && isLine && isInteger(interval) ? Math.floor(+(bet?.price || 0)) + 1 : bet?.price;

  const sizeRemaining = (offer && offer.sizeRemaining) || 0;
  const sizeMatched = (offer && offer.sizeMatched) || 0;
  const sizeCancelled = (offer && offer.sizeCancelled) || 0;

  let size =
    (notification.placementType === MobilePlacementTypes.Cancel && Number(sizeRemaining)) ||
    (notification.placementType === MobilePlacementTypes.Cancel && Number(sizeCancelled)) ||
    (!!bet && Number(bet?.size)) ||
    Number(sizeRemaining) ||
    Number(sizeMatched) ||
    Number(sizeCancelled);

  if (notification.placementType === MobilePlacementTypes.Edit) {
    if (!newOffer && notification.sizeToShow !== undefined && notification.priceToShow !== undefined) {
      size = Number(notification.sizeToShow);
      price = notification.priceToShow;
    }
  }

  const { formattedAmount: formattedSize } = useFormatCurrency(size, bet?.currency ?? '', {
    isCheckIndian: true,
    noRounding: true
  });

  const isErrorType =
    !isLoading &&
    (isError ||
      (!isFullyMatched && !isCancelled) ||
      (notification.placementType === MobilePlacementTypes.Place && isUnmatched) ||
      (notification.placementType !== MobilePlacementTypes.Cancel && isCancelled));
  const isInfoType = isLoading || (isCancelled && notification.placementType === MobilePlacementTypes.Cancel);
  const isSuccess = !isError && !isLoading;

  const removeNotification = () => {
    if (notification.betUuid) {
      dispatch(removeMobilePlacementNotificationsByIds([notification.betUuid]));
    }
  };

  const handleRemoveNotification = () => {
    if (isLoading) {
      setIsVisible(false);
    } else {
      removeNotification();
    }
  };

  const timeOutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const closeTimeOutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const messageKey = useMemo(
    () =>
      getMessageKey({
        isError,
        isOffer: !!offer,
        placementType: notification.placementType,
        isFullyMatched,
        isPartiallyMatched,
        isLoading,
        isUnmatched,
        isCancelling:
          notification.placementType === MobilePlacementTypes.Cancel &&
          offer?.offerState !== BetsStatusesTypes.CANCELLED &&
          !(!!offer && !Number(offer?.sizeRemaining) && !!Number(offer?.sizeCancelled)) &&
          !notification.error,
        isCancelled,
        isGame,
        isPNCEnabled,
        isPlacedWithBetterOdds
      }),
    [isLoading, isError]
  );

  useEffect(() => {
    if (offer) {
      setOldOffer(offer);
    }
  }, [offer]);

  useEffect(() => {
    if (getBetsStatusesInterval.current) {
      clearInterval(getBetsStatusesInterval.current);
      getBetsStatusesInterval.current = null;
    }

    if (notification.offerId && !notification.error) {
      const fetchBetsStatusesHandler = () => {
        dispatch(
          fetchBetsStatuses({
            offerIds: [notification.offerId as number],
            onSuccess: offersStatuses => {
              const noPendingStatus = Object.values(offersStatuses).every(
                status => status !== BetsStatusesTypes.PENDING
              );

              if (noPendingStatus && getBetsStatusesInterval.current) {
                clearInterval(getBetsStatusesInterval.current);
                getBetsStatusesInterval.current = null;
                dispatch(fetchCurrentBets());
              }
            }
          })
        );
      };

      fetchBetsStatusesHandler();
      getBetsStatusesInterval.current = setInterval(fetchBetsStatusesHandler, parseInt(betsStatusesRequestInterval));
    }
  }, [notification.offerId, notification.error]);

  useEffect(() => {
    if (isLoading) {
      if (!timeOutRef.current) {
        timeOutRef.current = setTimeout(() => {
          setTimeout(() => {
            setIsTimeoutError(true);
            setIsLongPlacement(false);

            if (notification.pageBlock && notification.placementType === MobilePlacementTypes.Place) {
              dispatch(
                moveMobileInlineSelectedBetFromRestoreToActive({
                  pageBlock: notification.pageBlock,
                  betUuid: notification.betUuid,
                  marketId: notification.marketId
                })
              );
            }

            if (timeOutRef.current) {
              clearTimeout(timeOutRef.current);
              timeOutRef.current = null;
            }
          }, BET_SLIP_UNMATCHED_BET_WAS_NOT_PLACED_MESSAGE_TIMEOUT - BET_SLIP_UNMATCHED_BET_PROCESS_MESSAGE_TIMEOUT);
          setIsLongPlacement(true);
        }, BET_SLIP_UNMATCHED_BET_PROCESS_MESSAGE_TIMEOUT);
      }
    } else {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
        timeOutRef.current = null;

        if (isLongPlacement) {
          setIsLongPlacement(false);
        }
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      const { betUuid, pageBlock, marketId, offerId, placementType } = notification;
      const isCancelledState = !!offer && offer?.offerState === BetsStatusesTypes.CANCELLED;
      const isPlacementError = isError || (isPNCEnabled && !isGame && isCancelledState);

      if (pageBlock) {
        if (isPlacementError) {
          dispatch(
            moveMobileInlineSelectedBetFromRestoreToActive({
              pageBlock,
              betUuid,
              marketId
            })
          );
        } else {
          dispatch(removeMobileInlineSelectedBetToRestore({ pageBlock, betUuid, marketId }));
        }
      }

      if (placementType === MobilePlacementTypes.Place) {
        dispatch(removeProcessingMobileBet(betUuid));
      } else if (offerId) {
        dispatch(removeProcessingMobileBet(offerId));
      }

      const timeout =
        isPlacementError || isErrorType
          ? MOBILE_PLACEMENT_ERROR_NOTIFICATION_REMOVE_TIME_OUT
          : MOBILE_PLACEMENT_SUCCESS_NOTIFICATION_REMOVE_TIME_OUT;

      if (closeTimeOutRef.current) {
        clearTimeout(closeTimeOutRef.current);
        closeTimeOutRef.current = null;
      }

      closeTimeOutRef.current = setTimeout(() => {
        removeNotification();
      }, timeout);
    }
  }, [isLoading, isError, offer]);

  const getSelectionName = () => {
    if (isLine) {
      return `${bet?.selectionName} @${BETSLIP_LINE_INT_ODDS}`;
    } else {
      return bet?.selectionName;
    }
  };

  return (
    <div
      className={classNames(styles.notification, branding.NOTIFICATION, {
        [styles.notification__success]: isSuccess && !isCancelled,
        [styles.notification__error]: isErrorType,
        [styles.notification__info]: isInfoType,
        [styles.notification__hidden]: (!isVisible && isLoading) || !betType,
        [branding.SUCCESS]: isSuccess && !isCancelled,
        [branding.INFO]: isInfoType,
        [branding.ERROR]: isErrorType
      })}
    >
      <div className={styles.notification__body}>
        <div className={styles.notification__icon}>
          {isLoading && (
            <i
              className={classNames(
                'fa fa-spinner fa-pulse fa-fw',
                styles.notification__loading,
                mobileIcons.LOCK_ICON
              )}
            />
          )}
          {isError && <i className={classNames('biab_custom-icon-warning-circle', mobileIcons.WARNING_ICON)} />}
          {isSuccess &&
            isFullyMatched &&
            !isCancelled &&
            (isPlacedWithBetterOdds ? (
              <i className={classNames('biab_custom-icon-success-star-circle', mobileIcons.STAR_ICON)}>
                <span className={classNames('path1', mobileIcons.BG_COLOR)} />
                <span className="path2" />
              </i>
            ) : (
              <i className={classNames('biab_custom-icon-success-circle', mobileIcons.CHECKMARK_ICON)} />
            ))}
          {isSuccess && !isFullyMatched && !isCancelled && (
            <i
              className={classNames(
                'fa2 fa2-clock-icon',
                styles.notification__icon__unmatched,
                mobileIcons.CLOCK_ICON,
                mobileIcons.ERROR
              )}
            />
          )}
          {isCancelled && !isError && (
            <>
              {notification.placementType !== MobilePlacementTypes.Cancel ? (
                <i className={classNames('biab_custom-icon-warning-circle', mobileIcons.WARNING_ICON)} />
              ) : (
                <i className={classNames('biab_custom-icon-info-circle', mobileIcons.INFO_ICON)} />
              )}
            </>
          )}
        </div>
        <div>
          <p className={styles.text}>
            {t(`inlinePlacement.labels.${betType}`)}: <strong className={styles.bold}>{getSelectionName()}</strong> –{' '}
            {isGame ? (!!offer ? bet?.eventName : (bet as TSelectedBet)?.gameName) : bet?.marketName}
          </p>
          <p className={styles.text}>
            {isLine && (
              <>
                <span className={styles.bold}>
                  {t('placement.labels.stakeForUnits', {
                    stake: formattedSize,
                    units: `${price} ${marketUnitTranslated}`
                  })}
                </span>
              </>
            )}
            {!isLine && (
              <>
                <span className={styles.bold}>{formattedSize}</span> @<span className={styles.bold}>{price}</span>
              </>
            )}
            {isPNCEnabled && !isGame && isCancelled ? '' : ' - '}
            {isPNCEnabled && !isGame && isCancelled ? ` - ${t('betslip.labels.error')}` : !!messageKey && t(messageKey)}
          </p>
          {isPNCEnabled && !isGame && isCancelled && (
            <p className={styles.notification__errorText}>
              {t(isLine ? VALIDATION_ERROR_BET_OUTDATED_LINE : VALIDATION_ERROR_BET_OUTDATED_ODDS)}
            </p>
          )}
          {!isLoading && (isFullyMatched || isPartiallyMatched) && (
            <div
              className={classNames(styles.moreInfo, 'biab_opened-bet-placement-msg')}
              dangerouslySetInnerHTML={{
                __html: unescape(t('openBets.messages.moreInformation', { rootPath: getEnvironmentRootPath() }))
              }}
            />
          )}
          {notification.error && (
            <p
              className={styles.notification__errorText}
              dangerouslySetInnerHTML={{ __html: unescape(notification.error) }}
            />
          )}
          {isTimeoutError && <p className={styles.notification__errorText}>{t('betslip.labels.notPlaced')}</p>}
        </div>
        <i
          onClick={handleRemoveNotification}
          className={classNames('biab_custom-icon-close', styles.notification__closeIcon)}
        />
      </div>
      {isLongPlacement && isLoading && <p className={styles.timeMsg}>{t('betslip.labels.processingBet')}</p>}
    </div>
  );
}

function getMessageKey({
  isError,
  isPNCEnabled,
  isPlacedWithBetterOdds,
  isGame,
  isCancelled,
  isCancelling,
  isFullyMatched,
  isPartiallyMatched,
  isLoading,
  isUnmatched,
  isOffer,
  placementType
}: {
  isError: boolean;
  isLoading: boolean;
  isFullyMatched: boolean;
  isPartiallyMatched: boolean;
  isUnmatched: boolean;
  isCancelled: boolean;
  isPlacedWithBetterOdds: boolean;
  isCancelling: boolean;
  isPNCEnabled: boolean;
  isGame: boolean;
  isOffer: boolean;
  placementType: MobilePlacementType;
}) {
  switch (true) {
    case isError ||
      (placementType === MobilePlacementTypes.Place &&
        isOffer &&
        !isFullyMatched &&
        !isPartiallyMatched &&
        !isUnmatched &&
        !isCancelled):
      return 'betslip.labels.error';
    case isCancelling:
      return 'betslip.labels.cancellingBet';
    case isCancelled:
      return 'betslip.labels.cancelledBet';
    case isLoading:
      return 'betslip.labels.placing';
    case isPlacedWithBetterOdds:
      return 'betslip.labels.pnc.placedWithBetterOdds';
    case isFullyMatched:
      return isPNCEnabled && !isGame ? 'betslip.labels.pnc.placed' : 'betslip.labels.matchedBet';
    case isPartiallyMatched:
      return 'betslip.labels.partiallyMatchedBet';
    case isUnmatched:
      return 'betslip.labels.unmatchedBet';
    default:
      return 'betslip.labels.placing';
  }
}
