import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { SELF_EXCLUSION_REMINDER_MINUTES } from 'constants/responsibleBetting';
import { CombinedResponsibleBettingTimeUnit } from 'redux/modules/responsibleBetting/type';
import {
  ResponsibleBettingTimeUnits,
  UpdatedResponsibleBettingDisplayedContent,
  UpdatedResponsibleBettingDisplayedContentKeys
} from 'types/responsibleBetting';
import { calculateResponsibleBettingScaleWidth } from 'utils/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingScaleProps {
  availableTimeLimit?: number | null;
  currentLimit: number | null;
  spentLimit: number | null;
  availableLimitValue: string;
  spentLimitValue?: string;
  availableLabel: string;
  spentLabel?: string;
  currentTimeUnit: CombinedResponsibleBettingTimeUnit;
  limitPeriod: string;
  type: UpdatedResponsibleBettingDisplayedContentKeys;
}

const ResponsibleBettingScale = ({
  availableTimeLimit = null,
  currentLimit,
  spentLimit,
  availableLimitValue,
  spentLimitValue = '',
  availableLabel,
  spentLabel = '',
  currentTimeUnit,
  limitPeriod,
  type
}: ResponsibleBettingScaleProps) => {
  const { t } = useTranslation();

  const isTimeLimitScale = type === UpdatedResponsibleBettingDisplayedContent.TimeLimit;
  const isFinalCountdown = availableTimeLimit && availableTimeLimit <= SELF_EXCLUSION_REMINDER_MINUTES;

  const { spent, available } = useMemo(
    () => calculateResponsibleBettingScaleWidth(currentLimit, spentLimit),
    [currentLimit, spentLimit]
  );

  return (
    <div className={styles.container}>
      <div className={styles.limitContainer}>
        <div className={styles.limitItem}>
          <div
            className={classNames(styles.badge, styles.badge__available, {
              [styles.badge__disabled]: isTimeLimitScale && availableTimeLimit !== null && availableTimeLimit <= 0,
              [styles.badge__spent]: isTimeLimitScale && isFinalCountdown
            })}
          />
          <span className={styles.limit}>{availableLimitValue}</span>
          <span className={styles.limitLabel}>{availableLabel}</span>
        </div>
        {!isTimeLimitScale && spentLimit && spentLimit > 0 ? (
          <div className={styles.limitItem}>
            <div className={classNames(styles.badge, styles.badge__spent)} />
            <span className={classNames(styles.limit, styles.limit__spent)}>{spentLimitValue}</span>
            <span className={styles.limitLabel}>{spentLabel}</span>
          </div>
        ) : null}
      </div>
      <div className={styles.scaleContainer}>
        <div
          className={classNames(styles.available, {
            [styles.available__countdown]: isTimeLimitScale && isFinalCountdown
          })}
          style={{ width: `${available}%` }}
        />
        <div
          className={classNames(styles.spent, {
            [styles.spent__disabled]: isTimeLimitScale
          })}
          style={{ width: `${spent}%` }}
        />
      </div>
      {currentTimeUnit !== ResponsibleBettingTimeUnits.DAY && (
        <div className={styles.periodContainer}>
          <span className={styles.period}>{t('responsibleBetting.labels.currentPeriod')}:</span>
          <span className={styles.period}>{limitPeriod}</span>
        </div>
      )}
    </div>
  );
};

export default ResponsibleBettingScale;
