import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { VALIDATION_ERROR_BET_BACK_LESS_LAY } from 'constants/placement';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import {
  removeSelectedBetsByMarket,
  setLiabilityByMarket,
  setSelectedMarketState,
  updateSelectedBetsByMarket
} from 'redux/modules/betslip';
import {
  getBackLessLayError,
  getIsSelectedMarketInPlacementProcess,
  getSelectedBetsListByMarket,
  getSelectedMarketInfo,
  getSelectedMarketState
} from 'redux/modules/betslip/selectors';
import { SelectedMarketState } from 'redux/modules/betslip/type';
import { getBetStatusErrorByOfferIds } from 'redux/modules/betsStatuses/selectors';
import {
  getIsMarketEnabledByMarketPricesId,
  getMarketPricesBettingType,
  getMarketPricesComplete,
  getMarketPricesCurrency,
  getMarketPricesMarketType,
  getMarketPricesRunners,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { removePlacedSelectedMarket } from 'redux/modules/placement';
import {
  getPlacedSelectedMarketErrorMessage,
  getPlacedSelectedMarketOfferIdsUuids
} from 'redux/modules/placement/selectors';
import { MarketStatus } from 'types';
import { BetTypes } from 'types/bets';
import { TBetslipMarket } from 'types/betslip';
import { getLiabilityByMarket } from 'utils/totalLiability';

import CurrencyMessage from '../CurrencyMessage';
import NetProfitLoss from '../NetProfitLoss';
import SelectedBets from '../SelectedBets';
import SelectedMarketError from '../SelectedMarketError';
import SelectedMarketHeader from '../SelectedMarketHeader';
import SelectedMarketNotification from '../SelectedMarketNotification';

import styles from './styles.module.scss';

type TSelectedMarketProps = {
  marketId: string;
};
const SelectedMarket = ({ marketId }: TSelectedMarketProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const marketInfo = useSelector(getSelectedMarketInfo(marketId));
  const backLessLayError = useSelector(getBackLessLayError(marketId));
  const marketType = useSelector(getMarketPricesMarketType(marketId));
  const bettingType = useSelector(getMarketPricesBettingType(marketId));
  const complete = useSelector(getMarketPricesComplete(marketId));
  const runners = useSelector(getMarketPricesRunners(marketId));
  const bets = useSelector(getSelectedBetsListByMarket(marketId));
  const marketStatus = useSelector(getStatusByMarketPricesId(marketId));
  const marketState = useSelector(getSelectedMarketState(marketId));
  const isMarketEnabled = useSelector(getIsMarketEnabledByMarketPricesId(marketId));
  const placedMarketErrorMessage = useSelector(getPlacedSelectedMarketErrorMessage(marketId));
  const placedMarketOfferIdsUuids = useSelector(getPlacedSelectedMarketOfferIdsUuids(marketId));
  const placedBetsStatusErrorMessage = useSelector(
    getBetStatusErrorByOfferIds(Object.values(placedMarketOfferIdsUuids ?? {}))
  );
  const isSelectedMarketInPlacementProcess = useSelector(getIsSelectedMarketInPlacementProcess(marketId));
  const marketCurrency = useSelector(getMarketPricesCurrency(marketId));
  const { isMultiCurrencySupported, isMultiCurrencyChanged } = useMultiCurrencySupporting();

  const isCurrencyChanged = isMultiCurrencyChanged(marketCurrency);
  const isMultiCurrencyMessage = isMultiCurrencySupported && isCurrencyChanged;

  const placedMarketOfferIdsUuidsAmount = Object.keys(placedMarketOfferIdsUuids ?? {}).length;
  const placementErrorMessage =
    placedMarketErrorMessage || (placedMarketOfferIdsUuidsAmount > 1 ? placedBetsStatusErrorMessage : '');

  const onClosePlacedMarketErrorHandler = () => {
    dispatch(removePlacedSelectedMarket({ marketId }));
  };

  const market = {
    marketId,
    marketType,
    commission: marketInfo?.commission,
    marketName: marketInfo?.marketName,
    marketStartTime: marketInfo?.marketStartTime,
    bettingType,
    complete,
    description: {
      marketType,
      bettingType,
      eachWayDivisor: marketInfo?.eachWayDivisor,
      lineRangeInfo: marketInfo?.lineRangeInfo,
      priceLadderDescription: marketInfo?.priceLadderDescription
    },
    eventType: {
      id: marketInfo?.sportId,
      name: marketInfo?.eventName
    },
    runners: marketInfo?.runners,
    eachWayDivisor: marketInfo?.eachWayDivisor ?? null
  } as TBetslipMarket;

  const isClosed = marketStatus === MarketStatus.CLOSED;
  const isClosedState = marketState === SelectedMarketState.CLOSED;

  const liability = getLiabilityByMarket({
    bets: bets.map(bet => {
      return { ...bet, betType: bet.type };
    }),
    market: {
      marketId,
      marketType: marketInfo?.marketType ?? '',
      bettingType: marketInfo?.bettingType ?? '',
      complete,
      runners: (marketInfo?.gameName ? marketInfo?.runners : runners) ?? [],
      eachWayDivisor: marketInfo?.eachWayDivisor ?? null
    }
  });

  const onCloseErrorMessageHandler = () => {
    dispatch(updateSelectedBetsByMarket({ marketId, data: { error: null } }));
  };

  useEffect(() => {
    dispatch(setLiabilityByMarket({ marketId, liability }));
  }, [liability]);

  useEffect(() => {
    // If market is closed or blacklisted
    if (isClosed || (!isUndefined(isMarketEnabled) && !isMarketEnabled)) {
      dispatch(removeSelectedBetsByMarket({ marketId }));
      dispatch(setSelectedMarketState({ marketId, state: SelectedMarketState.CLOSED }));
      dispatch(removePlacedSelectedMarket({ marketId }));
    }
  }, [isClosed, isMarketEnabled]);

  return (
    <div className={classNames({ [styles.hidden]: isSelectedMarketInPlacementProcess })}>
      <SelectedMarketHeader marketId={marketId} />
      {isMultiCurrencyMessage ? (
        <CurrencyMessage marketCurrency={marketCurrency} />
      ) : (
        <>
          {isClosedState && <SelectedMarketNotification />}
          {[BetTypes.BACK, BetTypes.LAY].map(type => (
            <SelectedBets key={`b-${marketId}-${type}`} marketId={marketId} type={type} />
          ))}
          {backLessLayError && (
            <SelectedMarketError
              errorMessage={t(VALIDATION_ERROR_BET_BACK_LESS_LAY)}
              onClose={onCloseErrorMessageHandler}
            />
          )}
          {placementErrorMessage && (
            <SelectedMarketError errorMessage={placementErrorMessage} onClose={onClosePlacedMarketErrorHandler} />
          )}
          {!isClosedState && marketInfo && <NetProfitLoss market={market} />}
        </>
      )}
    </div>
  );
};

export default SelectedMarket;
