import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  COLLAPSIBLE_SIDEBAR_WINDOW_WIDTH,
  LANDSCAPE_MIN_WIDTH,
  MOBILE_MAX_DISPLAY_SIZE,
  SLICES_NAMES
} from 'constants/app';
import { CDN_IMAGE_FEED_PATH } from 'constants/icons';
import { DEFAULT_CELL_WIDTH } from 'constants/marketsTable';
import { FailureActionPayload, ViewBy } from 'types';

import {
  AppConfigs,
  CashOutPopUp,
  CommissionTypes,
  Devices,
  NavigationType,
  TFetchConfigsPayload,
  TFetchPropertiesResponse,
  TProperties,
  TSuccessFetchCurrency
} from './type';

const initialState: AppConfigs = {
  properties: {
    accountId: '',
    language: '',
    version: '',
    brandingCssVersion: '',
    connectionFilePath: 'static/speed_test',
    connectionFileSize: 80,
    connectionInterval: 10000,
    connectionMaxSpeed: 96,
    connectionMinSpeed: 32,
    defaultQuickStakes: { stakes: [], gameStakes: [] },
    homePageColumnsNumber: 1,
    displayCustomerCommissionRange: false,
    hideMarketDepth: true,
    minSearchQueryLength: 0,
    supportedLocales: [],
    reconnectAttempts: 0,
    reconnectTimeout: 10000,
    hideToursFadePeriodMs: 0,
    competitionViewWithFiltersSupportedSportIds: [],
    competitionViewSupportedSportIds: [],
    isCompetitionViewEnabled: false,
    rtoMultiplier: 3,
    marketPricesWSEnabled: true,
    operatorBalanceEnabled: false,
    desktopCashoutPageSize: 20,
    balanceWsEnabled: true,
    eventUpdatesWsEnabled: true,
    generalWsEnabled: true,
    desktopSettings: {
      activeMarkets: false,
      alternativeLayOdds: false,
      anyGameEnabled: false,
      anySportWithLayEnabled: false,
      asianViewCashOutPaginationButtons: false,
      asianViewMiddleSectionPageSize: 20,
      backButtonMobile: false,
      balanceToggle: true,
      collapseSportsBar: false,
      competitionViewSports: [],
      confirmBetsBeforePlace: false,
      connectionChecks: false,
      consolidateBets: false,
      contextualHelp: false,
      defStake: true,
      depositBtn: false,
      displayLocksForRestrictedMarkets: false,
      fancyMarketsOnCricket: false,
      fillOrKill: true,
      footerContent: '',
      footerImages: [],
      footerLinks: [],
      headerNavigation: [],
      howTo: false,
      howToMobile: false,
      infiniteScrollEnabled: false,
      inlineBetting: false,
      langDropdownAuth: false,
      langDropdownNoAuth: false,
      lineMarketsSwitchBackLayOnCricket: false,
      listViewSports: [],
      loginHeaderButtons: true,
      myBetsLinks: [],
      netOfCommission: false,
      netOfCommissionBetslip: false,
      netOfCommissionBetslipDefaultState: false,
      netOfCommissionDefaultState: false,
      pageNotFoundBackGroundId: 0,
      pageNotFoundLinks: [],
      placeBetWithEnterKey: true,
      pwdChangeBtn: false,
      quickstakeBetslip: false,
      replaceBackLayWithUnderOver: false,
      showLabelsAboveInputFields: false,
      showLayOddsGames: false,
      showLayOddsSports: true,
      sportsWithoutLayColumn: [],
      swapColorsFancyMarketsOnCricket: false,
      totalWinnings: false,
      viewLiquidity: true,
      virtualKeyboardBetslip: false,
      webtours: false
    },
    mobileSettings: {
      activeMarkets: false,
      alternativeLayOdds: false,
      anySportWithLayEnabled: false,
      asianViewCashOutPaginationButtons: false,
      asianViewMobileBetListPagination: false,
      asianViewMobileNavigationMenu: false,
      backButtonMobile: false,
      balanceToggle: true,
      classicNavigation: false,
      classicNavigationLinks: [],
      collapseSportsBar: false,
      competitionViewSports: [],
      confirmBetsBeforePlace: false,
      connectionChecks: false,
      consolidateBets: false,
      contextualHelp: false,
      defStake: true,
      depositBtn: false,
      displayLocksForRestrictedMarkets: false,
      fancyMarketsOnCricket: false,
      fillOrKill: true,
      footerContent: '',
      footerImages: [],
      footerLinks: [],
      headerNavigation: [],
      howTo: false,
      howToMobile: false,
      infiniteScrollEnabled: false,
      inlineBetting: false,
      langDropdownAuth: false,
      langDropdownNoAuth: false,
      lineMarketsSwitchBackLayOnCricket: false,
      listViewSports: [],
      loginHeaderButtons: true,
      myBetsLinks: [],
      netOfCommission: false,
      netOfCommissionBetslip: false,
      netOfCommissionBetslipDefaultState: false,
      netOfCommissionDefaultState: false,
      pageNotFoundBackGroundId: 0,
      pageNotFoundLinks: [],
      placeBetWithEnterKey: true,
      positionOfAsianViewMobileNavigationMenu: false,
      pwdChangeBtn: false,
      quickstakeBetslip: false,
      replaceBackLayWithUnderOver: false,
      showLabelsAboveInputFields: false,
      showLayOddsGames: false,
      showLayOddsSports: true,
      sportsWithoutLayColumn: [],
      swapColorsFancyMarketsOnCricket: false,
      swipeMenu: false,
      swipeMenuLinks: [],
      totalWinnings: false,
      viewLiquidity: true,
      virtualKeyboardBetslip: false,
      webtours: false
    },
    displayIconsInSubHeader: false,
    operator: '',
    domain: '',
    newCustomerCookieName: 'BIAB_NEW_CUSTOMER',
    fallbackLang: 'en',
    featuredSports: [],
    displayCurrencySymbol: true,
    currencySymbolAfterAmount: false,
    desktopExtendedMarketCellWidth: DEFAULT_CELL_WIDTH,
    runningBallInterval: 15000,
    marketSuspendedTimeToHideSeconds: 15,
    americanDateFormatEnabled: false,
    pwdLink: {} as NavigationType,
    timezoneCookieEnabled: true,
    sportEnabled: true,
    horseRacingSportPageInterval: 10000,
    cashOutEnabled: false,
    cashOutGetMarketsInterval: 10000,
    cashOutGetStatusInterval: 1000,
    marketOddsBackOnly: false,
    marketOdds: false,
    marketOddsBannerLocation: null,
    masterAccountCommissionType: CommissionTypes.WINNINGS,
    multiCurrencySupported: false,
    replaceCurrencySymbolForMatchedAndTooltips: false,
    pageServingOptions: {
      mobileAccountButtonEnabled: true,
      mobileTimezoneDropdownEnabled: true,
      iframeEnabled: false,
      desktopHeaderHidden: false,
      desktopFooterHidden: false,
      defaultSorting: ViewBy.TIME
    },
    currencySteps: [],
    inPlayDefTabHighlights: false,
    inPlayPaginationSize: 60,
    walletIntegrationType: '',
    homePageType: null,
    isSportIconsEnabled: true,
    countriesWithCustomClothIcon: '',
    betfairCdnImageFeedPath: CDN_IMAGE_FEED_PATH,
    currentBetsRequestInterval: '3000',
    betsStatusesRequestInterval: '1000',
    betslipSpinnerTime: '',
    nextHorseEnabled: false,
    desktopCompetitionPageSize: 60,
    numberOfMarketSelectionsScroll: 20,
    isMarketSelectionsScroll: false,
    minAmountToShowOdds: 0,
    eventViewSettings: [],
    indianNumberSystemEnabled: false,
    mobileMarketCellWidth: 40,
    desktopMarketCellWidth: 50,
    desktopCellWidth: 42,
    desktopExtendedCellWidth: '8%',
    mobileCellWidth: 40,
    customPopUpCloseButtonEnabled: false,
    customPopUpEnabled: false,
    minNavSportIds: [],
    tours: [],
    timeformStatisticsEnabled: false,
    bettingDisplaySettings: {
      id: null,
      domainId: null,
      walletGroupId: null,
      groupsToCopy: null,
      exposureLimitEnabled: false,
      lossLimitEnabled: false,
      selfExclusionEnabled: false,
      reminderEnabled: false,
      reminderInterval: null,
      reminderUnit: null,
      dayLimitEnabled: false,
      timeLimitEnabled: false,
      passiveSessionEnabled: false,
      passiveSessionPositionHeader: false,
      headsUpOverHeader: false,
      lastUpdated: null,
      timeLimitEndNotificationEnabled: false
    },
    headerWarnMessage: '',
    nonAuthLoginNotificationEnabled: false,
    postLoginNotificationEnabled: false,
    tokenTypeCookieName: '',
    hideLiveScoreInHeader: false,
    nonAuthMatchStatisticsEnabled: false,
    matchStatUrl: null,
    isMobileMenuButtonTextEnabled: false,
    asianViewEnabled: false,
    autoCashOutEnabled: false,
    exchangeGamesEnabled: false,
    exchangeSportsEnabled: false,
    currentBetsWsEnabled: false,
    exchangeGamesInterval: 3000,
    asianViewCashOutSportsEnabled: [],
    asianViewLayBettingEnabled: false,
    autoCashOutWsEnabled: false,
    cashOutGetQuotesInterval: 5000,
    cashOutQuoteWsEnabled: false,
    asianViewMobileBetListPaginationSize: 10,
    operatorBettingLimitsEnabled: false,
    bettingLoginModalShown: false
  },
  pages: {
    PAGE_NOT_FOUND: '',
    UNAUTHORIZED: '',
    FORBIDDEN: '',
    AUTO_CASH_OUT: '',
    PARTIAL_CASH_OUT_RULES: '',
    CASH_OUT_RULES: '',
    LAY_BETTING_RULES_PAGE: '',
    RG_TOOLS_SELF_EXCLUSION_END: '',
    RG_TOOLS_SELF_EXCLUSION_START: '',
    RG_TOOLS_TIME_LIMIT_END: ''
  },
  device: window.innerWidth > MOBILE_MAX_DISPLAY_SIZE ? Devices.DESKTOP : Devices.MOBILE,
  isLandscape: window.innerWidth <= MOBILE_MAX_DISPLAY_SIZE && window.innerWidth > LANDSCAPE_MIN_WIDTH,
  timezone: '0',
  splashScreenRefreshTimeout: Number(
    window.environmentConfig?.splashScreenRefreshTimeout || process.env.REACT_APP_SPLASH_SCREEN_REFRESH_TIMEOUT
  ),
  splashScreenContent: '',
  splashScreenEnabled: false,
  currency: {} as TSuccessFetchCurrency,
  currencies: [] as TSuccessFetchCurrency[],
  loading: false,
  error: null,
  propertiesLoading: false,
  cashOutPopUp: {
    content: '',
    name: ''
  },
  cashOutPopUpLoading: false,
  isPropertiesLoaded: false,
  backendContentPage: '',
  brandingCSSLoaded: false,
  currencyLoading: false,
  currenciesLoading: false,
  headerLogoHeight: 0,
  stringifiedCurrency: '',
  isCollapsibleRightSide: window.innerWidth < COLLAPSIBLE_SIDEBAR_WINDOW_WIDTH,
  arePropertiesFromWebSocketLoaded: false
};

const slice = createSlice({
  name: SLICES_NAMES.APP_CONFIGS,
  initialState,
  reducers: {
    fetchAppConfigs: (state, action: PayloadAction<TFetchConfigsPayload>) => {
      state.propertiesLoading = action.payload?.changeLoadingState !== false;
    },
    successGetAppConfigs: (state, action: PayloadAction<TFetchPropertiesResponse>) => {
      const {
        domain,
        competitionViewWithFiltersSupportedSportIds,
        competitionViewSupportedSportIds,
        desktopExtendedMarketCellWidth,
        desktopExtendedCellWidth
      } = action.payload;
      state.properties = action.payload;
      state.properties.domain = domain?.toLowerCase();
      state.properties.competitionViewWithFiltersSupportedSportIds =
        competitionViewWithFiltersSupportedSportIds?.split(',');
      state.properties.competitionViewSupportedSportIds = competitionViewSupportedSportIds?.split(',');
      state.properties.desktopExtendedMarketCellWidth = Number(String(desktopExtendedMarketCellWidth).replace('%', ''));
      state.properties.desktopExtendedCellWidth = Number(String(desktopExtendedCellWidth).replace('%', ''));
      state.propertiesLoading = false;
      state.isPropertiesLoaded = true;
    },
    fetchContentPages: state => {
      state.loading = true;
    },
    successGetContentPages: (state, action: PayloadAction<object>) => {
      state.pages = action.payload as any;
      state.loading = false;
    },
    failureGetData: (state, action: PayloadAction<FailureActionPayload>) => {
      state.error = action.payload;
      state.loading = false;
      state.cashOutPopUpLoading = false;
    },
    setTimezone: (state, action: PayloadAction<string>) => {
      state.timezone = action.payload;
    },
    setSplashScreen: (state, action: PayloadAction<string>) => {
      state.splashScreenContent = action.payload;
      state.splashScreenEnabled = true;
    },
    setUserDevice: (state, action: PayloadAction<Devices>) => {
      state.device = action.payload;
    },
    fetchCurrency: state => {
      state.currencyLoading = true;
    },
    fetchCurrencies: state => {
      state.currenciesLoading = true;
    },
    successGetCurrency: (state, { payload }: PayloadAction<TSuccessFetchCurrency>) => {
      state.currencyLoading = false;

      const stringifiedPayload = JSON.stringify(payload);

      if (state.stringifiedCurrency !== stringifiedPayload) {
        state.currency = payload;
        state.stringifiedCurrency = stringifiedPayload;
      }
    },
    successFetchCurrencies: (state, action: PayloadAction<TSuccessFetchCurrency[]>) => {
      state.currencies = action.payload;
    },
    fetchCashOutPopUp: (state, _: PayloadAction<string>) => {
      state.cashOutPopUpLoading = true;
    },
    successFetchCashOutPopUp: (state, action: PayloadAction<CashOutPopUp>) => {
      state.cashOutPopUpLoading = false;
      state.cashOutPopUp = action.payload;
    },
    setBackendContent: (state, action: PayloadAction<string>) => {
      state.backendContentPage = action.payload;
    },
    setBrandingCSSLoaded: (state, action: PayloadAction<boolean>) => {
      state.brandingCSSLoaded = action.payload;
    },
    failureFetchCurrency: (state, action: PayloadAction<FailureActionPayload>) => {
      state.currencyLoading = false;
      state.error = action.payload;
    },
    failureFetchCurrencies: (state, action: PayloadAction<FailureActionPayload>) => {
      state.currenciesLoading = false;
      state.error = action.payload;
    },
    setHeaderLogoHeight: (state, { payload }: PayloadAction<number>) => {
      state.headerLogoHeight = payload;
    },
    setIsCollapsibleRightSide: (state, { payload }: PayloadAction<boolean>) => {
      state.isCollapsibleRightSide = payload;
    },
    setIsLandscape: (state, { payload }: PayloadAction<boolean>) => {
      state.isLandscape = payload;
    },
    updateProperties: (state, { payload }: PayloadAction<Partial<TProperties>>) => {
      Object.assign(state.properties, payload);

      if (!state.arePropertiesFromWebSocketLoaded) {
        state.arePropertiesFromWebSocketLoaded = true;
      }
    }
  }
});

export const {
  failureGetData,
  successGetAppConfigs,
  fetchAppConfigs,
  successGetCurrency,
  successFetchCurrencies,
  successGetContentPages,
  fetchContentPages,
  setBackendContent,
  successFetchCashOutPopUp,
  fetchCashOutPopUp,
  setSplashScreen,
  setTimezone,
  setUserDevice,
  setBrandingCSSLoaded,
  fetchCurrencies,
  fetchCurrency,
  failureFetchCurrency,
  failureFetchCurrencies,
  setHeaderLogoHeight,
  setIsCollapsibleRightSide,
  setIsLandscape,
  updateProperties
} = slice.actions;

export default slice.reducer;
