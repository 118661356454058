import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import BetsTable from 'components/BetsTable';
import MobileBetDetail from 'components/BetsTable/components/MobileBetDetail';
import Loader, { CircleColors } from 'components/Loader';
import MyBetsSkeleton from 'components/Skeleton/MyBetsSkeleton';
import { MY_BETS_BASE_URL } from 'constants/locations';
import {
  BetStatuses,
  ExchangeTypes,
  MyBetsPage,
  NavigationButtons,
  Period,
  SEARCH_CURRENCY_KEY
} from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import DatePickers from 'pages/MyBetsPages/components/DatePickers/MyBetsDatePickers';
import ExportButton from 'pages/MyBetsPages/components/ExportButton';
import Pagination from 'pages/MyBetsPages/components/Pagination';
import RefreshButton from 'pages/MyBetsPages/components/RefreshButton';
import CancelAllButton from 'pages/MyExchangeBetsPage/components/CancelAllButton';
import Tabs from 'pages/MyExchangeBetsPage/components/Tabs';
import {
  getDesktopSettingsMyBetsLinks,
  getIsIframeEnabled,
  getMobileSettingsMyBetsLinks,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { removeCancelActionStatus, setCancelAllBtnState } from 'redux/modules/cancelActions';
import { getCancelAllBtnState } from 'redux/modules/cancelActions/selectors';
import { setCancelAllUnmatchedBetsActionId, setMultiCurrencyValue } from 'redux/modules/myBets';
import { getMyBets, isShowMobileBetDetails, myBetsLoading } from 'redux/modules/myBets/selectors';
import { successCancelAllBets } from 'redux/modules/placement';
import { CookieNames } from 'types';
import { ECancelAllBtnState } from 'types/betslip';
import { TGetBetAndPageDataParams } from 'types/myBets';
import { getThreeMonthsAgo } from 'utils/date';
import { checkUrlLinkFor, getDefaultLinkTarget } from 'utils/navigation';

import TimeFormatDropdown from './components/TimeFormatDropdown';

import styles from './MyExchangeBetsPage.module.scss';

interface MyExchangeBetsPageProps {
  asianViewEnabled: boolean;
  collectHandlers: (name: 'fetchBetDataHandler', handler: (params: TGetBetAndPageDataParams) => void) => void;
}

const MyExchangeBetsPage = ({ asianViewEnabled, collectHandlers }: MyExchangeBetsPageProps) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const myBetsData = useSelector(getMyBets);
  const isDetailsOpen = useSelector(isShowMobileBetDetails);
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const myBetsLinks = useSelector(getDesktopSettingsMyBetsLinks);
  const linksMobile = useSelector(getMobileSettingsMyBetsLinks);
  const isLoading = useSelector(myBetsLoading);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const cancelAllBtnState = useSelector(getCancelAllBtnState);
  const { isMobile } = useDevice();

  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE]);

  const {
    timezoneOffset,
    bettingDay,
    startDate,
    endDate,
    pageSize,
    pageNumber,
    activeStatusTab,
    handleActiveStatusTab: setActiveStatusTab,
    activePeriodTab,
    getBetData,
    setActivePeriodTab,
    handleSetEndDate,
    handleSetStartDate,
    handleSetPageNumber,
    handleSetPageSize,
    handleSetPageSizeAndNumber,
    handlePeriodDropdown,
    handleResetDates,
    refreshData,
    getExportParams
  } = useMyBetsPagesNavigation(MyBetsPage.ExchangeBets);
  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  collectHandlers('fetchBetDataHandler', getBetData);

  const searchCurrency = searchParams.get(SEARCH_CURRENCY_KEY);
  const { content, totalElements } = myBetsData;
  const showTableBlock = !isDetailsOpen || !isMobile;
  const showBetDetails = isDetailsOpen && isMobile;
  const isCurrentPeriod = activeStatusTab === BetStatuses.Matched || activeStatusTab === BetStatuses.Unmatched;
  const minDate = !isCurrentPeriod ? getThreeMonthsAgo() : undefined;

  const showCancelUnmatchedBetsBtn = activeStatusTab === BetStatuses.Unmatched && content.length > 0;

  const exportData = getExportParams(searchCurrency || undefined);

  const handleSetActivePeriodTab = (period: Period) => {
    setActivePeriodTab(period);

    if (period === Period.Current && !isPNCEnabled) {
      setActiveStatusTab(BetStatuses.Unmatched);
    } else if (period === Period.Current && isPNCEnabled) {
      setActiveStatusTab(BetStatuses.Matched);
    } else {
      setActiveStatusTab(BetStatuses.Settled);
    }
  };

  useEffect(() => {
    if (isMultiCurrencySupported) {
      dispatch(setMultiCurrencyValue(searchCurrency || ''));
    }
  }, [isMultiCurrencySupported]);

  useEffect(() => {
    if (cancelAllBtnState === ECancelAllBtnState.CONFIRM) {
      dispatch(setCancelAllBtnState(ECancelAllBtnState.ACTIVE));
    }

    dispatch(removeCancelActionStatus());

    return () => {
      dispatch(removeCancelActionStatus());
      dispatch(successCancelAllBets(null));
      dispatch(setCancelAllUnmatchedBetsActionId(null));
    };
  }, [startDate, endDate, activeStatusTab, cookies.EXCHANGE_TYPE]);

  return (
    <>
      {!isMobile && asianViewEnabled && cookies.EXCHANGE_TYPE === ExchangeTypes.AsianView && <TimeFormatDropdown />}
      <div
        className={classNames({
          [styles.tabsBlockWrapper]: !isMobile
        })}
      >
        <Tabs
          activeStatusTab={activeStatusTab}
          setActivePeriodTab={handleSetActivePeriodTab}
          setActiveStatusTab={setActiveStatusTab}
          activePeriodTab={activePeriodTab}
          getBetData={getBetData}
        />
        {!isMobile && showCancelUnmatchedBetsBtn && <CancelAllButton />}
      </div>
      {showBetDetails && <MobileBetDetail currentStatus={activeStatusTab} />}
      {!isMobile && (
        <>
          <div className={styles.refreshWrapper}>
            <RefreshButton onRefresh={refreshData} />
          </div>
          <ExportButton data={exportData} isDisabled={totalElements === 0} />
        </>
      )}
      {showTableBlock && (
        <>
          <DatePickers
            timezoneOffset={timezoneOffset}
            bettingDay={bettingDay}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            setStartDate={handleSetStartDate}
            setEndDate={handleSetEndDate}
            handlePeriodDropdown={handlePeriodDropdown}
            handleResetDates={handleResetDates}
            showCancelUnmatchedBetsBtn={showCancelUnmatchedBetsBtn}
            selectedTab={NavigationButtons.MyBets}
          />
          {myBetsLinks &&
            !isMobile &&
            myBetsLinks.map(link => (
              <div key={link.url} className={styles.customLink}>
                <span className={styles.linkDescription}>{link.description}</span>
                <a
                  className={classNames('biab_custom-link', styles.linkDescription__item)}
                  href={link.absolutePath ? link.url : checkUrlLinkFor(link.url)}
                  target={getDefaultLinkTarget({
                    url: link.url,
                    absolutePath: link.absolutePath,
                    isIframeEnabled: isIframeEnabled,
                    currentTarget: link.openInNewTab ? '_blank' : '_self',
                    openInNewTab: link.openInNewTab
                  })}
                >
                  {link.label}
                </a>
              </div>
            ))}
          {linksMobile && isMobile && (
            <div className={classNames('biab_my-bets-custom-link', styles.customLinksMobileHolder)}>
              {linksMobile.map(link => (
                <div key={link.url}>
                  <span className={classNames('biab_custom-link-label', styles.linkDescription)}>
                    {link.description}
                  </span>
                  <a
                    className={classNames('biab_custom-link', styles.linkDescription__item)}
                    href={link.absolutePath ? link.url : checkUrlLinkFor(link.url)}
                    target={getDefaultLinkTarget({
                      url: link.url,
                      absolutePath: link.absolutePath,
                      isIframeEnabled: isIframeEnabled,
                      currentTarget: link.openInNewTab ? '_blank' : '_self',
                      openInNewTab: link.openInNewTab
                    })}
                  >
                    {link.label}
                  </a>
                </div>
              ))}
            </div>
          )}
          {isMobile ? (
            <>
              {isLoading ? (
                <Loader circleColor={CircleColors.BLACK} />
              ) : (
                <div
                  className={classNames('biab_my-bets-table-header', styles.tableContainer, {
                    [styles.tableContainer__mobile]: isMobile
                  })}
                >
                  <BetsTable currentStatus={activeStatusTab} />
                </div>
              )}
            </>
          ) : (
            <div
              className={classNames(styles.skeletonContainer, {
                [styles.skeletonContainer__active]: isLoading
              })}
            >
              {isLoading && <MyBetsSkeleton isShowTable />}
              <BetsTable currentStatus={activeStatusTab} />
            </div>
          )}

          {!isLoading && totalElements > 10 && (
            <Pagination
              source={myBetsData}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={handleSetPageSize}
              setPageNumber={handleSetPageNumber}
              pageLink={`${MY_BETS_BASE_URL}/${activeStatusTab?.toLowerCase()}`}
              setPageSizeAndNumber={handleSetPageSizeAndNumber}
            />
          )}
        </>
      )}
    </>
  );
};

export default MyExchangeBetsPage;
