import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import useDevice from 'hooks/useDevice';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getBackendPageData } from 'redux/modules/pages/selectors';
import { getResponsibleBettingSettings } from 'redux/modules/responsibleBetting/selectors';

import commonStyles from '../styles.module.scss';

const ResponsibleBettingTimeOutTemplate = () => {
  const { t } = useTranslation();

  const { isMobile } = useDevice();

  const data = useSelector(getBackendPageData);
  const rbSettings = useSelector(getResponsibleBettingSettings);

  const timeLimitEndDate = rbSettings?.rgTools?.timeLimitEndDate ?? 0;

  const formattedDate = useLongDateFormat({
    time: timeLimitEndDate,
    hasYear: true,
    hasTime: true,
    hasSeconds: true
  });

  const informationText = t(data?.information || '', {
    date: `${isMobile ? '<br />' : ''}<strong>${formattedDate}</strong>`,
    interpolation: { escapeValue: false }
  });

  return (
    <div
      className={classNames(commonStyles.container, {
        [commonStyles.container__content]: isMobile
      })}
    >
      <div className={commonStyles.content}>
        <div className={commonStyles.iconWrapper}>
          <i className={classNames(commonStyles.icon, commonStyles.icon__locked, 'biab_custom-icon-locked')} />
        </div>
        <h3
          className={classNames(commonStyles.title, {
            [commonStyles.title__mobile]: isMobile
          })}
        >
          {data?.name}
        </h3>
        <div
          className={classNames(commonStyles.descriptionContainer, {
            [commonStyles.descriptionContainer__mobile]: isMobile
          })}
        >
          <p
            className={classNames(commonStyles.description, {
              [commonStyles.description__date]: isMobile
            })}
            dangerouslySetInnerHTML={{ __html: unescape(informationText) }}
          />
        </div>
        <p
          className={classNames(commonStyles.description, {
            [commonStyles.description__mobile]: isMobile
          })}
          dangerouslySetInnerHTML={{ __html: unescape(data?.content ?? '') }}
        />
      </div>
    </div>
  );
};

export default ResponsibleBettingTimeOutTemplate;
