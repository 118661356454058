import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { removeAllGamesSelectedBets } from 'redux/modules/betslip';
import { removeAllGamesOffersByGameId } from 'redux/modules/currentBets';
import { getCurrentGameMarketStatus } from 'redux/modules/games/selectors';
import { EMarketStatus } from 'redux/modules/games/type';
import { cleanInlineSelectedBets } from 'redux/modules/inlinePlacement';

import styles from '../../styles.module.scss';

const MarketStatusOverlay = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const status = useSelector(getCurrentGameMarketStatus);

  useEffect(() => {
    if (
      status === EMarketStatus.SUSPENDED_GAME_PLAYING ||
      status === EMarketStatus.SUSPENDED_GAME_ROUND_OVER ||
      status === EMarketStatus.SUSPENDED_GAME_SETTLING
    ) {
      dispatch(removeAllGamesSelectedBets());
      dispatch(cleanInlineSelectedBets());
      dispatch(removeAllGamesOffersByGameId(params.gameId!));
    }
  }, [status]);

  return <div className={styles.marketStatusOverlay}>{t(`market.status.${status?.toLowerCase()}`)}</div>;
};

export default MarketStatusOverlay;
