import { KeyboardEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { KEY_CODES } from 'constants/betslip';
import { componentsBranding, inlinePlacementBranding as branding } from 'constants/branding';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { useInlineSelectedBetView, useIsDisabledInlineSelectedBet } from 'hooks/useInlinePlacement';
import { useMarketUnits } from 'hooks/useMarketUnits';
import usePostMessage from 'hooks/usePostMessage';
import useTakeOdds from 'hooks/useTakeOdds';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { updateInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { getAccountSettings } from 'redux/modules/user/selectors';
import { BetTypes } from 'types/bets';
import { ButtonActionTypes, Statuses } from 'types/inlinePlacement';
import { isLineBettingType } from 'utils/betslip';
import { getBetIdentityData } from 'utils/inlinePlacement';
import { calculatePayout } from 'utils/liability';

import styles from './styles.module.scss';

type TInlineBetButton = {
  bet: TInlineSelectedBet;
  status?: Statuses;
  buttonType: ButtonActionTypes;
  onClickCallback: () => void;
};

const InlineBetButton = ({ bet, status, buttonType, onClickCallback }: TInlineBetButton) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const { isShortView } = useInlineSelectedBetView(bet);
  const { placeBetWithEnterKey } = useDeviceSettings();
  const { placeBetLogin } = usePostMessage();
  const accountSettings = useSelector(getAccountSettings);
  const isPlaceBetsWithEnterKeyEnabled = placeBetWithEnterKey && accountSettings?.placeBetWithEnterKey;

  const { marketType, bettingType, eachWayDivisor, type, isPriceValid, isSizeValid } = bet;
  const isBack = type === BetTypes.BACK;
  const isLineMarket = isLineBettingType(bettingType ?? '');
  const isDisabled = useIsDisabledInlineSelectedBet(bet);

  const isPlaceBtn = buttonType === ButtonActionTypes.PLACE;
  const isConfirmBtn = buttonType === ButtonActionTypes.CONFIRM;
  const isCancelBtn = buttonType === ButtonActionTypes.CANCEL;
  const isEditBtn = buttonType === ButtonActionTypes.EDIT;
  const isUpdateBtn = buttonType === ButtonActionTypes.UPDATE;
  const isTakeOfferBtn = buttonType === ButtonActionTypes.TAKE_OFFER;
  const isEditUnmatchedBtn = buttonType === ButtonActionTypes.EDIT_UNMATCHED;
  const isCancelUnmatchedBtn = buttonType === ButtonActionTypes.CANCEL_UNMATCHED;

  const isFocused = bet.focusedButton === buttonType;

  const isPlacementInvalid =
    (!isUndefined(isPriceValid) && !isPriceValid) || (!isUndefined(isSizeValid) && !isSizeValid);

  const { takeOdds, profit } = useTakeOdds({ ...bet, betType: bet.type, size: bet.size || '' });

  const isPlacedBet = !!bet.placedBet?.offerId;

  const isDisabledTakeBtn = isTakeOfferBtn && (!takeOdds || takeOdds === bet.price);

  const isDisabledBtn =
    (isPlaceBtn || isConfirmBtn || isCancelUnmatchedBtn || isTakeOfferBtn || isUpdateBtn || isEditUnmatchedBtn) &&
    (isPlacementInvalid || isDisabled || isDisabledTakeBtn);

  const betIdData = getBetIdentityData(bet);

  const marketUnitTranslated = useMarketUnits(bet.marketUnit ?? '');

  const isPrevOddsLower = !!(bet.initPrice && +bet.initPrice < +(bet.price || 0));
  const isLowerPrice = isBack ? isPrevOddsLower : !isPrevOddsLower;

  const isAcceptOdds = isPNCEnabled && bet.initPrice !== bet.price && !isLowerPrice && !isLineMarket;

  let buttonText = '';

  if (isPlaceBtn) {
    buttonText = isPlacedBet
      ? t('inlinePlacement.labels.updateBet')
      : isAcceptOdds
      ? t('placement.labels.acceptOdds')
      : t('inlinePlacement.labels.placeBet');
  } else if (isEditUnmatchedBtn) {
    buttonText = t('inlinePlacement.labels.editBet');
  } else if (isUpdateBtn) {
    buttonText = t('inlinePlacement.labels.updateBet');
  } else if (isTakeOfferBtn) {
    buttonText = isLineMarket
      ? t('inlinePlacement.labels.takeBtnUnits', { units: `${takeOdds || 0} ${marketUnitTranslated}` })
      : t('inlinePlacement.labels.takeBtn', { odds: takeOdds || 0 });
  } else if (isConfirmBtn) {
    buttonText = t('inlinePlacement.labels.confirmBet');
  } else if (isCancelBtn) {
    buttonText = t('inlinePlacement.labels.cancel');
  } else if (isCancelUnmatchedBtn) {
    buttonText = t('inlinePlacement.labels.cancelBet');
  }

  const payout = calculatePayout(bet.price, bet.size, { marketType, bettingType, eachWayDivisor, betType: type });

  const { noFormattedAmount: formattedPayout } = useFormatCurrency(payout || 0, '', {
    noRounding: true,
    isCheckIndian: true
  });

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isConfirmBtn && buttonRef.current !== null) {
      buttonRef.current.focus();
    }
  }, [status]);

  useEffect(() => {
    if (isFocused && buttonRef.current !== null) {
      buttonRef.current.focus();
    }
  }, [isFocused]);

  const keyDownHandler = (e: KeyboardEvent) => {
    if (e.key === KEY_CODES.ENTER && isPlaceBetsWithEnterKeyEnabled) {
      e.preventDefault();
      onClickCallback();
    } else if (e.key === KEY_CODES.TAB) {
      e.preventDefault();

      let focusedButton: ButtonActionTypes | null = null;

      if (isPlaceBtn || isEditUnmatchedBtn || isUpdateBtn) {
        focusedButton = ButtonActionTypes.CANCEL;
      } else if (isConfirmBtn) {
        focusedButton = ButtonActionTypes.EDIT;
      } else if (isEditBtn) {
        focusedButton = ButtonActionTypes.CONFIRM;
      } else if (isCancelUnmatchedBtn) {
        focusedButton = ButtonActionTypes.TAKE_OFFER;
      } else if (isTakeOfferBtn) {
        focusedButton = ButtonActionTypes.EDIT_UNMATCHED;
      }

      dispatch(updateInlineSelectedBet({ ...betIdData, focusedField: null, focusedButton }));
    }
  };

  const clickHandler = () => {
    if (!isLoggedIn && isPlaceBtn) {
      placeBetLogin();
    } else {
      onClickCallback();
    }
  };

  return (
    <button
      type="button"
      ref={buttonRef}
      className={classNames(
        `biab_btn-${buttonType.toLowerCase()}`,
        styles.button,
        styles[`button__${buttonType.toLowerCase()}`],
        branding.PLACEMENT_BUTTON,
        {
          [styles.button__fullWidth]: isShortView,
          [styles.button__disabled]: isDisabledBtn,
          [styles.button__acceptOdds]: isPlaceBtn && isAcceptOdds,
          [branding.PLACE_BTN]: isPlaceBtn,
          [branding.CANCEL_BTN]: isCancelBtn,
          [branding.CONFIRM_BTN]: isConfirmBtn,
          [branding.DISABLED_BTN]: isDisabledBtn,
          [branding.EDIT_BTN]: isEditUnmatchedBtn || isCancelUnmatchedBtn,
          [componentsBranding.PRIMARY_BTN]:
            isPlaceBtn || isAcceptOdds || isEditUnmatchedBtn || isConfirmBtn || isTakeOfferBtn || isUpdateBtn,
          [componentsBranding.SECONDARY_BTN]: isCancelBtn || isCancelUnmatchedBtn
        }
      )}
      onMouseDown={clickHandler}
      onKeyDown={keyDownHandler}
      disabled={isDisabledBtn}
    >
      {buttonText && <span>{buttonText}</span>}
      {(isPlaceBtn || isConfirmBtn || isUpdateBtn) && (
        <span className={styles.button__profit}>
          {t('inlinePlacement.labels.payout')} {formattedPayout}
        </span>
      )}
      {isTakeOfferBtn && (
        <span className={styles.button__profit}>
          {t('betslip.labels.take.profit.' + bet.type.toLowerCase())}: {profit}
        </span>
      )}
      {isEditBtn && <i className={classNames('biab_custom-icon-edit', styles.button__editIcon)} />}
    </button>
  );
};

export default InlineBetButton;
