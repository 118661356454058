import { RefObject, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import InlinePlacement from 'components/InlinePlacement';
import MarketStatusLabel from 'components/MarketStatusLabel';
import MobileOpenBets from 'components/MobileOpenBets';
import MobilePlacement from 'components/MobilePlacement';
import NonRunner from 'components/NonRunner';
import RacingGameTitle from 'components/RacingGameTitle';
import TimeFormRunner from 'components/TimeForm/components/TimeFormRunner';
import WhatIf from 'components/WhatIf';
import { RACING_SPORT } from 'constants/app';
import {
  getAppDevice,
  getCountriesWithCustomClothIcon,
  getTimeformStatisticsEnabled
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getIsCurrentBetsBySelection } from 'redux/modules/currentBets/selectors';
import {
  getInlineSelectedBetBySelection,
  getInlineSelectedBetsBySelection
} from 'redux/modules/inlinePlacement/selectors';
import {
  getMarketPricesId,
  getMarketPricesRunnerStatusBySelectionId,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { MarketStatus, PageBlocks, PlacementPage, SportId } from 'types';
import { MatchTypes } from 'types/bets';
import { IMarket, RowMarketClasses, RunnersStatuses, TMarketRunner } from 'types/markets';
import { RacingGameTitleClasses } from 'types/raceCard';
import { getLinePlusNumber } from 'utils';
import { getMarketTypes } from 'utils/market';

import CellsRow from './components/CellsRow';

import styles from './styles.module.scss';

interface RowMarketProps {
  market: IMarket;
  runner: TMarketRunner;
  pageBlock: PageBlocks;
  classes?: RowMarketClasses;
  isHomePage?: boolean;
  /**
   * Ref to parent container of keyLine for scrolling to center
   */
  keyLineRef?: RefObject<HTMLDivElement>;
  /**
   * Redirect to single market page if inline placement is disabled
   */
  redirectToSingleMarketPage?: boolean;
  /**
   * Whether market depth is enabled
   */
  isDepthEnabled: boolean;
  /**
   * Whether is landscape mode
   */
  isLandscape: boolean;
  racingGameTitleClasses?: RacingGameTitleClasses;
  page: PlacementPage;
  desktopCellsContainerWidth: string;
  mobileCellsContainerWidth: string;
  mobilePlacementContainerClassName?: string;
}

const RowRunner = ({
  market,
  runner,
  pageBlock,
  classes,
  isHomePage = false,
  keyLineRef,
  redirectToSingleMarketPage,
  isDepthEnabled,
  isLandscape,
  racingGameTitleClasses,
  page,
  desktopCellsContainerWidth,
  mobileCellsContainerWidth,
  mobilePlacementContainerClassName
}: RowMarketProps) => {
  const { selectionId, handicap, runnerName, keyLine } = runner;

  const device = useSelector(getAppDevice);
  const timeformStatisticsEnabled = useSelector(getTimeformStatisticsEnabled);
  const countriesWithCustomClothIcon = useSelector(getCountriesWithCustomClothIcon);
  const inlineSelectedBet = useSelector(
    getInlineSelectedBetBySelection(pageBlock, market.marketId, selectionId, handicap)
  );
  const inlineSelectedBets = useSelector(
    getInlineSelectedBetsBySelection(pageBlock, market.marketId, selectionId, handicap)
  );
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const currentRunnerStatus = useSelector(
    getMarketPricesRunnerStatusBySelectionId(market.marketId, runner.selectionId, runner.handicap)
  );
  const marketPricesStatus = useSelector(getStatusByMarketPricesId(market.marketId));
  const marketPricesId = useSelector(getMarketPricesId(market.marketId));
  const isMatchedBetsBySelectionId = useSelector(
    getIsCurrentBetsBySelection({
      type: MatchTypes.MATCHED,
      marketId: market.marketId,
      selectionId,
      handicap
    })
  );
  const isUnmatchedBetsBySelectionId = useSelector(
    getIsCurrentBetsBySelection({
      type: MatchTypes.UNMATCHED,
      marketId: market.marketId,
      selectionId,
      handicap
    })
  );

  const [isBetslipClosing, setIsBetslipClosing] = useState(false);
  const [isTimeFormExpanded, setIsTimeFormExpanded] = useState(false);

  const runnerStatistic = useMemo(
    () => market.timeformStatistics?.runnersStatistics.find(item => item.selectionId === selectionId),
    [market.timeformStatistics?.runnersStatistics, selectionId]
  );

  const isMobile = device === Devices.MOBILE;
  const isMobileOpenBetsVisible =
    isMobile &&
    isLoggedIn &&
    (isBetslipClosing || !inlineSelectedBet) &&
    (isMatchedBetsBySelectionId || isUnmatchedBetsBySelectionId);
  const isRacing = RACING_SPORT[market.eventType.id];
  const { isCombinedTotal, isTotalGoals } = getMarketTypes(
    market.description.marketType,
    market.description.bettingType
  );
  const isMarketClosed = marketPricesStatus === MarketStatus.CLOSED;
  const isTimeformEnabled =
    timeformStatisticsEnabled &&
    !isMarketClosed &&
    market.timeformStatistics &&
    market.eventType.id === SportId.HORSE_RACING;
  const isHideExpandTimeForm = currentRunnerStatus === RunnersStatuses.REMOVED || inlineSelectedBet;

  const getRunnerName = (name: string) => {
    if (!isHomePage && (isTotalGoals || isCombinedTotal) && !isMarketClosed) {
      return name.replace(getLinePlusNumber(handicap, !isTotalGoals), '');
    }

    return name;
  };

  const getRowByRunnerStatus = () => {
    switch (currentRunnerStatus) {
      case RunnersStatuses.REMOVED:
        return (
          <NonRunner
            marketId={market.marketId}
            selectionId={runner.selectionId}
            handicap={runner.handicap}
            isShowTime={isLandscape}
          />
        );
      case RunnersStatuses.ACTIVE:
        return (
          <CellsRow
            market={market}
            runner={runner}
            pageBlock={pageBlock}
            isHomePage={isHomePage}
            containerWidth={isMobile ? mobileCellsContainerWidth : desktopCellsContainerWidth}
            redirectToSingleMarketPage={redirectToSingleMarketPage}
            isDepthEnabled={isDepthEnabled}
            page={page}
          />
        );
      case RunnersStatuses.WINNER || RunnersStatuses.LOSER || RunnersStatuses.PLACED:
        return (
          <MarketStatusLabel
            marketDescription={market.description}
            runnerStatus={currentRunnerStatus}
            containerWidth={desktopCellsContainerWidth}
          />
        );
    }
  };

  useEffect(() => {
    if (inlineSelectedBet) {
      setIsTimeFormExpanded(false);
    }
  }, [inlineSelectedBet]);

  const whatIfClasses = useMemo(() => {
    if (isRacing) {
      return {
        container: classNames(styles.whatIf__space, {
          [styles.whatIf__raice]: countriesWithCustomClothIcon.split(',').includes(market.event.countryCode),
          [styles.whatIf__extraSpace]: isTimeformEnabled,
          [styles.whatIfCashOutRunner]: pageBlock === PageBlocks.CASH_OUT
        })
      };
    }

    return {
      container: classNames(styles.whatIf__space, {
        [styles.whatIfCashOutRunner]: pageBlock === PageBlocks.CASH_OUT
      })
    };
  }, [countriesWithCustomClothIcon, isRacing, isTimeformEnabled, market.event.countryCode, pageBlock]);

  return (
    <>
      <div
        key={selectionId}
        ref={keyLine ? keyLineRef : null}
        className={classNames(
          'runnerRow biab_market-table',
          styles.runnerRow,
          {
            [`${styles.keyLineRunners} biab_key-line`]: isMobile && keyLine,
            [styles.keyLineContainer]: keyLine,
            isNonRunnerWrapper: currentRunnerStatus === RunnersStatuses.REMOVED,
            [classes?.market ?? '']: !isMobile && classes?.market
          },
          classes?.runnerRow ?? ''
        )}
      >
        <div
          className={classNames('runnerName', styles.runnerName, {
            [`${styles.desktopKeyLine} biab_key-line`]: !isMobile && keyLine,
            [styles.runnerName__homePageRow]: isHomePage
          })}
        >
          {isRacing ? (
            <div>
              <div className={styles.runnerName__titleWrapper}>
                {isTimeformEnabled && !isMarketClosed && runnerStatistic && (
                  <span
                    className={classNames('biab_timeform-runner-expand', styles.runnerName__timeForm__expand, {
                      [styles.runnerName__timeForm__nonRunner]: isHideExpandTimeForm,
                      [`biab_expand ${styles.runnerName__timeForm_active}`]: isTimeFormExpanded
                    })}
                    onClick={() => !isHideExpandTimeForm && setIsTimeFormExpanded(!isTimeFormExpanded)}
                  >
                    <i className="fa2 fa2-arrow-down" />
                  </span>
                )}
                <RacingGameTitle
                  runner={runner}
                  isRacing={isRacing}
                  countryCode={market.event.countryCode}
                  classes={racingGameTitleClasses}
                />
              </div>
              {marketPricesId && currentRunnerStatus !== MarketStatus.REMOVED && (
                <WhatIf
                  marketId={market.marketId}
                  commission={market.commission}
                  eachWayDivisor={market.description?.eachWayDivisor}
                  marketType={market.description?.marketType}
                  selectionId={selectionId}
                  handicap={handicap}
                  classes={whatIfClasses}
                />
              )}
            </div>
          ) : (
            <div
              className={classNames(styles.runnerName__container, {
                [styles.cashOutRunnerWrapper]: pageBlock === PageBlocks.CASH_OUT
              })}
            >
              <span className={classNames(styles.runnerName__wrapper, 'biab_game-title')}>
                {getRunnerName(runnerName)}
              </span>
              {marketPricesId && (
                <WhatIf
                  marketId={market.marketId}
                  commission={market.commission}
                  eachWayDivisor={market.description?.eachWayDivisor}
                  marketType={market.description?.marketType}
                  selectionId={selectionId}
                  handicap={handicap}
                  classes={whatIfClasses}
                />
              )}
            </div>
          )}
        </div>

        {getRowByRunnerStatus()}
      </div>
      {isTimeFormExpanded && runnerStatistic && !inlineSelectedBet && (
        <TimeFormRunner runnerStatistic={runnerStatistic} />
      )}
      {!!inlineSelectedBets.length && !isMobile && (
        <InlinePlacement bets={inlineSelectedBets} pageBlock={pageBlock} page={page} />
      )}
      {inlineSelectedBet && isMobile && !isMarketClosed && (
        <MobilePlacement
          bet={inlineSelectedBet}
          pageBlock={pageBlock}
          onClosingBetslip={setIsBetslipClosing}
          page={page}
          containerClassName={mobilePlacementContainerClassName}
        />
      )}
      {isMobileOpenBetsVisible && (
        <MobileOpenBets
          marketId={market.marketId}
          selectionId={selectionId}
          handicap={handicap}
          pageBlock={pageBlock}
          page={page}
          sportId={market.eventType.id}
        />
      )}
    </>
  );
};

export default RowRunner;
