import { createSelector } from '@reduxjs/toolkit';
import { filter, reduce, reverse, sortBy, toNumber } from 'lodash';

import { BETTING_TYPES, EXCHANGE, GAME } from 'constants/app';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { getCurrentGameMarket } from 'redux/modules/games/selectors';
import { AppState } from 'redux/reducers';
import { BetTypes, MatchTypes } from 'types/bets';
import { isCancelled } from 'utils/betslip';
import { isMatchedOffer, isUnmatchedOffer, mapBet } from 'utils/currentBets';

import { ECurrentBetActions, TCurrentBet } from './type';

const getCurrentBets = ({ currentBets }: AppState) => currentBets;

const getCurrentBetsByExchangeType = (isGameType?: boolean) =>
  createSelector(getCurrentBetsByExchangeFiltered(isGameType), getCurrentGameMarket, (offers, gameMarket) =>
    filter(offers, {
      betType: isGameType ? GAME : EXCHANGE,
      ...(isGameType && gameMarket && gameMarket.id ? { marketId: String(gameMarket.id) ?? '' } : {})
    })
  );

const getFilteredCurrentBetsByExchangeType = (
  type: MatchTypes,
  filterMarketId?: string,
  filterEventId?: string,
  isGameType?: boolean
) =>
  createSelector(getCurrentBetsByExchangeFiltered(isGameType), getCurrentGameMarket, (offers, gameMarket) =>
    filter(offers, {
      betType: isGameType ? GAME : EXCHANGE,
      ...(!isGameType && type === MatchTypes.MATCHED && !!filterMarketId ? { marketId: filterMarketId } : {}),
      ...(!isGameType && type === MatchTypes.MATCHED && !!filterEventId ? { eventId: filterEventId } : {}),
      ...(isGameType && !!filterMarketId && gameMarket && gameMarket.id
        ? { marketId: String(gameMarket.id) ?? '' }
        : {})
    })
  );

export const getCurrentBetsByExchangeFiltered = (isGameType?: boolean) =>
  createSelector(getCurrentBets, currentBets => filter(currentBets.offers, { betType: isGameType ? GAME : EXCHANGE }));

export const getLoading = ({ currentBets }: AppState) => currentBets.loading;

export const getOffers = ({ currentBets }: AppState) => currentBets.offers;

export const getCurrentBetsLoading = ({ currentBets }: AppState) => currentBets.loading && currentBets.isFirstLoad;

export const getCurrentBetsAmount = ({ isGameType }: { isGameType?: boolean }) =>
  createSelector(
    getCurrentBetsByType({ type: MatchTypes.UNMATCHED, isGameType }),
    getCurrentBetsByType({ type: MatchTypes.MATCHED, isGameType }),
    (unmatchedOffers, matchedOffers) => unmatchedOffers.length + matchedOffers.length
  );
// Get current bet by offerId
export const getCurrentBetByOfferId =
  (offerId?: number) =>
  ({ currentBets }: AppState) =>
    offerId ? currentBets.offers[offerId] : null;

// Get current bets by old offerId
export const getCurrentBetsByOldOfferId = (oldOfferId: number) =>
  createSelector(getOffers, offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [...res, ...(bet.oldOfferId === oldOfferId ? [mapBet(bet)] : [])];
      },
      []
    )
  );

// Get MATCHED or UNMATCHED offers list by selection
export const getCurrentSelectionBetsByType = (
  marketId: string,
  selectionId: number,
  handicap: number,
  type: MatchTypes
) =>
  createSelector(getOffers, offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [
          ...res,
          ...(bet.marketId === marketId &&
          bet.selectionId === selectionId &&
          bet.handicap == handicap &&
          ((type === MatchTypes.MATCHED && isMatchedOffer(bet)) ||
            (type === MatchTypes.UNMATCHED && isUnmatchedOffer({ bet })))
            ? [mapBet(bet)]
            : [])
        ];
      },
      []
    )
  );

// Get MATCHED or UNMATCHED offers list by event id
export const getCurrentEventBetsByType = (eventId: string, type: MatchTypes) =>
  createSelector(getOffers, offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [
          ...res,
          ...(bet.eventId === eventId &&
          ((type === MatchTypes.MATCHED && isMatchedOffer(bet)) ||
            (type === MatchTypes.UNMATCHED &&
              isUnmatchedOffer({ bet }) &&
              bet.offerState !== BetsStatusesTypes.CANCELLED))
            ? [mapBet(bet)]
            : [])
        ];
      },
      []
    )
  );

export const getIsCurrentEventBetsByType = (eventId: string, type: MatchTypes) =>
  createSelector(getCurrentEventBetsByType(eventId, type), bets => bets.length > 0);

export const getCurrentBetsError = ({ currentBets }: AppState) => currentBets.error;

export const getCurrentBetsString = ({ currentBets }: AppState) => currentBets.currentBetsString;
export const getCurrentBetsList = ({ currentBets }: AppState) => currentBets.currentBetsList;

// Get MATCHED or UNMATCHED offers list by selection
export const getCurrentBetsBySelection = (
  type: MatchTypes,
  marketId: string,
  selectionId?: number,
  handicap?: number
) =>
  createSelector(getOffers, offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet) => {
        const isValidSelection = !selectionId || (selectionId && bet.selectionId === selectionId);
        const isValidHandicap =
          !handicap || (handicap && bet.handicap == handicap) || bet.bettingType === BETTING_TYPES.line;
        const isNotHidden = bet.action !== ECurrentBetActions.HIDDEN;
        const isMatched = type === MatchTypes.MATCHED && isMatchedOffer(bet);
        const isUnmatched =
          type === MatchTypes.UNMATCHED && isUnmatchedOffer({ bet }) && bet.action !== ECurrentBetActions.FULLY_MATCHED;
        const isCancelledBet =
          (bet.action === ECurrentBetActions.CANCELLING || bet.action === ECurrentBetActions.CANCELLING_ALL) &&
          isCancelled(bet);

        if (
          bet.marketId === marketId &&
          isValidSelection &&
          isValidHandicap &&
          isNotHidden &&
          !isCancelledBet &&
          (isMatched || isUnmatched)
        ) {
          return [...res, mapBet(bet)];
        }

        return res;
      },
      []
    )
  );

export const getIsCurrentBetsBySelection = ({
  type,
  marketId,
  selectionId,
  handicap
}: {
  type: MatchTypes;
  marketId: string;
  selectionId?: number;
  handicap?: number;
}) => createSelector(getCurrentBetsBySelection(type, marketId, selectionId, handicap), bets => bets.length > 0);

const getUnmatchedOffersIdsToShowLapsed = ({ currentBets }: AppState) => currentBets.unmatchedOffersIdsToShowLapsed;
// Get MATCHED or UNMATCHED offers list
export const getCurrentBetsByType = ({
  type,
  isGameType = false,
  ignoreCancelled = false,
  anyCancelled = false,
  showLapsed = false,
  ignoreFullyMatchedAction = false
}: {
  type: MatchTypes;
  isGameType?: boolean;
  ignoreCancelled?: boolean;
  anyCancelled?: boolean;
  showLapsed?: boolean;
  ignoreFullyMatchedAction?: boolean;
}) =>
  createSelector(
    getCurrentBetsByExchangeType(isGameType),
    getUnmatchedOffersIdsToShowLapsed,
    (offers, unmatchedOffersIdsToShowLapsed) => {
      return reduce(
        offers,
        (res: TCurrentBet[], bet: TCurrentBet) => {
          const showBetIfCancelled = !ignoreCancelled || bet.offerState !== BetsStatusesTypes.CANCELLED;
          const showBetIfFullyMatchedAction =
            !ignoreFullyMatchedAction || bet.action !== ECurrentBetActions.FULLY_MATCHED;
          const isMatchedBet = type === MatchTypes.MATCHED && isMatchedOffer(bet);
          const isUnmatchedBet =
            type === MatchTypes.UNMATCHED &&
            isUnmatchedOffer({ bet, anyCancelled, showLapsed, unmatchedOffersIdsToShowLapsed });

          if (showBetIfCancelled && showBetIfFullyMatchedAction && (isMatchedBet || isUnmatchedBet)) {
            return [...res, mapBet(bet)];
          }

          return res;
        },
        []
      );
    }
  );

export const getCurrentBetsLengthByType = (
  params:
    | {
        type: MatchTypes;
        isGameType?: boolean;
        ignoreCancelled?: boolean;
        anyCancelled?: boolean;
        showLapsed?: boolean;
        ignoreFullyMatchedAction?: boolean;
      }
    | undefined
) => {
  if (params === undefined) {
    return () => 0;
  }

  const {
    type,
    isGameType = false,
    ignoreCancelled = false,
    anyCancelled = false,
    showLapsed = false,
    ignoreFullyMatchedAction
  } = params;

  return createSelector(
    getCurrentBetsByType({ type, isGameType, ignoreCancelled, anyCancelled, showLapsed, ignoreFullyMatchedAction }),
    bets => bets.length
  );
};

export const getCurrentBetsByOfferIds = (offerIds: number[]) =>
  createSelector(getOffers, offers => Object.values(offers).filter(offer => offerIds.includes(offer.offerId)));
export const getUpdatedOffers = ({ currentBets }: AppState) => currentBets.updatedOffers;
export const getNewOffersByOldOfferIds = (offerIds: number[]) =>
  createSelector(getOffers, offers => Object.values(offers).filter(offer => offerIds.includes(offer.oldOfferId)));

export const getIsAllUnmatchedBetsCancellingOrEditing = (isGameType: boolean) =>
  createSelector(getCurrentBetsByType({ type: MatchTypes.UNMATCHED, isGameType }), unmatchedBets => {
    return unmatchedBets.every(
      ({ action }) => action === ECurrentBetActions.CANCELLING || action === ECurrentBetActions.EDITING
    );
  });

// Get offers by BACK/LAY side and MATCHED/UNMATCHED type
export const getCurrentBetsBySideType = (
  side: BetTypes,
  type: MatchTypes,
  filterMarketId?: string,
  filterEventId?: string,
  isConsolidateBets = false,
  isGameType?: boolean
) =>
  createSelector(getFilteredCurrentBetsByExchangeType(type, filterMarketId, filterEventId, isGameType), offers => {
    const openedBets = reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [...res, ...(bet.side === side ? [mapBet(bet)] : [])];
      },
      []
    ).filter(bet => {
      return type === MatchTypes.MATCHED ? isMatchedOffer(bet) : isUnmatchedOffer({ bet });
    });

    if (type === MatchTypes.MATCHED && isConsolidateBets && !isGameType) {
      return mapConsolidateBets(openedBets);
    }

    return openedBets;
  });

export const getCurrentBetsLengthBySideType = (
  side: BetTypes,
  type: MatchTypes,
  filterMarketId?: string,
  filterEventId?: string,
  isConsolidateBets = false,
  isGameType?: boolean
) =>
  createSelector(
    getCurrentBetsBySideType(side, type, filterMarketId, filterEventId, isConsolidateBets, isGameType),
    bets => bets.length
  );

// Get offers sorted by placedDate
export const getSortedCurrentBets = (isGameType?: boolean) =>
  createSelector(getCurrentBetsByExchangeType(isGameType), offers =>
    reduce(
      reverse(sortBy(offers, 'placedDate')),
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [
          ...res,
          ...(isUnmatchedOffer({ bet }) ? [{ ...mapBet(bet), matchType: MatchTypes.UNMATCHED }] : []),
          // if bet is partially matched show both parts
          ...(isMatchedOffer(bet) ? [{ ...mapBet(bet), matchType: MatchTypes.MATCHED }] : [])
        ];
      },
      []
    )
  );

export const getSortedMatchedCurrentBets = ({
  consolidateBets,
  isGameType,
  filterEventId,
  filterMarketId
}: {
  consolidateBets: boolean;
  isGameType?: boolean;
  filterMarketId?: string;
  filterEventId?: string;
}) =>
  createSelector(getCurrentBetsByExchangeType(isGameType), offers => {
    let filteredOffers = offers;

    if (filterMarketId) {
      filteredOffers = filter(offers, { marketId: filterMarketId });
    } else if (filterEventId) {
      filteredOffers = filter(offers, { eventId: filterEventId });
    }

    const sortedBets = reduce(
      reverse(sortBy(filteredOffers, 'placedDate')),
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [...res, ...(isMatchedOffer(bet) ? [{ ...mapBet(bet), matchType: MatchTypes.MATCHED }] : [])];
      },
      []
    );

    if (consolidateBets && !isGameType) {
      return mapConsolidateBets(sortedBets);
    }

    return sortedBets;
  });

// Get offers count
export const getCurrentBetsCount = createSelector(getOffers, offers => Object.keys(offers).length);

/**
 * Combine bets by marketId, selectionId, handicap, averagePrice (or price).
 * Calculate sum of size, profit and liability fields.
 */
const mapConsolidateBets = (openedBets: TCurrentBet[]) => {
  const groupedBets = reduce(
    openedBets,
    (res: Record<string, TCurrentBet[]>, item) => {
      const key = `${item.marketId}-${item.selectionId}-${toNumber(item.handicap || 0)}-${item.side}`;
      const avPriceKey = `${key}-${item.averagePrice}`;
      const priceKey = `${key}-${item.price}`;

      return {
        ...res,
        ...(res[avPriceKey]
          ? { [avPriceKey]: [...res[avPriceKey], item] }
          : res[priceKey]
          ? { [priceKey]: [...res[priceKey], item] }
          : { [avPriceKey]: [item] })
      };
    },
    {}
  );

  return reduce(
    groupedBets,
    (res: TCurrentBet[], betsList) => {
      return [
        ...res,
        ...(betsList[0]
          ? [
              {
                ...betsList[0],
                ...{
                  isCombined: true,
                  averagePrice: betsList[0].averagePriceRounded,
                  ...[
                    'liability',
                    'profit',
                    'profitNet',
                    'potentialProfit',
                    'size',
                    'sizeMatched',
                    'sizePlaced',
                    'totalWinnings'
                  ].reduce((calculatedFields, field) => {
                    return {
                      ...calculatedFields,
                      ...{
                        [field]: betsList.reduce((totalValue: number, value: TCurrentBet) => {
                          return totalValue + toNumber(value[field as keyof TCurrentBet] || 0);
                        }, 0)
                      }
                    };
                  }, {})
                }
              }
            ]
          : [])
      ];
    },
    []
  );
};

export const isMarketHasOffers = (marketId?: string) =>
  createSelector(getOffers, offers =>
    marketId ? !!Object.values(offers).find(offer => offer.marketId === marketId) : null
  );

export const getCurrentSelectionBetsByTypeForWhatIf = (marketId: string, handicap: number, type: MatchTypes) =>
  createSelector(getOffers, offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        if (
          bet.marketId === marketId &&
          bet.handicap == handicap &&
          type === MatchTypes.UNMATCHED &&
          isUnmatchedOffer({ bet }) &&
          (bet.changedSize || bet.changedPrice)
        ) {
          res.push(bet);
        }

        return res;
      },
      []
    )
  );

export const getPlacedCashOut = (offerId: number | null) => (state: AppState) =>
  offerId ? state.currentBets.offers[offerId] : null;

export const getCurrentBetsBySideTypeLengthByMarketId = (
  marketId: string,
  side: BetTypes,
  type: MatchTypes,
  filterMarketId?: string,
  filterEventId?: string,
  isConsolidateBets = false,
  isGameType?: boolean
) =>
  createSelector(
    getCurrentBetsBySideType(side, type, filterMarketId, filterEventId, isConsolidateBets, isGameType),
    bets => bets.filter(bet => bet.marketId === marketId).length
  );

export const getAllMatchedCurrentBetsByEventId = (eventId: string | null, isGameType?: boolean) =>
  createSelector(getCurrentBetsByType({ type: MatchTypes.MATCHED, isGameType }), bets => {
    return eventId ? bets.filter(bet => bet.eventId === eventId).length : 0;
  });

export const getAreCurrentBetsLoaded = ({ currentBets }: AppState) => currentBets.areCurrentBetsLoaded;

export const getIsAllCurrentBetsCancelling = (curMarketId?: string, isGameType?: boolean) =>
  createSelector(getCurrentBetsByType({ type: MatchTypes.UNMATCHED, isGameType, ignoreCancelled: true }), offers =>
    offers.some(
      ({ marketId, action }) =>
        (!curMarketId || marketId === curMarketId) && action === ECurrentBetActions.CANCELLING_ALL
    )
  );

export const getIsAtLeastOneCanBeRemovedCurrentBet = createSelector(getOffers, offers =>
  Object.values(offers).some(bet => bet.canBeRemoved)
);
