import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import ResponsibleBettingConfirmLimit from 'components/ResponsibleBetting/components/ResponsibleBettingConfirmLimit';
import ResponsibleBettingScale from 'components/ResponsibleBetting/components/ResponsibleBettingScale';
import commonStyles from 'components/ResponsibleBetting/styles.module.scss';
import useDevice from 'hooks/useDevice';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getCurrency, getDisplayCurrencySymbol } from 'redux/modules/appConfigs/selectors';
import { confirmResponsibleBettingLossLimit } from 'redux/modules/responsibleBetting';
import { getResponsibleBettingSettings } from 'redux/modules/responsibleBetting/selectors';
import {
  ResponsibleBettingDisplayedContent,
  UpdatedResponsibleBettingDisplayedContent
} from 'types/responsibleBetting';
import { getTimeUnitKey } from 'utils/responsibleBetting';

interface ResponsibleBettingLossLimitViewProps {
  onEdit: () => void;
  onRemove: () => void;
}

const ResponsibleBettingLossLimitView = ({ onEdit, onRemove }: ResponsibleBettingLossLimitViewProps) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const dispatch = useDispatch();

  const displayCurrencySymbol = useSelector(getDisplayCurrencySymbol);
  const defaultCurrency = useSelector(getCurrency);
  const currencySymbol = displayCurrencySymbol ? defaultCurrency?.symbol : '';

  const rbSettings = useSelector(getResponsibleBettingSettings);

  const {
    lossLimit = 0,
    lossLimitAvailableAmount = 0,
    lossLimitTimeUnit = null,
    lossLimitSpentAmount = 0,
    newLossLimitStartDate = null,
    lossLimitStartDate = null,
    lossLimitEndDate = null,
    newLossLimitEnabled = false,
    newLossLimitTimeUnit = null,
    newLossLimitConfirmationDate,
    newLossLimit = 0
  } = rbSettings?.rgTools || {};
  const operatorLimit = rbSettings?.operatorsLossLimit;

  const { formattedAmount: formattedCurrentLimit } = useFormatCurrency(lossLimit || 0, defaultCurrency?.symbol, {
    isCheckIndian: true,
    noRounding: false,
    ignoreFlexibleDisplayFormat: true
  });
  const { formattedAmount: formattedAvailableLimit } = useFormatCurrency(
    lossLimitAvailableAmount || 0,
    currencySymbol,
    {
      isCheckIndian: true,
      noRounding: true,
      ignoreFlexibleDisplayFormat: true
    }
  );
  const { formattedAmount: formattedSpentLimit } = useFormatCurrency(lossLimitSpentAmount || 0, currencySymbol, {
    isCheckIndian: true,
    noRounding: true,
    ignoreFlexibleDisplayFormat: true
  });
  const { formattedAmount: formattedNewLimit } = useFormatCurrency(newLossLimit || 0, defaultCurrency?.symbol, {
    isCheckIndian: true,
    noRounding: false,
    ignoreFlexibleDisplayFormat: true
  });
  const formattedLossLimitStartDate = useLongDateFormat({
    time: lossLimitStartDate ?? 0,
    hasYear: true,
    hasTime: false,
    hasComma: true
  });
  const formattedNewLossLimitStartDate = useLongDateFormat({
    time: newLossLimitStartDate ?? 0,
    hasYear: true,
    hasTime: false,
    hasComma: true
  });

  const formattedNewLossLimitEndDate = useLongDateFormat({
    time: lossLimitEndDate ?? 0,
    hasYear: true,
    hasTime: false,
    hasComma: true
  });
  const formattedNewLossLimitConfirmationDate = useLongDateFormat({
    time: newLossLimitConfirmationDate ?? 0,
    hasYear: true,
    hasComma: true
  });

  const formattedLossLimitPeriod = `${formattedLossLimitStartDate} - ${formattedNewLossLimitEndDate}`;
  const isAvailableToConfirm = !!(newLossLimitConfirmationDate && new Date().getTime() > newLossLimitConfirmationDate);

  let confirmationLimitTypeLabel = 'responsibleBetting.labels.newLimit';
  let confirmationLimitTypeIcon = 'fa2-success-icon';

  if (newLossLimitConfirmationDate) {
    confirmationLimitTypeLabel = 'responsibleBetting.labels.pending';
    confirmationLimitTypeIcon = 'fa2-clock-icon';
  }
  if (isAvailableToConfirm) {
    confirmationLimitTypeLabel = 'responsibleBetting.labels.toConfirm';
    confirmationLimitTypeIcon = 'fa2-info-icon';
  }

  const confirmationLimitTypeValue = newLossLimitEnabled
    ? `${formattedNewLimit} ${t(getTimeUnitKey(newLossLimitTimeUnit))}`
    : t('responsibleBetting.labels.limitRemoved');

  const confirmationLimitDateLabel =
    newLossLimitConfirmationDate && !isAvailableToConfirm
      ? t('responsibleBetting.labels.cooldown')
      : t('responsibleBetting.labels.starting');

  const confirmationLimitDateValue = newLossLimitStartDate
    ? formattedNewLossLimitStartDate
    : isAvailableToConfirm
    ? formattedNewLossLimitStartDate
    : `${t('responsibleBetting.labels.until')} ${formattedNewLossLimitConfirmationDate}`;

  const handleOnCancel = () => {
    dispatch(
      confirmResponsibleBettingLossLimit({
        confirmed: false,
        type: ResponsibleBettingDisplayedContent.LossLimit
      })
    );
  };

  const handleOnConfirm = () => {
    dispatch(
      confirmResponsibleBettingLossLimit({
        confirmed: true,
        type: ResponsibleBettingDisplayedContent.LossLimit
      })
    );
  };

  return (
    <div
      className={classNames(commonStyles.lossLimitContainer, {
        [commonStyles.lossLimitContainer__mobile]: isMobile
      })}
    >
      <ResponsibleBettingScale
        currentLimit={lossLimit}
        availableLimitValue={formattedAvailableLimit}
        spentLimitValue={formattedSpentLimit}
        spentLimit={lossLimitSpentAmount}
        availableLabel={t('responsibleBetting.labels.available')}
        spentLabel={t('responsibleBetting.labels.spent')}
        currentTimeUnit={lossLimitTimeUnit}
        limitPeriod={formattedLossLimitPeriod}
        type={UpdatedResponsibleBettingDisplayedContent.LossLimit}
      />
      <div
        className={classNames(commonStyles.lossLimitControllsContainer, {
          [commonStyles.lossLimitControllsContainer__mobile]: isMobile
        })}
      >
        <div className={commonStyles.limitView__container}>
          <div
            className={classNames(commonStyles.limitView__wrapper, {
              [commonStyles.limitView__wrapper__mobile]: isMobile
            })}
          >
            <span
              className={classNames(commonStyles.limitView__label, {
                [commonStyles.limitView__label__mobile]: isMobile
              })}
            >
              {t('responsibleBetting.labels.currentLimit')}:
            </span>
            <span className={commonStyles.limitView__value}>{`${formattedCurrentLimit} ${t(
              getTimeUnitKey(lossLimitTimeUnit)
            )}`}</span>
          </div>
          <div className={commonStyles.limitView__controls}>
            {newLossLimitEnabled !== false && (
              <button className={commonStyles.limitView__controlIcon} onClick={onRemove}>
                <i className="fa2 fa2-trash-icon biab_fav-manage-icon" />
              </button>
            )}
            <button className={commonStyles.limitView__controlIcon} onClick={onEdit}>
              <i className="fa2 fa2-edit-icon biab_fav-manage-icon" />
            </button>
          </div>
        </div>
        {newLossLimitEnabled !== null && (
          <>
            <div className={classNames(commonStyles.divider, commonStyles.limitView__divider)} />
            <ResponsibleBettingConfirmLimit
              isMobile={isMobile}
              onCancel={handleOnCancel}
              onConfirm={handleOnConfirm}
              confirmationLimitTypeLabel={t(confirmationLimitTypeLabel)}
              confirmationLimitTypeIcon={confirmationLimitTypeIcon}
              confirmationLimitTypeValue={confirmationLimitTypeValue}
              confirmationLimitDateLabel={confirmationLimitDateLabel}
              confirmationLimitDateValue={confirmationLimitDateValue}
              operatorsLossLimit={operatorLimit}
              showConfirmationBtn={isAvailableToConfirm}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ResponsibleBettingLossLimitView;
