import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { INLINE_PLACEMENT_AVAILABLE } from 'constants/inlinePlacement';
import { SPORT_BASE_URL } from 'constants/locations';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useInlinePlacement from 'hooks/useInlinePlacement';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import useOddsDisplayFormat from 'hooks/useOddsDisplayFormat';
import { getAppDevice, getIsHideMarketDepth, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { removeAllSportsSelectedBets, setActiveTab, setSelectedBets } from 'redux/modules/betslip';
import { getIsBetSelected } from 'redux/modules/betslip/selectors';
import { EBetslipTabs } from 'redux/modules/betslip/type';
import {
  cleanInlineSelectedBets,
  removeInlineSelectedBet,
  setInlineSelectedBet,
  updateInlineSelectedBet
} from 'redux/modules/inlinePlacement';
import {
  getInlineSelectedBet,
  getInlineSelectedBetBeforePlacement,
  getIsInlineSelectedBetByMarket,
  getIsMobilePlacementConfirmationStep
} from 'redux/modules/inlinePlacement/selectors';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import {
  getBetAmount,
  getMarketPricesCurrencyById,
  getMarketPricesRunnerLockedBySelectionId
} from 'redux/modules/marketsPrices/selectors';
import { getContextualHelp } from 'redux/modules/tooltip/selectors';
import {
  BetContentCellClasses,
  BetContentCellStyles,
  PageBlocks,
  PlacementPage,
  PopularEventType,
  SportId
} from 'types';
import { BetDatabaseName, BetTypes } from 'types/bets';
import { Actions } from 'types/inlinePlacement';
import { BettingType, IMarket, IMarketStatusSettings, TMarketRunner } from 'types/markets';
import { getIsOddsChanged, highlightBet } from 'utils/market';
import { getPricesByMarketType } from 'utils/price';

import styles from './styles.module.scss';

export interface BetContentCellProps {
  /**
   * Should the lay cell be hidden or not
   */
  isLayColumnHidden: boolean;

  /**
   * Show bet cells for mobile
   */
  mobileBettingMode?: boolean;

  /**
   * Bet type - can be 'back' or 'lay'
   */
  betType: BetTypes;

  /**
   * Place where component was added (Home, Market odds, Competition, Event)
   */
  pageBlock?: PageBlocks;

  /**
   * Data for the market (marketId, runners, event data, startTime, etc.)
   */
  market: IMarket;
  /**
   * Data for market runner
   */
  runner: TMarketRunner | null;

  /**
   * Not the best price to show
   */
  isDeepPrice?: boolean;

  /**
   * Index of price in bet prices list (there are only 3 items in bet prices (bdatb or bdatl) list)
   */
  depthValue?: 0 | 1 | 2;

  /**
   * Custom classes for bet content cell
   */
  classes?: BetContentCellClasses;

  /**
   * Custom styles for cell
   */
  cellStyles?: BetContentCellStyles;

  /**
   * Redirect to single market page if inline placement is disabled
   */
  redirectToSingleMarketPage?: boolean;
  /**
   * URL search params for redirect to single market page
   */
  redirectToSingleMarketSearchParams?: string;
  /**
   * Callback fired when redirected to a single market page
   * @param market
   */
  onRedirectToSingleMarketPage?: (market: IMarket) => void;
  /**
   * Cell width for mobile
   */
  mobileCellWidth?: number;
  marketStatusSettings: IMarketStatusSettings;
  isOtherSection?: boolean;
  page?: PlacementPage;
  isPopularMarketsOdds?: boolean;
}

const BetContentCell = ({
  isLayColumnHidden,
  mobileBettingMode = false,
  betType,
  pageBlock,
  market,
  runner,
  isDeepPrice = false,
  depthValue = 0,
  classes,
  cellStyles,
  redirectToSingleMarketPage,
  redirectToSingleMarketSearchParams,
  onRedirectToSingleMarketPage,
  mobileCellWidth,
  marketStatusSettings: { showLockIcon, displayStatus, showStatus },
  isOtherSection,
  page,
  isPopularMarketsOdds = false
}: BetContentCellProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const betName = betType === BetTypes.BACK ? BetDatabaseName.BACK : BetDatabaseName.LAY;

  const device = useSelector(getAppDevice);
  const hideMarketDepth = useSelector(getIsHideMarketDepth);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const contextualHelp = useSelector(getContextualHelp);
  const marketPricesCurrency = useSelector(getMarketPricesCurrencyById(market.marketId));
  const betAmount = useSelector(
    getBetAmount(market.marketId, runner?.selectionId ?? 0, runner?.handicap ?? 0, betName, depthValue)
  );
  const isSelectionLocked = useSelector(
    getMarketPricesRunnerLockedBySelectionId(market.marketId, runner?.selectionId ?? 0, runner?.handicap ?? 0)
  );
  const isMobileConfirmationStep = useSelector(getIsMobilePlacementConfirmationStep);

  let odds = useOddsDisplayFormat({
    marketId: market.marketId,
    selectionId: runner?.selectionId ?? 0,
    handicap: runner?.handicap ?? 0,
    betType
  });

  odds = getPricesByMarketType(odds, market.description.marketType, market.description.bettingType);

  const { marketId } = useParams();
  const isTodayCard = marketId?.startsWith('tc');
  const isMarketOdds = pageBlock === PageBlocks.MARKET_ODDS;

  const { viewLiquidity, inlineBetting, swapColorsFancyMarketsOnCricket } = useDeviceSettings();
  const isInlinePlacement = useInlinePlacement();
  const { isMultiCurrencySupported, isMultiCurrencyChanged: isMultiCurrencyChangedCheck } =
    useMultiCurrencySupporting();
  const isMultiCurrencyChanged = isMultiCurrencySupported && isMultiCurrencyChangedCheck(marketPricesCurrency);

  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);

  const prevOdds = useRef<null | number | undefined>(null);
  const containerRef = useRef<HTMLButtonElement>(null);

  const isBack = betType === BetTypes.BACK;
  const isLineBettingType = market.description.bettingType === BettingType.LINE;
  const isDesktop = device === Devices.DESKTOP;
  const showAmount = !isPNCEnabled || viewLiquidity;
  const isEmptyColumn = runner === null;
  const selectionId = isEmptyColumn ? null : runner.selectionId;
  const isCricket = market.eventType.id === SportId.CRICKET;
  const isEditActionParam = searchParams.get('action') === 'edit';
  const offerIdFromParams = searchParams.get('offerId');

  const { formattedAmount: amount, noFormattedAmount: tooltipAmount } = useFormatCurrency(
    betAmount ?? '',
    marketPricesCurrency ?? '',
    {
      noCommas: true
    }
  );

  const showBetLockIcon = isPNCEnabled && (odds === 0 || odds === null);
  const showBetPrice = isDeepPrice ? !hideMarketDepth && odds !== 0 : odds !== 0;

  highlightBet(getIsOddsChanged(prevOdds.current, odds), setIsHighlighted);

  prevOdds.current = odds;

  const inlineSelectedBet = useSelector(
    getInlineSelectedBet(pageBlock, market.marketId, selectionId ?? 0, runner?.handicap ?? null, betType)
  );
  const isInlineSelectedBetByMarketId = useSelector(getIsInlineSelectedBetByMarket(pageBlock, market.marketId));

  const inlineSelectedBetBeforePlacement = useSelector(
    getInlineSelectedBetBeforePlacement(pageBlock, market.marketId, selectionId ?? 0, runner?.handicap ?? null, betType)
  );

  const isBetslipBetSelected = useSelector(
    getIsBetSelected({
      marketId: market.marketId,
      selectionId: selectionId ?? 0,
      handicap: runner?.handicap ?? null,
      type: betType
    })
  );

  const isLockedIconEnabled =
    isSelectionLocked || ((showLockIcon || showBetLockIcon) && (!isPNCEnabled || !isEmptyColumn));

  const isSelected =
    (isDesktop && !!inlineSelectedBetBeforePlacement) ||
    (!isDesktop &&
      !!inlineSelectedBet &&
      !inlineSelectedBet.notHighlightBetContentCell &&
      (!inlineSelectedBet.currentOfferId || !!inlineSelectedBet.action)) ||
    isBetslipBetSelected;

  const raceName = market?.parents?.find(({ type: eventType }) => eventType === PopularEventType.RACE)?.name ?? '';

  const marketInfo = {
    commission: market.commission,
    marketId: market.marketId,
    eventId: market.event.id,
    sportId: market.eventType.id,
    marketType: market.description.marketType,
    bettingType: market.description.bettingType,
    numberOfWinners: market.numberOfWinners,
    eventName: market.event.name,
    marketName: market.marketName,
    raceName,
    marketUnit: market.description.lineRangeInfo?.marketUnit ?? '',
    marketStartTime: market.marketStartTime,
    eachWayDivisor: market.description.eachWayDivisor,
    lineRangeInfo: market.description.lineRangeInfo,
    priceLadderDescription: market.description.priceLadderDescription,
    runners: market.runners,
    fancyView: market.fancyView
  };

  const onBetCellClickHandler = () => {
    if (pageBlock && market.marketId && runner) {
      const bet: TInlineSelectedBet = {
        marketId: market.marketId,
        eventId: market.event.id,
        sportId: market.eventType.id,
        selectionId: runner.selectionId,
        handicap: runner.handicap,
        marketType: market.description.marketType,
        bettingType: market.description.bettingType,
        numberOfWinners: market.numberOfWinners,
        eventName: market.event.name,
        marketName: market.marketName,
        selectionName: runner.runnerName,
        marketUnit: market.description.lineRangeInfo?.marketUnit ?? '',
        lineSide: runner.lineSide ?? null,
        marketStartTime: market.marketStartTime,
        eachWayDivisor: market.description.eachWayDivisor,
        lineRangeInfo: market.description.lineRangeInfo,
        priceLadderDescription: market.description.priceLadderDescription,
        currency: marketPricesCurrency ?? '',
        fancyView: market.fancyView,
        raceName,
        type: betType,
        price: odds || '',
        pageBlock,
        page,
        betUuid: inlineSelectedBet?.betUuid
      };

      const isPlacementDisabled = !inlineBetting;

      const isRedirectToMarketPage = isDesktop
        ? isPlacementDisabled && !(isMarketOdds && isTodayCard)
        : redirectToSingleMarketPage && isPlacementDisabled;

      if (!isDesktop) {
        if (isSelected) {
          if (inlineSelectedBet && odds !== inlineSelectedBet?.price && !isMobileConfirmationStep) {
            dispatch(updateInlineSelectedBet({ ...inlineSelectedBet, price: odds }));
          } else {
            dispatch(removeInlineSelectedBet(bet));
          }
        } else {
          dispatch(cleanInlineSelectedBets());
          if (isInlineSelectedBetByMarketId) {
            // If there is inlineSelectedBet for the same market MobilePlacement doesn't unmount before new
            // inlineSelectedBet, and all the states for previous inlineSelectedBet in MobilePlacement stay, so need to
            // add new inlineSelectedBet (for another selection or betSide) with timeout, in thi case previous MobilePlacement
            // will unmount and new one will mount with new inlineSelectedBet
            setTimeout(() => {
              dispatch(setInlineSelectedBet({ selectedBet: bet, isMobilePlacement: true }));
            }, 0);
          } else {
            dispatch(setInlineSelectedBet({ selectedBet: bet, isMobilePlacement: true }));
          }
        }
      } else {
        if (isInlinePlacement && INLINE_PLACEMENT_AVAILABLE.includes(pageBlock)) {
          if (isSelected && inlineSelectedBetBeforePlacement) {
            if (
              inlineSelectedBetBeforePlacement?.price === bet.price ||
              inlineSelectedBetBeforePlacement.action === Actions.CONFIRM
            ) {
              dispatch(removeInlineSelectedBet(inlineSelectedBetBeforePlacement));
            } else {
              dispatch(setInlineSelectedBet({ selectedBet: bet }));
            }
          } else {
            dispatch(setInlineSelectedBet({ selectedBet: bet }));
          }
        } else {
          if (isMultiCurrencyChanged && !isSelected) {
            dispatch(removeAllSportsSelectedBets());
          }
          dispatch(
            setSelectedBets({
              selectedBets: [
                {
                  ...bet,
                  ...(isRedirectToMarketPage ? { redirectedTo: PageBlocks.MARKET_ODDS } : {}),
                  isPopularMarket: isPopularMarketsOdds
                }
              ],
              marketInfo
            })
          );
          dispatch(setActiveTab(EBetslipTabs.PLACE_BETS));
        }

        if (isRedirectToMarketPage) {
          const singleMarketPageURL = `${SPORT_BASE_URL}/${market.eventType.id}/market/${market.marketId}`;
          const toSingleMarketPage = redirectToSingleMarketSearchParams
            ? { pathname: singleMarketPageURL, search: redirectToSingleMarketSearchParams }
            : singleMarketPageURL;

          if (onRedirectToSingleMarketPage) {
            onRedirectToSingleMarketPage(market);
          }
          navigate(toSingleMarketPage);
        }
      }
    }

    if (offerIdFromParams && isEditActionParam) {
      searchParams.delete('action');
      searchParams.delete('offerId');

      setSearchParams(searchParams, { replace: true });
    }
  };

  const getMarketUnitTranslation = () => {
    const marketUnit = market.description?.lineRangeInfo?.marketUnit || 'runs';
    const marketUnitKey = marketUnit.replace(' ', '').toLowerCase();
    const marketUniti18nKey = 'market.units.' + marketUnitKey;
    const marketUnitTranslation = t(marketUniti18nKey);

    if (marketUniti18nKey === marketUnitTranslation) {
      return marketUnit;
    } else {
      return marketUnitTranslation;
    }
  };

  const renderCellContent = () => {
    if (isLockedIconEnabled) {
      return <i className="biab_lock-icon fa2 fa2-lock" />;
    }

    if (showBetPrice && !isEmptyColumn) {
      return (
        <>
          <span
            className={classNames(styles.betOdds, 'betOdds', {
              [classes?.odds ?? '']: classes?.odds
            })}
          >
            {odds}
          </span>
          {!isEmptyColumn && showAmount && (
            <span className={classNames('biab_bet-amount betAmount', styles.betAmount)}>{amount}</span>
          )}
        </>
      );
    } else if (isEmptyColumn) {
      return <span className={classNames(styles.betOdds, 'betOdds')}>&#8212;</span>;
    } else if (market.description.bettingType === BettingType.LINE && !isDeepPrice) {
      return (
        <span title="makeOffer" className={styles.makeOffer}>
          {t('market.labels.makeOffer')}
        </span>
      );
    }

    return null;
  };

  let tooltipText: string;

  if (displayStatus || !showBetPrice || isLockedIconEnabled) {
    tooltipText = t('tooltip.emptyCell');
  } else if (pageBlock === PageBlocks.POPULAR || pageBlock === PageBlocks.EVENT || isOtherSection) {
    tooltipText = t(betType === BetTypes.BACK ? 'tooltip.eventBackCell' : 'tooltip.eventLayCell', {
      odds,
      selection: runner?.runnerName
    });
  } else if (
    pageBlock === PageBlocks.IN_PLAY ||
    pageBlock === PageBlocks.HOME ||
    pageBlock === PageBlocks.SEARCH ||
    pageBlock === PageBlocks.SPORT
  ) {
    tooltipText = t(betType === BetTypes.BACK ? 'tooltip.backCell' : 'tooltip.layCell', {
      odds,
      selection: runner?.runnerName
    });
  } else {
    tooltipText = t(
      `${isPNCEnabled ? 'pnc.' : ''}tooltip${isLineBettingType ? '.market.line.' : '.'}${
        betType === BetTypes.BACK ? 'backMarketCell' : 'layMarketCell'
      }`,
      {
        odds,
        amount: tooltipAmount,
        selection: runner?.runnerName,
        runs: getMarketUnitTranslation(),
        UNITS: getMarketUnitTranslation()
      }
    );
  }

  const isFancySwapColors = isCricket && swapColorsFancyMarketsOnCricket && isLineBettingType;
  const isSelectedInlineBet = isDesktop && isSelected && inlineSelectedBet;

  return (
    <div
      className={classNames({
        [styles.contents]: isDesktop,
        [styles.mobileBetContentCellWrapper]: mobileBettingMode,
        'biab_bet-content-cell': mobileBettingMode,
        [classes?.container ?? '']: classes?.container,
        [styles.mobileCellContainer]: !isDesktop
      })}
    >
      <button
        ref={containerRef}
        style={{ ...cellStyles?.cell, width: !isDesktop ? mobileCellWidth : '' }}
        data-cell-id={selectionId}
        className={classNames('betContentCellMarket biab_bet', styles.betContentCell, {
          'biab_empty-cell': displayStatus || !showBetPrice,
          [styles.doubleWidth]: betType === BetTypes.BACK && isLayColumnHidden,
          [styles.lockIconContainer]: isLockedIconEnabled,
          [styles.deepPriceCell]: isDeepPrice,
          [styles.highlighted]: isHighlighted,
          biab_highlighted: isHighlighted,
          'biab_bet-content-cell': !mobileBettingMode,
          'biab_blue-cell': betType === BetTypes.BACK && !isDeepPrice,
          'biab_green-cell': betType === BetTypes.LAY && !isDeepPrice,
          'biab_grey-cell': isDeepPrice,
          'biab_line-market-cell': market.description.bettingType === BettingType.LINE,
          [classes?.cell ?? '']: classes?.cell,
          'biab_back-2': isDeepPrice && betType === BetTypes.BACK && depthValue === 2,
          'biab_back-1': isDeepPrice && betType === BetTypes.BACK && depthValue === 1,
          'biab_back-0': betType === BetTypes.BACK && depthValue === 0,
          'biab_lay-2': isDeepPrice && betType === BetTypes.LAY && depthValue === 2,
          'biab_lay-1': isDeepPrice && betType === BetTypes.LAY && depthValue === 1,
          'biab_lay-0': betType === BetTypes.LAY && depthValue === 0,
          'biab_bet-back back-cell': betType === BetTypes.BACK,
          'biab_bet-lay lay-cell': betType === BetTypes.LAY,
          biab_selected: isSelected,
          [styles.inlineSelected]: isDesktop && isSelected && inlineSelectedBet,
          [styles.inlineSelected__back]: isSelectedInlineBet && (!isFancySwapColors ? isBack : !isBack),
          [styles.inlineSelected__lay]: isSelectedInlineBet && (isFancySwapColors ? isBack : !isBack)
        })}
        disabled={showStatus || isLockedIconEnabled}
        onAnimationEnd={() => setIsHighlighted(false)}
        onClick={onBetCellClickHandler}
      >
        <span
          data-tooltip-id="tooltip"
          data-tooltip-html={isDesktop && contextualHelp && !showStatus ? unescape(tooltipText) : ''}
          className={classNames(styles.tooltip, {
            'cursor-help': isDesktop && contextualHelp && !showStatus
          })}
        >
          {showStatus && <div className={styles.overlayBackground} />}
          <div
            className={classNames('biab_bet-content betContent', styles.betContent, {
              [styles.hyphen]: isEmptyColumn,
              [classes?.content ?? '']: classes?.content
            })}
          >
            {renderCellContent()}
          </div>
        </span>
      </button>
    </div>
  );
};

export default BetContentCell;
