import { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import ExchangeTypeTabs from 'components/BettingProfitAndLoss/components/ExchangeTypeTabs';
import PLTabs from 'components/BettingProfitAndLoss/components/PLTabs';
import Loader, { CircleColors } from 'components/Loader';
import MobileAccountNavigation from 'components/MobileAccountNavigation';
import MyBetsSkeleton from 'components/Skeleton/MyBetsSkeleton';
import {
  BetTypes,
  BetTypeValue,
  ExchangeTypes,
  ExchangeTypeTabButtons,
  mapPLTabButtonsToAccountProductKey,
  MyBetsLocations,
  NavigationButtons,
  PLBetType,
  PLTabButtons,
  TExchangeTypes
} from 'constants/myBets';
import useCommissionRange from 'hooks/useCommissionRange';
import useDevice from 'hooks/useDevice';
import useElementSize from 'hooks/useElementSize';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import TimeFormatDropdown from 'pages/MyExchangeBetsPage/components/TimeFormatDropdown';
import { getCurrency } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchProfitGames, fetchProfitSports } from 'redux/modules/myBets';
import {
  getLoadingProducts,
  getMyBetsMultiCurrencyValue,
  getSelectedTabIsFirstLoaded,
  isShowMobileBetDetails,
  isShowMobileStatementDetails,
  myBetsLoading
} from 'redux/modules/myBets/selectors';
import { TAccountProduct } from 'redux/modules/myBets/type';
import { getIsTimeOutEnabled } from 'redux/modules/responsibleBetting/selectors';
import { CookieNames } from 'types';
import { TFetchDataWithCurrencyHandlers } from 'types/myBets';
import { getMultiCurrencyCodeFromArg } from 'utils/myBetsValues';

import DesktopAccountPagesHeader from '../DesktopAccountPagesHeader/DesktopAccountPagesHeader';

import styles from './MyBetsPageHeader.module.scss';

type MyBetsPagesHeaderProps = {
  accountProducts: TAccountProduct;
  activePLTab: PLBetType;
  onSetActivePLTab: (tabValue: PLBetType) => void;
  selectedTab: string;
  onChangeBetType: MutableRefObject<((betType: BetTypeValue) => void) | undefined>;
  exchangePageType: string;
  isAsianViewEnabled: boolean;
  fetchDataWithCurrencyHandlers: MutableRefObject<TFetchDataWithCurrencyHandlers>;
  setHeaderHeight: Dispatch<SetStateAction<number>>;
};

const MyBetsPagesHeader = ({
  accountProducts,
  activePLTab,
  onSetActivePLTab,
  selectedTab,
  onChangeBetType,
  exchangePageType,
  isAsianViewEnabled,
  fetchDataWithCurrencyHandlers,
  setHeaderHeight
}: MyBetsPagesHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies, setCookies] = useCookies([CookieNames.EXCHANGE_TYPE, CookieNames.PROFITANDLOSS_BETTYPE]);
  const navigate = useNavigate();
  const { betType } = useParams();

  const currentCurrency = useSelector(getCurrency);
  const isLoadingProducts = useSelector(getLoadingProducts);
  const isLoading = useSelector(myBetsLoading);
  const isDetailsOpen = useSelector(isShowMobileStatementDetails);
  const isBetDetailsOpen = useSelector(isShowMobileBetDetails);
  const multiCurrencyValue = useSelector(getMyBetsMultiCurrencyValue);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isFirstLoaded = useSelector(getSelectedTabIsFirstLoaded(selectedTab));
  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);

  const { isMobile } = useDevice();
  const { commissionRange, showCommissionRange } = useCommissionRange();
  const [headerRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      setHeaderHeight(height);
    }
  });
  const { multiCurrencySupported, isMultiCurrencySupported } = useMultiCurrencySupporting();

  const activeExchangeTab = (cookies.EXCHANGE_TYPE as TExchangeTypes) || ExchangeTypeTabButtons[0];
  const availablePLTabs = PLTabButtons.filter(tab => accountProducts?.[mapPLTabButtonsToAccountProductKey[tab]]);
  const hasSpinner = isLoading || isLoadingProducts;
  const hasExchangeTabs = accountProducts?.asianView && (accountProducts?.sports || accountProducts?.games);
  const isInvalidExchangeType =
    accountProducts &&
    (!cookies.EXCHANGE_TYPE || (!accountProducts.asianView && activeExchangeTab === ExchangeTypes.AsianView));
  const isInvalidPLType =
    accountProducts && (!cookies.PROFITANDLOSS_BETTYPE || !availablePLTabs.includes(cookies.PROFITANDLOSS_BETTYPE));

  const handleSetActiveExchangeTab = (tabValue: TExchangeTypes) => {
    setCookies(CookieNames.EXCHANGE_TYPE, tabValue, { path: '/' });
  };

  const handleChangeBetType = (tabValue: PLBetType) => {
    if (activePLTab !== tabValue) {
      const currencyCode = getMultiCurrencyCodeFromArg(
        multiCurrencySupported,
        isMultiCurrencySupported,
        currentCurrency.currencyCode,
        multiCurrencyValue
      );

      if (onChangeBetType.current) {
        onChangeBetType.current(tabValue === PLBetType.Games ? BetTypeValue.Games : BetTypeValue.Sports);
      }

      onSetActivePLTab(tabValue);

      if (tabValue === PLBetType.Sports) {
        dispatch(fetchProfitSports(currencyCode));
      } else if (tabValue === PLBetType.Games) {
        dispatch(fetchProfitGames(currencyCode));
      }
    }
  };

  const handleChangeExchangeType = (tabValue: TExchangeTypes) => {
    if (activeExchangeTab !== tabValue) {
      handleSetActiveExchangeTab(tabValue);
    }
  };

  const pageHeadingText = () => {
    switch (selectedTab) {
      case NavigationButtons.MyBets:
        return t('account.mybets.title');
      case NavigationButtons.Statement:
        return t('account.statement.title');
      case NavigationButtons.ProfitAndLoss:
        return t('accountNavigation.profitandloss');
    }
  };

  useEffect(() => {
    if (activeExchangeTab == ExchangeTypes.AsianView && betType == BetTypes.Lapsed) {
      navigate(`${exchangePageType}${MyBetsLocations.Settled}/1`);
    }
  }, [betType, activeExchangeTab]);

  useEffect(() => {
    if (accountProducts) {
      if (selectedTab === NavigationButtons.MyBets && isInvalidExchangeType) {
        handleSetActiveExchangeTab(ExchangeTypeTabButtons[accountProducts.asianView ? 0 : 1]);
      }
      if (selectedTab === NavigationButtons.ProfitAndLoss && isInvalidPLType) {
        onSetActivePLTab(availablePLTabs[0]);
      }
    }
  }, [selectedTab, accountProducts]);

  return (
    <div ref={headerRef}>
      {isMobile && hasSpinner && (
        <div className={styles.globalLoading}>
          <Loader circleColor={CircleColors.BLACK} />
        </div>
      )}
      {!isMobile && !isFirstLoaded && isLoading && <MyBetsSkeleton isShowHeader isShowTable isUsePadding />}
      {isMobile && <MobileAccountNavigation />}
      {isMobile && !isLoggedIn && <p className={styles.betsPagesMessage}>{t('account.mybets.login')}</p>}
      {isMobile &&
        ((!isDetailsOpen && selectedTab === NavigationButtons.Statement) ||
          (selectedTab === NavigationButtons.MyBets && !isBetDetailsOpen)) && (
          <div
            className={classNames(styles.mobileHeader, {
              [styles.mobileHeader__noTabs]: !hasExchangeTabs && selectedTab === NavigationButtons.MyBets,
              [styles.mobileHeader__statement]: selectedTab === NavigationButtons.Statement,
              [styles.mobileHeader__hidden]: isTimeOutEnabled
            })}
          >
            <ExchangeTypeTabs
              isAsianViewActive={accountProducts.asianView}
              isSportsGamesActive={accountProducts.sports || accountProducts.games}
              activeTab={activeExchangeTab}
              setActiveTab={handleChangeExchangeType}
              isMobile={isMobile}
            />
            {isAsianViewEnabled && cookies.EXCHANGE_TYPE === ExchangeTypes.AsianView && <TimeFormatDropdown />}
          </div>
        )}
      {isMobile && selectedTab === NavigationButtons.ProfitAndLoss && (
        <div className={classNames(styles.mobileHeader, styles.mobileHeader__pl)}>
          <PLTabs
            accountProducts={accountProducts}
            activePLTab={activePLTab}
            setActivePLTab={handleChangeBetType}
            isMobile
          />
          {isAsianViewEnabled && cookies.PROFITANDLOSS_BETTYPE === PLBetType.AsianView && <TimeFormatDropdown />}
        </div>
      )}
      {isLoggedIn && !isMobile && (
        <>
          <DesktopAccountPagesHeader
            isMultiCurrencySupported={isMultiCurrencySupported}
            fetchDataWithCurrencyHandlers={fetchDataWithCurrencyHandlers}
            activePLTab={
              selectedTab === NavigationButtons.MyBets || selectedTab === NavigationButtons.Statement
                ? activeExchangeTab
                : activePLTab
            }
          />
          <div className={styles.betsPagesHeader}>
            <h2 className={classNames('biab_page-heading', styles.heading)}>{pageHeadingText()}</h2>
            {!isMobile && (selectedTab === NavigationButtons.MyBets || selectedTab === NavigationButtons.Statement) && (
              <ExchangeTypeTabs
                isAsianViewActive={accountProducts.asianView}
                isSportsGamesActive={accountProducts.sports || accountProducts.games}
                activeTab={activeExchangeTab}
                setActiveTab={handleChangeExchangeType}
                isMobile={isMobile}
              />
            )}
            {selectedTab === NavigationButtons.ProfitAndLoss && (
              <PLTabs
                accountProducts={accountProducts}
                activePLTab={activePLTab}
                setActivePLTab={handleChangeBetType}
                isMobile={false}
              />
            )}
            {selectedTab === NavigationButtons.Statement && showCommissionRange && (
              <div className={styles.commissionRange}>
                {t('accountStatement.commissionRange')}:{commissionRange}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MyBetsPagesHeader;
