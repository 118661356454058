import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { mobileBetslip as branding } from 'constants/branding';
import { useIsMobilePlacementDisabled } from 'hooks/useMobilePlacement';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getCurrentBetsBySelection } from 'redux/modules/currentBets/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { CookieNames, PageBlocks, PlacementPage } from 'types';
import { BetTypes, MatchTypes } from 'types/bets';

import MobileOpenBet from './MobileOpenBet';

import styles from './styles.module.scss';

type MobilePlacementProps = {
  marketId: string;
  selectionId?: number;
  handicap?: number;
  pageBlock: PageBlocks;
  marketPricesCurrency?: string;
  page?: PlacementPage;
  containerClassName?: string;
  sportId?: string;
};

function MobileOpenBets({
  marketId,
  selectionId,
  handicap,
  pageBlock = PageBlocks.HOME,
  marketPricesCurrency = '',
  page,
  containerClassName = '',
  sportId
}: MobilePlacementProps) {
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.MOBILE_OPEN_BETS]);

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const unmatchedBets = useSelector(getCurrentBetsBySelection(MatchTypes.UNMATCHED, marketId, selectionId, handicap));
  const matchedBets = useSelector(getCurrentBetsBySelection(MatchTypes.MATCHED, marketId, selectionId, handicap));

  const { isBettingEnabled } = useIsMobilePlacementDisabled({
    marketId,
    selectionId,
    handicap,
    pageBlock,
    type: BetTypes.LAY,
    sportId
  });

  const [closedUnmatchedBets, setClosedUnmatchedBets] = useState<number[]>([]);
  const [closedMatchedBets, setClosedMatchedBets] = useState<number[]>([]);
  const [isUnmatchedCollapsed, setIsUnmatchedCollapsed] = useState(unmatchedBets.length === 0);
  const [isMatchedCollapsed, setIsMatchedCollapsed] = useState(!(unmatchedBets.length === 0 && matchedBets.length > 0));

  const isOpenBetsEnabled = cookies[CookieNames.MOBILE_OPEN_BETS] === 'true';

  const getFilteredBets = (matchType: MatchTypes): TCurrentBet[] => {
    if (matchType == MatchTypes.MATCHED) {
      if (isOpenBetsEnabled) {
        return matchedBets;
      }
      return matchedBets.filter(bet => !closedMatchedBets.includes(bet.offerId));
    } else {
      if (isOpenBetsEnabled) {
        return unmatchedBets;
      }
      return unmatchedBets.filter(bet => !closedUnmatchedBets.includes(bet.offerId));
    }
  };

  const matchedBetsLength = getFilteredBets(MatchTypes.MATCHED).length;
  const unmatchedBetsLength = getFilteredBets(MatchTypes.UNMATCHED).length;
  const hasUnmatchedHeader = (matchedBetsLength > 0 && unmatchedBetsLength > 0) || unmatchedBetsLength > 1;
  const hasMatchedHeader = (matchedBetsLength > 0 && unmatchedBetsLength > 0) || matchedBetsLength > 1;
  const hasVisibleMatchedBets = !isMatchedCollapsed || (matchedBetsLength === 1 && unmatchedBetsLength === 0);
  const hasVisibleUnmatchedBets = !isUnmatchedCollapsed || (unmatchedBetsLength === 1 && matchedBetsLength === 0);

  const toggleUnmatchedBets = () => {
    if (isUnmatchedCollapsed && getFilteredBets(MatchTypes.MATCHED).length) {
      setIsMatchedCollapsed(true);
    }
    setIsUnmatchedCollapsed(!isUnmatchedCollapsed);
  };

  const toggleMatchedBets = () => {
    if (isMatchedCollapsed && getFilteredBets(MatchTypes.UNMATCHED).length) {
      setIsUnmatchedCollapsed(true);
    }
    setIsMatchedCollapsed(!isMatchedCollapsed);
  };

  useEffect(() => {
    setIsUnmatchedCollapsed(unmatchedBetsLength == 0);
    setIsMatchedCollapsed(!(unmatchedBetsLength == 0 && matchedBetsLength > 0));
  }, [matchedBetsLength, unmatchedBetsLength, isOpenBetsEnabled]);

  const onHideBet = (matchType: MatchTypes, offerId: number) => {
    if (matchType == MatchTypes.MATCHED) {
      setClosedMatchedBets([...closedMatchedBets, offerId]);
    } else {
      setClosedUnmatchedBets([...closedUnmatchedBets, offerId]);
    }
  };

  useEffect(() => {
    if (isOpenBetsEnabled) {
      if (closedMatchedBets.length) {
        setClosedMatchedBets([]);
      }
      if (closedUnmatchedBets.length) {
        setClosedUnmatchedBets([]);
      }
    }
  }, [isOpenBetsEnabled]);

  if (!isUndefined(isBettingEnabled) && !isBettingEnabled) {
    return null;
  }

  return (
    <div className={classNames(styles.openBets, containerClassName, branding.OPENED_BETS)}>
      <div className={styles.openBets__byType}>
        {hasUnmatchedHeader && (
          <button
            className={classNames(styles.unmatchedHeader, branding.TAB, branding.UNMATCHED_INDICATOR, {
              biab_opened: !isUnmatchedCollapsed,
              [styles.unmatchedHeader__active]: hasVisibleUnmatchedBets,
              [branding.SELECTED]: !isUnmatchedCollapsed
            })}
            onClick={toggleUnmatchedBets}
          >
            <span className={classNames(styles.unmatchedHeader__betCount, branding.UNMATCHED_INDICATOR)}>
              {getFilteredBets(MatchTypes.UNMATCHED).length}
            </span>
            {t('betslip.openBets.tabs.unmatched')}
            <i className={classNames('fa', styles.expand, !isUnmatchedCollapsed && styles.expand__opened)} />
          </button>
        )}
        {hasMatchedHeader && <div className={styles.divider} />}
        {hasMatchedHeader && (
          <button
            className={classNames(styles.matchedHeader, branding.TAB, branding.MATCHED_INDICATOR, {
              biab_opened: !isMatchedCollapsed,
              [styles.matchedHeader__active]: hasVisibleMatchedBets,
              [branding.SELECTED]: !isMatchedCollapsed
            })}
            onClick={toggleMatchedBets}
          >
            <span className={classNames(styles.matchedHeader__betCount, branding.MATCHED_INDICATOR)}>
              {getFilteredBets(MatchTypes.MATCHED).length}
            </span>
            {t('betslip.openBets.tabs.matched')}
            <i className={classNames('fa', styles.expand, !isMatchedCollapsed && styles.expand__opened)} />
          </button>
        )}
      </div>
      {(hasVisibleMatchedBets || hasVisibleUnmatchedBets) && (
        <div className={classNames('biab_mobile-open-bets-list', styles.openBetsList)}>
          {hasVisibleMatchedBets &&
            getFilteredBets(MatchTypes.MATCHED).map((bet, index) => (
              <MobileOpenBet
                bet={bet}
                pageBlock={pageBlock}
                matchType={MatchTypes.MATCHED}
                isPNCEnabled={isPNCEnabled}
                isOpenBetsEnabled={isOpenBetsEnabled}
                key={`${bet.offerId}-${index}`}
                currency={marketPricesCurrency}
                onHide={onHideBet}
                page={page}
                noHeader={!hasUnmatchedHeader && !hasMatchedHeader}
              />
            ))}
          {hasVisibleUnmatchedBets &&
            getFilteredBets(MatchTypes.UNMATCHED).map((bet, index) => (
              <MobileOpenBet
                bet={bet}
                pageBlock={pageBlock}
                matchType={MatchTypes.UNMATCHED}
                isPNCEnabled={isPNCEnabled}
                isOpenBetsEnabled={isOpenBetsEnabled}
                key={`${bet.offerId}-${index}`}
                currency={marketPricesCurrency}
                onHide={onHideBet}
                page={page}
                noHeader={!hasUnmatchedHeader && !hasMatchedHeader}
              />
            ))}
        </div>
      )}
      {(hasVisibleMatchedBets || hasVisibleUnmatchedBets) && <div className={styles.openBets__empty} />}
    </div>
  );
}

export default MobileOpenBets;
