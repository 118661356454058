import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import { CombinedResponsibleBettingTimeUnit } from 'redux/modules/responsibleBetting/type';
import { DropdownItem } from 'types';

import dropdownStyles from './styles.module.scss';

interface ResponsibleBettingDropdownProps {
  onChangeOption: (option: DropdownItem) => void;
  data: DropdownItem[];
  value?: CombinedResponsibleBettingTimeUnit | string;
}

const ResponsibleBettingDropdown = ({ onChangeOption, data, value }: ResponsibleBettingDropdownProps) => {
  const { t } = useTranslation();
  const selectedValue = data.find(item => item.value === value);
  const defaultPlaceholder = t('responsibleBetting.placeholders.selectPeriod');
  const defaultValue = {
    value: defaultPlaceholder,
    label: defaultPlaceholder,
    id: defaultPlaceholder
  };

  return (
    <Dropdown
      classes={{
        ...dropdownStyles,
        placeholder: selectedValue
          ? dropdownStyles.placeholder
          : classNames(dropdownStyles.placeholder, dropdownStyles.placeholder__default)
      }}
      data={data}
      title={true}
      onSelectOption={onChangeOption}
      defaultValue={selectedValue || defaultValue}
    />
  );
};

export default ResponsibleBettingDropdown;
