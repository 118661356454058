import classNames from 'classnames';

import { componentsBranding } from 'constants/branding';
import { ResponsibleBettingButtonTypeKeys, ResponsibleBettingButtonTypes } from 'types/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingBtnProps {
  label: string;
  onClick: () => void;
  type?: ResponsibleBettingButtonTypeKeys;
  isMobile: boolean;
  customClass?: string;
  disabled?: boolean;
}

const ResponsibleBettingBtn = ({
  label,
  onClick,
  type = ResponsibleBettingButtonTypes.Primary,
  isMobile,
  customClass = '',
  disabled = false
}: ResponsibleBettingBtnProps) => (
  <button
    className={classNames(styles.btn, customClass, {
      [componentsBranding.DISABLED]: disabled,
      [componentsBranding.PRIMARY_BTN]: type === ResponsibleBettingButtonTypes.Primary,
      [componentsBranding.SECONDARY_BTN]: type === ResponsibleBettingButtonTypes.Secondary,
      [styles.btn__primary]: type === ResponsibleBettingButtonTypes.Primary,
      [styles.btn__secondary]: type === ResponsibleBettingButtonTypes.Secondary,
      [styles.btn__applied]: type === ResponsibleBettingButtonTypes.Applied,
      [styles.btn__outlined]: type === ResponsibleBettingButtonTypes.Outlined,
      [styles.btn__mobile]: isMobile
    })}
    onClick={onClick}
    disabled={disabled}
  >
    {label}
  </button>
);

export default ResponsibleBettingBtn;
