import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { DEFAULT_CURRENCY } from 'constants/app';
import { VALIDATION_ERROR_DIFFERENT_CURRENCY_MARKET } from 'constants/placement';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { removeAllGamesSelectedBets, removeAllSportsSelectedBets } from 'redux/modules/betslip';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';

import styles from './styles.module.scss';

const CurrencyMessage = ({
  marketCurrency = DEFAULT_CURRENCY,
  hasCloseIcon = false
}: {
  marketCurrency?: string;
  hasCloseIcon?: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isGameBetSlip = useSelector(getIsGameBetSlip);

  const errorMessageRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(errorMessageRef, () => onCancel);

  const onCancel = () => {
    if (isGameBetSlip) {
      dispatch(removeAllGamesSelectedBets());
    } else {
      dispatch(removeAllSportsSelectedBets);
    }
  };

  return (
    <div className={styles.multiCurrencyMessage} ref={errorMessageRef}>
      <i className={classNames('biab_custom-icon-info-circle', styles.multiCurrencyMessage__icon)} />
      <div
        className={styles.multiCurrencyMessage__text}
        dangerouslySetInnerHTML={{
          __html: unescape(t(VALIDATION_ERROR_DIFFERENT_CURRENCY_MARKET, { currency_ISO_code: marketCurrency }))
        }}
      />
      {hasCloseIcon && (
        <button className={styles.multiCurrencyMessage__btnClose}>
          <i className="fa fa-times" aria-hidden="true" onClick={onCancel} />
        </button>
      )}
    </div>
  );
};

export default CurrencyMessage;
