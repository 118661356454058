import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { ONE_SECOND } from 'constants/date';
import { ACCOUNT_BASE_URL, ASIAN_BASE_URL, HOME_BASE_URL, RESPONSIBLE_BETTING_BASE_URL } from 'constants/locations';
import useDevice from 'hooks/useDevice';
import { getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { logoutUser } from 'redux/modules/auth';
import { setShowReminder } from 'redux/modules/responsibleBetting';
import { getShowReminder } from 'redux/modules/responsibleBetting/selectors';
import { getUserSessionInfo } from 'redux/modules/user/selectors';
import { parseMinutesToHoursAndMinutes } from 'utils/responsibleBetting';
import { getParsedSessionTimeInMinutes } from 'utils/session';

import ResponsibleBettingModalTemplate from '../ResponsibleBettingModalTemplate';

import styles from './styles.module.scss';

const ResponsibleBettingReminderModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const { pathname } = useLocation();

  const showReminder = useSelector(getShowReminder);

  const sessionInfo = useSelector(getUserSessionInfo);
  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);

  const isAsianViewPage = pathname.includes(ASIAN_BASE_URL);

  const exchangePage = (isAsianViewPage && ASIAN_BASE_URL) || HOME_BASE_URL;

  const [sessionTime, setSessionTime] = useState(getParsedSessionTimeInMinutes(sessionInfo?.startSessionDate ?? 0));

  const handleOnEdit = () => {
    dispatch(setShowReminder(false));
    navigate(`${ACCOUNT_BASE_URL}${RESPONSIBLE_BETTING_BASE_URL}`, { replace: true });
  };

  useEffect(() => {
    if (sessionInfo?.startSessionDate) {
      const timer = setInterval(() => {
        const currentSessionTime = getParsedSessionTimeInMinutes(sessionInfo?.startSessionDate);
        if (currentSessionTime !== sessionTime) {
          setSessionTime(currentSessionTime);
        }
      }, ONE_SECOND);

      return () => {
        clearInterval(timer);
      };
    }
  }, [sessionInfo?.startSessionDate, language, timezone]);

  return (
    <ResponsibleBettingModalTemplate
      title={t('responsibleBetting.titles.reminder')}
      open={showReminder}
      onContinue={() => dispatch(setShowReminder(false))}
      onLogout={() => {
        dispatch(setShowReminder(false));
        dispatch(logoutUser(exchangePage));
      }}
      onClose={() => dispatch(setShowReminder(false))}
    >
      <div className={classNames(styles.container, { [styles.container__mobile]: isMobile })}>
        <div className={styles.content}>
          <i className={classNames(styles.icon, 'biab_custom-icon-bell')} />
          <div className={styles.messageContainer}>
            <span className={styles.message}>{t('responsibleBetting.messages.playingForDuration')}:</span>
            <span className={styles.time}>{parseMinutesToHoursAndMinutes(sessionTime, t)}</span>
          </div>
        </div>
        <span className={classNames(styles.editLink, { [styles.editLink__mobile]: isMobile })} onClick={handleOnEdit}>
          {t('responsibleBetting.titles.editReminder')}
        </span>
      </div>
    </ResponsibleBettingModalTemplate>
  );
};

export default ResponsibleBettingReminderModal;
