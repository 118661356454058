import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { betslipBranding } from 'constants/branding';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';

import InlinePlacementTooltipWrapper from './components/InlinePlacementTooltipWrapper';

import styles from './styles.module.scss';

interface BetsIndicatorProps {
  matchedBets: TCurrentBet[];
  unmatchedBets: TCurrentBet[];
  eventTypeId: string;
  marketId: string;
  inMarketsTableRow?: boolean;
}

function BetsIndicator({ matchedBets, unmatchedBets, eventTypeId, marketId, inMarketsTableRow }: BetsIndicatorProps) {
  const { t } = useTranslation();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);

  const betsIndicatorMatchedClassnames = inMarketsTableRow
    ? classNames(betslipBranding.MATCHED_COUNTER, styles.placementLabel)
    : styles.betIndicatorWrapper__matchedBets;

  const betsIndicatorUnmatchedClassnames = inMarketsTableRow
    ? classNames(betslipBranding.UNMATCHED_COUNTER, styles.placementLabel)
    : styles.betIndicatorWrapper__unmatchedBets;

  const unmatchedTooltipText =
    unmatchedBets.length === 1 ? t('inlinePlacement.labels.unmatchedBet') : t('inlinePlacement.labels.unmatchedBets');

  const matchedTooltipText =
    matchedBets.length === 1
      ? t(`${isPNCEnabled ? 'pnc.' : ''}inlinePlacement.labels.matchedBet`)
      : t(`${isPNCEnabled ? 'pnc.' : ''}inlinePlacement.labels.matchedBet`);

  return (
    <div className={classNames(styles.betIndicatorWrapper, betslipBranding.BETSLIP_WRAP)}>
      {matchedBets.length > 0 && (
        <InlinePlacementTooltipWrapper
          length={matchedBets.length}
          tooltipText={matchedTooltipText}
          eventTypeId={eventTypeId}
          marketId={marketId}
          linkClasses={betsIndicatorMatchedClassnames}
        />
      )}
      {unmatchedBets.length > 0 && (
        <InlinePlacementTooltipWrapper
          length={unmatchedBets.length}
          tooltipText={unmatchedTooltipText}
          eventTypeId={eventTypeId}
          marketId={marketId}
          linkClasses={betsIndicatorUnmatchedClassnames}
        />
      )}
    </div>
  );
}

export default BetsIndicator;
