import { AB, CLASSIC_NAV_CLASSES, DEFAULT as ICON_DEFAULT, POKER_STAR } from 'constants/icons';

/*** types/interfaces that used in the whole app ***/

export interface Generator<T = unknown, TReturn = any, TNext = unknown> extends Iterator<T, TReturn, TNext> {
  next(...args: [] | [TNext]): IteratorResult<T, TReturn>;

  return(value: TReturn): IteratorResult<T, TReturn>;

  throw(e: any): IteratorResult<T, TReturn>;

  [Symbol.iterator](): Generator<T, TReturn, TNext>;
}

export enum Method {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
  PATCH = 'patch'
}

export interface RequestParams<T = null> {
  method: Method;
  url: string;
  data?: T;
  params?: any;
  withCredentials: boolean;
}

export type TFailureActionPayload = Error | null | undefined;
export type FailureActionPayload = Error | unknown;
export type Handler = (event: MouseEvent | TouchEvent) => void;

export interface DropdownItem {
  title?: string;
  label?: string;
  image?: string;
  /** icon className */
  icon?: string;
  tooltip?: string;
  /**
   * This property is used when selected label should be translatable, because if pass to component already translated
   * labels and then change locale you will see that label of selected item doesn't change. This happens because
   * label of selected item was translated only one time when it was passed, but after locale changing we need translate
   * this label again. So if you want to use labels with translations just pass translations keys as labels and true for
   * this property, f.e.: look at viewByOptions in pages/InPlay component
   */
  isLabelTranslatable?: boolean;
  id: number | string;
  value: string;
}

export interface DropdownClasses {
  container?: string;
  placeholder?: string;
  activePlaceholder?: string;
  dropdown?: string;
  option?: string;
  selectedOption?: string;
  icon?: string;
  selectedIcon?: string;
}

export interface BrandingDropdownClasses {
  dropdownBtn?: string;
  dropdownItem?: string;
  dropdownItems?: string;
  active?: string;
  selected?: string;
}

export enum CookieNames {
  IS_LOGGING_IN = 'BIAB_IS_LOGGING_IN',
  LOGIN_POP_UP_SHOWN = 'BIAB_LOGIN_POP_UP_SHOWN',
  DOMAIN = 'domain',
  I18_NEXT = 'i18next',
  API_URL = 'BIAB_API_URL',
  CSRF = 'CSRF-TOKEN',
  TOKEN = 'BIAB_TOKEN',
  REFERER = 'REFERER',
  BIAB_NEW_CUSTOMER = 'BIAB_NEW_CUSTOMER',
  TIME_FORMAT = 'BIAB_TIME_FORMAT',
  ODDS_TYPE = 'BIAB_ODDS_TYPE',
  LANGUAGE = 'BIAB_LANGUAGE',
  TIMEZONE = 'BIAB_TZ',
  LICENSE = 'BIAB_LICENSE',
  CONFIRM_BETS = 'BIAB_CONFIRM_BETS',
  BETSLIP_FILTER_MARKET = 'FILTER_BETS_BY_MARKET_ID',
  BETSLIP_FILTER_SORTING = 'SORTED_BETS',
  BETSLIP_FILTER_EVENT = 'FILTER_BETS_BY_EVENT_ID',
  IN_PLAY_VIEW_BY = 'BIAB_INPLAY_VIEW_BY',
  IN_PLAY_COLLAPSED_SPORTS = 'BIAB_INPLAY_COLlAPSED_SPORTS',
  MARKET_ODDS_COLLAPSED = 'BIAB_MARKET_ODDS_COLLAPSED',
  MARKETS_COLLAPSE = 'MARKETS_COLLAPSED',
  MARKETS_CLOSED = 'MARKETS_CLOSED',
  SHOW_TOOLTIPS = 'BIAB_SHOW_TOOLTIPS',
  RACING_PERIOD = 'BIAB_HORSE_RACING_PERIOD',
  BIAB_MARKET_NET_COMMISSION = 'BIAB_MARKET_NET_COMMISSION',
  COMPETITION_TIME_FILTER = 'BIAB_COMPETITION_TIME_FILTER',
  COMPETITION_VIEW_BY = 'BIAB_COMPETITION_VIEW_BY',
  COMPETITION_COLLAPSED = 'BIAB_COMPETITION_COLLAPSED',
  ACCOUNT_STATEMENT_DATEFROM = 'ACCOUNT_STATEMENT_DATEFROM',
  ACCOUNT_STATEMENT_DATETO = 'ACCOUNT_STATEMENT_DATETO',
  PROFITANDLOSS_TIMERANGE = 'PROFITANDLOSS_TIMERANGE',
  PROFITANDLOSS_BETTYPE = 'PROFITANDLOSS_BETTYPE',
  PROFITANDLOSS_SPORTNAME = 'PROFITANDLOSS_SPORTNAME',
  PROFITANDLOSS_SPORTID = 'PROFITANDLOSS_SPORTID',
  PROFITANDLOSS_SPORTTRANSLATION = 'PROFITANDLOSS_SPORTTRANSLATION',
  FANCY_SECTIONS_COLLAPSED = 'BIAB_FANCY_SECTIONS_COLLAPSED',
  MOBILE_OPEN_BETS = 'BIAB_MOBILE_OPEN_BETS',
  TOP_MARKETS_IS_OPENED = 'BIAB_TOP_MARKETS_IS_OPENED',
  TOP_MARKETS_COLLAPSED = 'BIAB_TOP_MARKETS_COLLAPSED',
  MULTI_MARKETS_OTHER_IS_OPENED = 'BIAB_MULTI_MARKETS_OTHER_IS_OPENED',
  MULTI_MARKETS_COLLAPSED = 'MULTI_MARKETS_COLLAPSED',
  BIAB_MULTIPLE_BANNERS_SHOW_ALL = 'BIAB_MULTIPLE_BANNERS_SHOW_ALL',
  PL_TABLE_VISIBLE = 'PL_TABLE_VISIBLE',
  BIAB_NEW_USER = 'BIAB_NEW_USER',
  BIAB_MOBILE_FAV_TAB_IS_ACTIVE = 'BIAB_MOBILE_FAV_TAB_IS_ACTIVE',
  BIAB_LOGIN_POP_UP_SHOWN = 'BIAB_LOGIN_POP_UP_SHOWN',
  BIAB_BETSLIP_NET_PROFIT_LOSS = 'BIAB_BETSLIP_NET_PROFIT_LOSS',
  ASIAN_VIEW_SORTING_FILTER = 'ASIAN_VIEW_SORTING_FILTER',
  ASIAN_VIEW_LINE_VIEW_FILTER = 'ASIAN_VIEW_LINE_VIEW_FILTER',
  ASIAN_VIEW_RIGHT_SIDE_IS_CLOSED = 'ASIAN_VIEW_RIGHT_SIDE_IS_CLOSED',
  ASIAN_VIEW_LAY_BETTING = 'ASIAN_VIEW_LAY_BETTING',
  ASIAN_VIEW_QUICK_BETTING = 'ASIAN_VIEW_QUICK_BETTING',
  EXCHANGE_TYPE = 'EXCHANGE_TYPE',
  ASIAN_VIEW_BET_LIST_SORTING = 'ASIAN_VIEW_BET_LIST_SORTING',
  COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR'
}

export interface SubheaderIconsType {
  [key: string]: string;
}

export interface GetIconName {
  iconName: string;
  sportId: string | number;
  modifiable: boolean;
  url: string;
  page: string;
}

export interface IconsConfigProps {
  SPORT_ICONS: { [key: string]: string };
  TOOLBAR_ICONS: {
    [AB]: Record<string, string>;
    [ICON_DEFAULT]: Record<string, string>;
  };
  SUBHEADER_ICONS: {
    [POKER_STAR]: Record<string, string>;
    [ICON_DEFAULT]: Record<string, string>;
    [CLASSIC_NAV_CLASSES]: Record<string, string>;
  };
  NAVIGATION_ICONS: {
    [POKER_STAR]: Record<string, string>;
    DEFAULT_ICON: string;
  };
}

export type TSocketMarketParams = {
  marketId: string;
  eventId: string;
  authToken?: string;
  applicationType?: string;
};

export enum ApplicationTypes {
  WEB = 'WEB'
}

export enum MarketStatus {
  CLOSED = 'CLOSED',
  HIDDEN = 'HIDDEN',
  SUSPENDED = 'SUSPENDED',
  REMOVED = 'REMOVED',
  OPEN = 'OPEN',
  IN_PLAY = 'IN_PLAY'
}

export type Inning = {
  overs: string;
  runs: string;
  wickets: string;
};

export type TEventsUpdatedDataTeam = {
  aces: null;
  currentBreak: null;
  doubleFaults: null;
  fullTimeScore: string;
  gameSequence: any;
  halfTimeScore: string;
  games: string;
  highlight: boolean;
  inning1: Inning | null;
  inning2: Inning | null;
  localizedNames: Record<string, string>;
  name: string | null;
  playerSeed: null;
  quarterByQuarter: number[];
  score: string;
  serviceBreaks: null;
  sets: string;
};

export const EventScorePeriod = {
  REGULAR: 'REGULAR',
  EXTRA: 'EXTRA'
} as const;

export type TEventScorePeriod = typeof EventScorePeriod[keyof typeof EventScorePeriod];

export const PenaltyResult = {
  SCORED: 'scored',
  MISSED: 'missed',
  CANCELLED: 'cancelled',
  FIRST_TEAM_TO_SHOOT: 'first_team_to_shoot'
} as const;

export type TPenaltyResult = typeof PenaltyResult[keyof typeof PenaltyResult];

export type TEventExtraData = {
  eventExtraData: {
    currentSet: number;
    currentDay: string;
    fullTimeElapsed: {
      hour: number;
      min: number;
      sec: number;
    };
    timeElapsedSeconds: number;
  };
  team: string;
  updateType: string;
  matchTime: number;
  footballWidth: number | string;
  elapsedRegularTime: number;
  period: TEventScorePeriod;
  penaltyResult: TPenaltyResult;
  inPlayMatchStatus: string | null;
};

export type TEventUpdatedData = {
  eventId: string;
  eventTypeId: number;
  score: {
    home: TEventsUpdatedDataTeam;
    away: TEventsUpdatedDataTeam;
  };
  inPlayMatchStatus: string | null;
  timeElapsed: number;
  elapsedRegularTime: number | null;
  elapsedAddedTime: number | null;
  marketId: null;
  status: EventStatus | null;
  asianViewElapsedRegularTime: number | null;
  period: TEventScorePeriod;
  updateDetails?: TEventExtraData[];
};

export enum SportId {
  SOCCER = '1',
  TENNIS = '2',
  CRICKET = '4',
  HORSE_RACING = '7',
  TODAY_HORSE_RACING = 'tc-7',
  GRAY_HOUND_RACING = '4339',
  TODAY_GRAY_HOUND_RACING = 'tc-4339',
  BASKETBALL = '7522'
}

export enum MatchStatus {
  HALF_TIME = 'FirstHalfEnd',
  END = 'Finished',
  FIRST_HALF = '1H',
  SECOND_HALF = '2H',
  KICK_OFF = 'KickOff',
  SECOND_HALF_KICK_OFF = 'SecondHalfKickOff',
  PENALTY_KICK_OFF = 'PenaltyKickOff'
}

export type TTimezone = Record<string, number>;
export type TOddsTypes = Record<OddsType, string>;

export type TGamesTitles = Record<string, string>;

export type TGameChannels = {
  [key: number]: {
    order: number;
    isTurbo: boolean;
  };
};

export type TGameOrder = Record<string, number>;

export enum PageBlocks {
  HOME = 'HOME',
  MARKET_ODDS = 'MARKET_ODDS',
  FANCY_VIEW = 'FANCY_VIEW',
  FANCY_TOP_VIEW = 'FANCY_TOP_VIEW',
  IN_PLAY = 'IN_PLAY',
  POPULAR = 'POPULAR',
  FEATURE_MARKET = 'FEATURE_MARKET',
  EVENT = 'EVENT',
  SPORT = 'SPORT',
  SEARCH = 'SEARCH',
  TOP_5_VIEW = 'TOP_5_VIEW',
  TOP_5_VIEW_TOP_MARKET = 'TOP_5_VIEW_TOP_MARKET',
  MULTI_MARKET_VIEW = 'MULTI_MARKET_VIEW',
  MULTI_MARKET_VIEW_TOP_MARKET = 'MULTI_MARKET_VIEW_TOP_MARKET',
  FANCY_MULTI_MARKET_VIEW = 'FANCY_MULTI_MARKET_VIEW',
  GAME = 'GAME',
  CASH_OUT = 'CASH_OUT'
}

export enum HomePageType {
  FEATURED_SPORTS = 'FEATURED_SPORTS',
  FEATURED_MARKETS = 'FEATURED_MARKETS',
  LINK = 'LINK'
}

export enum ViewBy {
  TIME = 'TIME',
  POPULARITY = 'POPULARITY',
  COMPETITION = 'COMPETITION'
}

export enum LiveWidgetHeaderType {
  MARKET = 'market',
  EVENT = 'event'
}

export enum PrecisionType {
  HUNDREDTHS = 'HUNDREDTHS',
  TENTHS = 'TENTHS',
  UNITS = 'UNITS'
}

export enum MarketNameDivider {
  VERSUS = ' vs ',
  AT = ' @ ',
  SHORT_VERSUS = ' v '
}

export interface BetContentGroupStyles {
  betContent?: Record<string, string | number>;
}

export interface BetContentGroupClasses {
  betContent?: string;
}

export interface BetContentCellClasses {
  cell?: string;
  container?: string;
  content?: string;
  odds?: string;
}

export interface BetContentCellStyles {
  cell?: Record<string, string | number>;
}

export enum EventViewType {
  DEFAULT = 'DEFAULT',
  MULTI_MARKET_VIEW = 'MULTI_MARKET_VIEW',
  TOP_5_VIEW = 'TOP_5_VIEW'
}

export const enum PopularEventType {
  EVENT_TYPE = 'EVENT_TYPE',
  COMPETITION = 'COMPETITION',
  CUSTOM_POP_UP = 'CUSTOM_POP_UP',
  RACE = 'RACE',
  EVENT = 'EVENT',
  GROUP = 'GROUP'
}

export enum CurrencySettingsPlacement {
  TOOLTIP = 'TOOLTIP',
  MATCHED_AMOUNT = 'MATCHED_AMOUNT'
}

export enum EventStatus {
  InPlay = 'IN_PLAY',
  CLOSED = 'CLOSED',
  COMPLETE = 'COMPLETE'
}

export type TMobileAccountDropdownValue = {
  name: string;
  labelTranslationKey: string;
  url?: string;
  isVisible?: boolean;
};

export type ModalClasses = {
  container?: string;
  dialog?: string;
  content?: string;
  header?: string;
  headerContent?: string;
  closeIcon?: string;
  body?: string;
  backdrop?: string;
  title?: string;
};

export enum BasketballPlayStatus {
  FINISH = 'Finished',
  FINISH_HALF_END = 'FirstHalfEnd',
  STOP_QUARTER = 'StopQuarter'
}

export enum CashOutTab {
  auto = 0,
  partial = 1
}

export const enum CashOutStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  VOID = 'VOID'
}

export type EventWidgetTab = null | 'video-stream' | 'match-statistics';

export type TListViewTabs = {
  id: string;
  name: string;
};

export enum PlacementPage {
  TOP_5_VIEW = 'top_5_view',
  FEATURED_SPORTS = 'featured-sports',
  FEATURED_SPORTS_ODDS_BANNER = 'featured-sports/odds_banner',
  FEATURED_MARKETS = 'featured-markets',
  FEATURED_MARKETS_ODDS_BANNER = 'featured-markets/odds_banner',
  MARKET = 'market',
  MARKET_ODDS_BANNER = 'market/odds_banner',
  SPORT = 'sport',
  SPORT_ODDS_BANNER = 'sport/odds_banner',
  COMPETITION = 'competition',
  COUNTRY = 'country',
  COUNTRY_ODDS_BANNER = 'country/odds_banner',
  COMPETITION_ODDS_BANNER = 'competition/odds_banner',
  MULTI_MARKET = 'multi-market',
  MULTI_MARKET_ODDS_BANNER = 'multi-market/odds_banner',
  GROUP = 'group',
  GROUP_ODDS_BANNER = 'group/odds_banner',
  IN_PLAY = 'inplay',
  IN_PLAY_ODDS_BANNER = 'inplay/odds_banner',
  CASH_OUT = 'cashout',
  EVENT = 'event',
  EVENT_ODDS_BANNER = 'event/odds_banner',
  LIST_VIEW = 'list-view',
  FANCY_PAGE = 'fancy-page',
  FANCY_PAGE_ODDS_BANNER = 'fancy-page/odds_banner',
  GAME = 'game',
  ASIAN = 'asian'
}

export const enum OddsType {
  DEC = 'decimal',
  MY = 'malay',
  HK = 'hongKong',
  IND = 'indonesian'
}

export const TimeFormats = {
  LOCAL_TIME: 'localTime',
  BETTING_DAY: 'bettingDay'
} as const;

export const Periods = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  THIS_WEEK: 'thisWeek',
  LAST_WEEK: 'lastWeek',
  LAST_30_DAYS: 'last30Days',
  LAST_3_MONTHS: 'last3Months',
  CUSTOM: 'custom'
} as const;

export type TPeriods = typeof Periods[keyof typeof Periods];

export type WebSocketProperties = {
  properties: {
    marketOddsBackOnly: boolean;
  };
};
